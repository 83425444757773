import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../type'
import * as wagmiChains from 'wagmi/chains'

const chain = wagmiChains.avalanche
const icon = chainsSvgs.avaxSvg
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: {
    ...chain,
    rpcUrls: {
      default: {
        http: [
          'https://rpc.ankr.com/avalanche/57f10f5dfc7ba8d80707c85c1cc1d5feeda3c1c7447da479c55156196d816235'
        ]
      }
    }
  },
  type: 'EVM',
  name: chain.name,
  icon: icon,
  networkType
}

export default chainInfo
