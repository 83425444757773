import { getPayload as getPayloadApi } from 'api'
import toast from 'components/Toast'
import { decodePayload } from 'utils/sdk'

export default function useSdk() {
  const getPayload = async () => {
    try {
      const { payload_base64 } = await getPayloadApi()
      // const { payload_base64 } = {
      //   payload_base64:
      //     'eyJpZCI6IjE3MjYyMjIwNDQxODE0MDgiLCJkYXRhIjp7ImlkIjoiMTcyNjIyMjA0NDE4MTQwOCIsIm1ldGhvZCI6ImV0aF9zaWduRVRIVHJhbnNhY3Rpb24iLCJwYXJhbXMiOlt7ImZyb20iOiIweGFiMGY1NjliNWMzNWYzY2Q2ODg5MDY3ZThlYzkxM2Q3ZTAxNTUzZGEiLCJ0byI6IjB4NzgzMDA0ZjY3MGQzYjcwN2UyYWYxM2RlN2Y1YjYzNzc3YzZiNjI1YiIsInZhbHVlIjoiMTAwMDAwMDAwMDAwMDAwMDAwMCIsImNoYWluSWQiOjF9XSwib3B0aW9ucyI6eyJhY2NvdW50Ijp7ImFkZHJlc3MiOiIweGFiMGY1NjliNWMzNWYzY2Q2ODg5MDY3ZThlYzkxM2Q3ZTAxNTUzZGEiLCJhbGxpYW5jZSI6ImV2bSIsIm5hbWUiOiJldGhlcmV1bSIsImNoYWluSWQiOjEsInN5bWJvbCI6IkVUSCJ9LCJtZXRhRGF0YSI6eyJ1cmwiOiIiLCJob3N0bmFtZSI6ImxvY2FsaG9zdCIsIm5hbWUiOiJQbGF5Z3JvdW5kIiwiaWNvbiI6bnVsbH0sInRpbWVTdGFtcCI6MTcyNjIyMjA0NDE4MH19LCJ2ZXJzaW9uIjoiMS4wIiwic2FsdCI6ImMyRnNkQzB4TnpJMk1qSXlNRFEwTVRneExURTNNall5TWpJd05EUXhPREUwTURnIn0='
      // }

      return decodePayload(payload_base64)
    } catch (err: any) {
      toast.error(err?.response?.data?.message || err?.message || '')
    }
  }

  return { getPayload }
}
