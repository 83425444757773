import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../type'
import * as wagmiChains from 'wagmi/chains'

const chain = wagmiChains.scroll
const icon = chainsSvgs.scrollSvg
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: {
    ...chain,
    rpcUrls: {
      default: {
        http: [
          'https://rpc.ankr.com/scroll/29fb8c6c5a90691782b017d9b6ad609d5e67c33e9b0905cce2153cefd28d3b2d'
        ]
      }
    }
  },
  type: 'EVM',
  name: chain.name,
  icon: icon,
  networkType
}

export default chainInfo
