// import { chainsSvgs } from 'assets'
import { DEFAULT_PAGE_SETTING, PageSetting } from 'hooks/usePageSetting'
import { atom } from 'jotai'
// import { IChainId, IWeb3ChainType } from 'proviers/web3Provider/type'
import { UserType, WalletType } from '../hooks/useLoginInfo'
// import { allChain } from 'proviers/web3Provider/chains'
// import { GetBalanceReturnType, PublicClient, WalletClient } from 'viem'
// import { IToken } from 'hooks/useTokens'
import {
  BackupSetting,
  DEFAULT_BACKUP_SETTING
} from '../hooks/useBackupSetting'
// import { HIDDEN_TOKENS, SHOW_TOKENS } from 'pages/ManageAssets'
// import { ISwapTokenType } from 'pages/swap/hooks/type'

function createPersistenceAtom<T>(name: string, defaultValue: T) {
  const data = localStorage.getItem(name)
  const flag = data === 'undefined' || data === null
  const tempAtom = atom<T>(flag ? defaultValue : JSON.parse(data as string))

  return atom<T>(
    (get) => get(tempAtom),
    (get, set, newObj) => {
      let newValue = newObj
      if (typeof newObj === 'function') {
        newValue = newObj(get(tempAtom))
      }
      set(tempAtom, newValue)
      localStorage.setItem(name, JSON.stringify(newValue))
    }
  )
}
// export const currentChainAtom = atom<IWeb3ChainType>(allChain)

export const pageSettingAtom = atom<PageSetting>(DEFAULT_PAGE_SETTING)
export const backupSettingAtom = atom<BackupSetting>(DEFAULT_BACKUP_SETTING)

export const userAtom = atom<UserType | null>(null)

export const walletTypeAtom = atom<WalletType | null>(null)

export enum SocialType {
  Google = 'google',
  Twitter = 'twitter',
  Apple = 'apple',
  Email = 'email',
  Telegram = 'telegram'
}

export type WalletState = {
  isConnection: boolean
  walletId?: number
  connectType?: 'social'
  socialType?: SocialType
  address?: string
  chainId?: number
}

export const walletStateAtom = createPersistenceAtom<WalletState>(
  'tomo_wallet_state',
  {
    isConnection: false,
    walletId: undefined,
    connectType: undefined,
    socialType: undefined,
    address: undefined,
    chainId: undefined
  }
)

export const installWalletsAtom = atom([])

export type TomoPage = {
  jsx: any
  resolve: (v?: any) => void
  type: 'page' | 'drawer'
  showClose: boolean
}

export const pagesAtom = atom<TomoPage[]>([])

// export const clientMapAtom = atom({
//   // 0: loading 1: ready
//   state: 0 as 0 | 1,
//   evmWallet: undefined as WalletClient | undefined,
//   evmProvider: undefined as undefined | any
// })

export const bioStatusAtom = atom<boolean>(false)
// export const userPhrasAtom = atom<string | undefined>(undefined)
export const bioAvailableAtom = atom<boolean>(false)
export const bioInitedAtom = atom<boolean>(false)

// export type IHistoryType = {
//   fromAddress: string | undefined
//   toAddress: string | undefined
//   fromAmount: string | undefined
//   toAmount: string
//   nonce: number
//   fromSwapTokens:
//     | {
//         token?: IToken
//         chain: IWeb3ChainType | undefined
//         balance: IToken | undefined
//       }
//     | undefined
//   toSwapTokens:
//     | {
//         token?: IToken
//         chain: IWeb3ChainType | undefined
//         balance: IToken | undefined
//       }
//     | undefined
//   time: number
//   hash: any
//   chain?: IWeb3ChainType | undefined
//   type?: 'OKX' | 'Rango'
//   requestId?: string | undefined
//   historyType: 'Swap' | 'Send' | 'Approve'
//   status?: 'success' | 'pending' | 'failed' | 'loading' | 'unknow'
// }

export const inviteCodeAtom = atom(localStorage.getItem('inviteCode') || '')

// export const saveSwapErc20Tokens = createPersistenceAtom<
//   | {
//       network: IWeb3ChainType
//       token: ISwapTokenType
//       chainId: number
//     }[]
//   | undefined
// >('saveSwapErc20Tokens', undefined)

// export const saveSwapSPLTokensAtom = createPersistenceAtom<
//   | {
//       network: IWeb3ChainType
//       token: ISwapTokenType
//       chainId: number
//     }[]
//   | undefined
// >('saveSwapSPLTokens', undefined)

// export const markertNetworkAtom = atom<IWeb3ChainType>(allChain)
// export const markertNetworkPageAtom = atom<IWeb3ChainType>(allChain)
export const markertSearchAtom = atom<boolean>(false)
export const markertSearchKeywordAtom = atom<string>('')
export const navigationAtom = atom<{ from: string; to: string }>({
  from: '',
  to: ''
})

// export const assetsHiddenTokensAtom = createPersistenceAtom<string[]>(
//   HIDDEN_TOKENS,
//   []
// )

// export const assetsShowTokensAtom = createPersistenceAtom<string[]>(
//   SHOW_TOKENS,
//   []
// )
export const deviceIdAtom = atom<string>('')
export const needEmailVerifyAtom = atom<boolean | 'verify' | 'relogin'>(true)

export const isSendBioAuthAtom = atom<boolean>(false)
export const sdkHashAtom = atom<string>('')
export const isTelegramReadyAtom = atom<boolean>(false)
export const isNewUserAtom = atom<boolean>(false)
export const isLoadingAtom = atom<boolean>(true)
export const verifyPinpayAtom = atom({
  visible: false,
  resolve: null
})
