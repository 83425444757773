import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../type'
import { botanixTestnet } from '@wagmi/core/chains'

const icon = chainsSvgs.botanixTestnetJpg
const networkType: IWeb3NetworkType = 'test'

const chainInfo: IWeb3ChainType = {
  chain: botanixTestnet,
  id: 3_636,
  type: 'EVM',
  name: botanixTestnet.name,
  icon: icon,
  networkType
}

export default chainInfo
