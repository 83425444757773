import { Input, Space, Toast } from 'antd-mobile'
import { Button } from 'components/Button'
import Container from 'components/Container'
import usePageSetting from 'hooks/usePageSetting'
import { useState, useEffect, useRef, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import 'assets/styles/pass.css'
// import { SyncOutlined } from '@ant-design/icons'
import useEmail from 'hooks/useEmail'
import useLoginInfo from 'hooks/useLoginInfo'
import useCountdown from 'hooks/useCountdown'
import { md5 } from 'utils/helper'
import toast from 'components/Toast'
import { classNames } from 'utils'
import PasscodeInput, { MyPasscodeInputRef } from 'components/PasscodeInput'
import { useDeviceId } from 'hooks/useDeviceId'
import useNavigateWithParams from 'hooks/useNavigateWithParams'
import useSdk from '@/hooks/useSdk'
import recoverEmailSvg from 'assets/imgs/recovery-email-logo.svg'
import { useQuery } from '@tanstack/react-query'
import { refresh } from '@/assets'

const taskName = 'bindEmail'

export const EmailStep1 = ({
  email,
  onChange,
  onConfirm,
  countdownKey
}: {
  email: string
  onChange: (val: string) => void
  onConfirm: () => void
  countdownKey: string
}) => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const disabled = !pattern.test(email)
  const { sendBindRrcoverEmailCode } = useEmail()
  const { startCountdown, resetCountdown, stopCountdown, diffTimeLeft } =
    useCountdown(countdownKey, taskName)
  const [isErr, setIsErr] = useState(false)
  const sendingRef = useRef(false)

  const handleEmail = (val: string) => {
    onChange(val)
    setIsErr(false)
  }
  const handleConfirm = async () => {
    Toast.show({
      icon: 'loading',
      // content: 'Logging in...',
      duration: 0,
      maskClickable: false,
      maskStyle: {
        '--z-index': 9999
      }
    })

    let result: boolean | number = true
    // console.log('secondssecondsseconds',seconds)
    if (!diffTimeLeft(countdownKey)) {
      if (sendingRef.current) return
      sendingRef.current = true
      result = await sendBindRrcoverEmailCode(email)
      sendingRef.current = false
      if (result === true) resetCountdown()
      else if (result === 20000) {
        // onChange('')
        setIsErr(true)
      }
    }

    Toast.clear()
    if (result !== true) return

    onConfirm()
  }

  useEffect(() => {
    startCountdown()
  }, [])

  return (
    <>
      <div className="flex w-full flex-col">
        <div className="mb-[10px] w-full text-left text-[20px] font-bold leading-[26px]">
          Add Recovery Email
        </div>
        <p className="mb-[30px] text-lg font-normal leading-[21px]">
          {/* To ensure the security of your account, please link your email
          address. It is vital for recovering your account if you forget the
          password. Without email verification, you may lose access to your
          account and assets. Please understand our ability to assist in such
          cases may be limited. */}
          If you lose access to your account, you can easily restore it by
          logging into the TOMO App with your email address to complete the
          recovery process.
        </p>

        <div>
          <Input
            enterKeyHint={'done'}
            value={email}
            className={classNames(
              'h-12 rounded-lg border  text-xl font-normal leading-[21px]',
              [isErr ? 'border-[#F21F7F]' : 'border-black/10']
            )}
            placeholder="Enter your email"
            style={{
              '--placeholder-color': 'rgba(18, 18, 18, 0.4)',
              '--font-size': '16px',
              '--color': '#121212',
              '--text-align': 'center'
            }}
            onChange={handleEmail}
            autoFocus
          />
          {isErr && (
            <p className="mt-[10px] text-df leading-normal text-[#F21F7F]">
              This email has been registered with Tomo. Please change the email
              address.
            </p>
          )}
        </div>
      </div>
      <div className="mt-[20px] w-full pb-[20px] pt-[12px]">
        <Button size="large" onClick={handleConfirm} block disabled={disabled}>
          Confirm
        </Button>
      </div>
    </>
  )
}

export const EmailStep2 = ({
  email,
  countdownKey,
  onSuccess,
  from = 'normal',
  onReset,
  sdkEmail
}: {
  sdkEmail: string
  email: string
  countdownKey: string
  onSuccess?: () => void
  from?: 'normal' | 'relogin' | 'verify' | 'reset'
  onReset?: (code: string) => void
}) => {
  const [pass, setPass] = useState<string>('')
  const passcodeRef = useRef<MyPasscodeInputRef | null>(null)
  const {
    sendBindEmailCode,
    verifyBindEmailCode,
    reLoginEmailSend,
    reLoginEmailVerify,
    sendTradePwdEmail,
    verifyTradeEmail,
    verifyBindRecoverEmailCode,
    sendBindRrcoverEmailCode
  } = useEmail()
  const [sending, setSending] = useState(false)

  const { user, setUser } = useLoginInfo()
  const {
    timeLeft: seconds,
    startCountdown,
    resetCountdown
  } = useCountdown(countdownKey, taskName)
  const { deviceId, setBioByBioDisable } = useDeviceId()
  const [keyboardOpen, setKeyboardOpen] = useState(false)

  const handleResend = async () => {
    if (sending) return
    setSending(true)
    let result: boolean = false
    if (from === 'normal') {
      result = await sendBindRrcoverEmailCode(sdkEmail || email)
    } else if (from === 'verify' || from == 'reset') {
      result = await sendTradePwdEmail()
    } else {
      result = await reLoginEmailSend()
    }

    if (result) resetCountdown()
    setSending(false)
  }

  const handleVerify = async (value: string) => {
    Toast.show({
      icon: 'loading',
      // content: 'Logging in...',
      duration: 0,
      maskClickable: false,
      maskStyle: {
        '--z-index': 9999
      }
    })

    let result: boolean
    if (from == 'normal') {
      result = await verifyBindRecoverEmailCode({ email, code: value })
    } else if (from == 'verify') {
      result = await verifyTradeEmail(value)
    } else if (from == 'reset') {
      Toast.clear()
      onReset && onReset(value)
      return
    } else {
      result = await reLoginEmailVerify(value, deviceId)
    }

    Toast.clear()
    if (!result) {
      passcodeRef.current?.reset()
      return
    }

    if (user) setUser({ ...user, email, recoveryEmailBound: true })
    toast.success('Success')
    if (onSuccess) {
      onSuccess()
      return
    }
  }

  const handleFocus = () => {
    if (
      window.visualViewport?.height &&
      window.visualViewport?.height - 300 <= 300
    ) {
      setKeyboardOpen(true)
    }
  }

  useEffect(() => {
    startCountdown()
    if (from !== 'normal' && seconds == 0) {
      handleResend()
    }
  }, [])

  useEffect(() => {
    if (from === 'normal' && sdkEmail) {
      handleResend()
    }
  }, [sdkEmail, from])

  return (
    <div
      className={classNames('flex w-full flex-col transition-all mt-[4px]', [
        keyboardOpen ? 'pb-[300px] -mt-12' : ''
      ])}
    >
      <div className="flex w-full items-center justify-between text-left text-[20px] font-bold leading-[26px]">
        <span>Email Code</span>
      </div>

      <span className="mt-4 text-[#8989AB]">Enter your verification code</span>

      <Space
        direction="vertical"
        block
        className="pass flex items-center pt-[30px]"
        style={{ '--gap': '24px' }}
      >
        <PasscodeInput
          seperated={true}
          caret={false}
          keyboard={<></>}
          value={pass}
          onHide={() => setKeyboardOpen(false)}
          onFocus={handleFocus}
          setValue={setPass}
          onFill={handleVerify}
          length={4}
          style={{ '--cell-gap': '24px', '--cell-size': '48px' }}
          ref={passcodeRef}
        />
      </Space>

      <div className="mt-6 flex flex-none items-center justify-center text-lg font-normal leading-[20px]">
        {seconds > 0 ? (
          <button disabled className="text-[#8989AB]">
            Resend {seconds} s
          </button>
        ) : (
          <button
            className="flex items-center gap-2 text-[#12122A]"
            onClick={handleResend}
          >
            <img src={refresh} />
            Request
          </button>
        )}
      </div>
    </div>
  )
}

const RecoveryEmail = () => {
  const stepRef = useRef(1)
  const [email, setEmail] = useState('')
  const countdownKey = `be${md5(email)}`
  const navigate = useNavigateWithParams()
  const { user, setUser } = useLoginInfo()
  const location = useLocation()
  const { email: emailParam } = location.state || { email: '' }
  const [step, setStep] = useState(emailParam ? 2 : 1)
  const { sendBindRrcoverEmailCode } = useEmail()
  const [sdkEmail, setSdkEmail] = useState(emailParam)

  const { resetCountdown } = useCountdown(countdownKey, taskName)

  const onConfirm = () => {
    stepRef.current = 2
    setStep(2)
  }

  useEffect(() => {
    if (emailParam) {
      setEmail(emailParam)
      setSdkEmail(emailParam)
      onConfirm()
      resetCountdown()
    }
  }, [emailParam])

  useEffect(() => {
    if (user?.email) {
      navigate('/')
    }
  }, [user?.email])

  return (
    <Container className="pt-[20px] text-title">
      {step == 1 ? (
        <EmailStep1
          onConfirm={onConfirm}
          email={email}
          onChange={setEmail}
          countdownKey={countdownKey}
        />
      ) : (
        <EmailStep2
          email={email}
          countdownKey={countdownKey}
          onSuccess={() => {
            navigate('/')
          }}
          sdkEmail={sdkEmail}
        />
      )}
    </Container>
  )
}

export default RecoveryEmail
