import { useNavigate, useLocation } from 'react-router-dom'

const useNavigateWithParams = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const params = new URLSearchParams(location.search)
  const myParam = params.get('startapp')

  const navigateWithParams = (path: string | number, options = {}) => {
    let newPath = path
    if (myParam) {
      newPath = `${path}?startapp=${myParam}`
    }
    if (typeof path == 'number') navigate(path)
    else navigate(`${newPath}`, options)
  }

  return navigateWithParams
}

export default useNavigateWithParams
