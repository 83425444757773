import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../type'
import * as wagmiChains from 'wagmi/chains'

const chain = wagmiChains.sepolia
const icon = chainsSvgs.ethSvg
const networkType: IWeb3NetworkType = 'test'

const chainInfo: IWeb3ChainType = {
  chain: {
    ...chain,
    rpcUrls: {
      default: {
        http: ['https://ethereum-sepolia-rpc.publicnode.com']
      }
    }
  },
  type: 'EVM',
  name: chain.name,
  icon: icon,
  networkType
}

export default chainInfo
