import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../type'
import * as wagmiChains from 'wagmi/chains'

const chain = wagmiChains.bsc
const icon = chainsSvgs.bscSvg
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: {
    ...chain,
    rpcUrls: {
      default: {
        http: [
          'https://rpc.ankr.com/bsc/5fc8ed1906d70db2174601d49aa1edc708e5ff35f1c862c95d43734a6943730e'
        ]
      }
    }
  },
  type: 'EVM',
  name: chain.name,
  icon: icon,
  networkType
}

export default chainInfo
