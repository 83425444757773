import {
  walletImport as walletImportApi,
  walletList as walletListApi,
  walletCurrent as walletCurrentApi,
  walletSwitch as walletSwitchApi,
  sdkReject as sdkRejectApi
} from 'api'
import toast from 'components/Toast'

export default function useWallet() {
  const walletImport = async (mnemonic: string) => {
    try {
      const { code, message, result } = await walletImportApi({ mnemonic })

      if (code !== 10000) {
        toast.error(message)
        return false
      }

      return result
    } catch (err: any) {
      // console.log('errrrrrr', err)
      toast.error(err?.response?.data?.message || err?.message || '')
    }
    return false
  }

  const walletList = async () => {
    try {
      const { code, message, result } = await walletListApi()

      if (code !== 10000) {
        toast.error(message)
        return []
      }

      return result
    } catch (err: any) {
      // console.log('errrrrrr', err)
      toast.error(err?.response?.data?.message || err?.message || '')
    }
    return []
  }

  const walletCurrent = async () => {
    try {
      const { code, message, result } = await walletCurrentApi()

      if (code !== 10000) {
        toast.error(message)
        return 0
      }

      return result.walletId
    } catch (err: any) {
      toast.error(err?.response?.data?.message || err?.message || '')
    }
    return 0
  }

  const walletSwitch = async (walletId: number, walletAddress: string) => {
    try {
      const { code, message, result } = await walletSwitchApi(
        {
          walletId
        },
        { 'wallet-address': walletAddress }
      )
      if (code !== 10000) {
        toast.error(message)
        return false
      }
      return true
    } catch (err: any) {
      toast.error(err?.response?.data?.message || err?.message || '')
      throw err
      return false
    }
  }

  const sdkReject = async () => {
    try {
      await sdkRejectApi()

      return true
    } catch (err: any) {
      toast.error(err?.response?.data?.message || err?.message || '')
      return false
    }
  }

  return { walletImport, walletList, walletCurrent, walletSwitch, sdkReject }
}
