import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../type'
import { worldchain } from 'viem/chains'

const icon = chainsSvgs.worldChainPng
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: {
    ...worldchain
  },
  id: worldchain.id,
  type: 'EVM',
  name: worldchain.name,
  icon: icon,
  networkType: networkType
}

export default chainInfo
