import { Button } from 'components/Button'
import Copy from 'components/Copy'
import { NumberFormatter } from 'components/NumberFormatter'
import useSdk from 'hooks/useSdk'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { numberFormat } from 'utils'
import { useQuery } from '@tanstack/react-query'
import useChains from 'hooks/useChains'
import useApp from 'hooks/useApp'
import { checkApproveHex, shortenAddress } from 'utils/helper'
import useLoginInfo from '@/hooks/useLoginInfo'
import useSendTransaction from '@/hooks/useSendTransaction'
import { useWebApp } from '@vkruglikov/react-telegram-web-app'
import toast from 'components/Toast'
import { getTokenDetailByAddress } from '@/api'
import { getTonBalance } from '@/config/ton'
import { TON_MIN_FEE_FOR_JETTON } from '@/constans'
import {
  TonTxBodyType,
  TonTxRequest,
  TonTxRequestStandard
} from '@tomo-inc/tomo-telegram-sdk'
import { tonMockDataLegacy, tonMockDataStandard } from './mockData'
import { TxInfo } from './TxInfo'
import { Address, fromNano, toNano } from '@ton/core'
import BigNumber from 'bignumber.js'

interface TransferModel {
  from: string
  to: string
  value: string
  chainId: number
  /**
   * for ton ↓ ---
   */
  contractAddress?: string
  publicKey?: string
  memo?: string
  precision?: number
  forwardAmount: string
  /**
   * -------↑-------
   */
}

export default function SignTonTransactionUI(props: { [other: string]: any }) {
  const navigate = useNavigate()
  const { getPayload } = useSdk()
  const { getChain } = useChains()
  const webApp = useWebApp()
  const { webAppReject } = useApp()
  const [tokenName, setTokenName] = useState('')

  const { tonAddress } = useLoginInfo()

  const { fee, unit } = props
  const chainType = 'ton'

  const { data: transData, isLoading } = useQuery({
    queryKey: ['sign-transaction'],
    queryFn: async () => {
      return await getPayload()
    }
  })
  // const isLoading = false
  // const transData = tonMockDataStandard

  const transfer: Partial<TransferModel> = useMemo(() => {
    if ((transData?.data?.params || []).length) {
      const data = transData?.data?.params[0]
      console.log('json legacy payload')
      // JETTON_PAYLOAD_JSON_LEGACY value using Nano
      return data
    }
    return {}
  }, [transData?.data?.params])

  const chainId = useMemo(() => {
    return Number(transfer?.chainId)
  }, [transfer?.chainId])

  const chain = useMemo(() => {
    return getChain(chainId)
  }, [chainId])

  const [status, setStatus] = useState<'normal' | 'loading' | 'success'>(
    'normal'
  )
  const [reStatus, setReStatus] = useState<'normal' | 'loading'>('normal')

  const { sendTonTransaction } = useSendTransaction({
    chainId: chainId
  })

  const getUserAddress = () => {
    return tonAddress
  }

  const doSignTx = async () => {
    if (status !== 'normal') return
    const userAddress = getUserAddress()

    if (
      !userAddress ||
      !transfer?.from ||
      userAddress.toLowerCase() != `${transfer.from}`.toLowerCase()
    ) {
      return toast.error('From address error.')
    }

    try {
      setStatus('loading')

      let signData
      let result
      // judge balance
      const { formatted = '0' } = (await getTonBalance(transfer.from)) || {}
      if (transfer.contractAddress && +formatted < TON_MIN_FEE_FOR_JETTON) {
        toast.error(
          `Insufficient balance. Min balance is ${TON_MIN_FEE_FOR_JETTON} TON`
        )
      }
      const txRequestRaw: TonTxRequest = transData?.data?.params[0]
      if (txRequestRaw.type === TonTxBodyType.STANDARD) {
        // TODO signTransaction
        signData = txRequestRaw as TonTxRequestStandard
        result = await sendTonTransaction({ paramsForPure: signData })
      } else {
        //  "JETTON_PAYLOAD_JSON_LEGACY" or not defined
        signData = {
          fromAddress: transfer.from,
          publicKey: transfer.publicKey || '',
          amount: transfer.value + '',
          toAddress: transfer.to || '',
          memo: transfer.memo || '',
          tokenContractAddress: transfer.contractAddress,
          tokenPrecision: transfer.precision,
          forwardAmount: transfer.forwardAmount
        }

        result = await sendTonTransaction({ params: signData })
      }

      if (result && result?.code == 10000) {
        setStatus('success')
        setTimeout(() => {
          webApp.close()
        }, 500)
      } else {
        setStatus('normal')
        throw result?.message || 'Network error.'
      }
    } catch (err: any) {
      toast.error(err?.response?.data?.message || err?.message || err)
      setStatus('normal')
    }
  }

  const handleConfirmClick = () => doSignTx()

  useEffect(() => {
    if (!transfer?.from) return setTokenName('')
    const contractAddr = transfer.contractAddress
    if (contractAddr) {
      getTokenDetailByAddress(chain?.name || '', contractAddr).then((res) => {
        setTokenName(res?.symbol)
      })
    } else {
      setTokenName('TON')
    }
  }, [chain, chainType, transfer])

  const feeInfo = 'Average ~ 0.0055 TON'

  return (
    <>
      <div
        className={`flex h-full flex-1 flex-col justify-between px-[16px] pb-[16px] pt-[20px]`}
      >
        <h2 className="text-[20px] font-bold leading-[1.3] text-title">
          Sign Tx
        </h2>

        <TxInfo
          txInfo={{
            chainName: chain?.name || '',
            chainIcon: chain?.icon || '',
            from: transfer.from || '',
            to: transfer.to || '',
            value: transfer.value || '',
            // value: fromNano(transfer.value || '0'),
            tokenName,
            feeInfo
          }}
        />

        <div
          className={`mt-[34px] grid w-full flex-1 grid-cols-2 items-end gap-5`}
        >
          <Button
            size="large"
            block
            onClick={() => webAppReject(false)}
            theme="ghost"
          >
            Reject
          </Button>
          <Button
            size="large"
            className="w-full"
            onClick={handleConfirmClick}
            status={status}
            disabled={isLoading || !transData?.data || fee === '--'}
          >
            Confirm
          </Button>
        </div>
      </div>
    </>
  )
}

type TransDataType = {
  chainId: number
  from: string
  to: string
  value: string
}

export function isJUSDT(address: string) {
  const usdtAddress = 'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs'
  if (
    usdtAddress === address ||
    Address.parse(address).toString() ===
      Address.parse(usdtAddress).toString() ||
    Address.parse(usdtAddress).toString() === address ||
    Address.parse(address).toString() === usdtAddress
  ) {
    return true
  } else {
    return false
  }
}
