import { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import {
  SENTRY_HTTP_IGNORE_ERROR_STATUS,
  SENTRY_HTTP_SUCCESS_BIZ_CODE
} from './const'
import {
  Business,
  sentryCaptureException,
  SentryTags
} from './sentryCaptureException'
import {
  createSentrySpan,
  endSentrySpan,
  handleSentrySpanError
} from './handleSentrySpan'

export const sentryInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use((config) => {
    try {
      createSentrySpan(config)
    } catch (error) {
      sentryCaptureException(<Error>error)
    }
    return config
  })
  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      try {
        endSentrySpan(response)
        // Interface business error reporting to Sentry
        const { code, msg, message } = response?.data || {}
        if (code && !SENTRY_HTTP_SUCCESS_BIZ_CODE.includes(Number(code))) {
          const url = response?.config?.url || ''
          const _msg = msg || message || 'unknown'
          const errMsg = `【api】response biz error, url: ${url}; code: ${code}; msg: ${_msg}`
          sentryCaptureException(errMsg, {
            tags: getTagsByConfig(response?.config),
            extra: {
              config: response?.config,
              res: response?.data
            }
          })
        }
      } catch (error) {
        sentryCaptureException(<Error>error)
      }
      return response
    },
    (error: Record<string, any>) => {
      try {
        handleSentrySpanError(error)
        const { config, response } = error || {}
        const url = config?.url || ''
        const status = response?.status || error?.status
        const code = error?.code || error?.status
        const msg = response?.data?.message || error?.message || 'unknown'
        const noErrorInfo = !status && !code
        const ignoreError = SENTRY_HTTP_IGNORE_ERROR_STATUS.includes(
          Number(status)
        )

        if (noErrorInfo || ignoreError) return Promise.reject(error)

        const errMsg = `【api】response error, url: ${url}; status: ${status}; code: ${code}; msg: ${msg}`
        sentryCaptureException(errMsg, {
          tags: getTagsByConfig(config),
          extra: {
            config,
            res: error
          }
        })
      } catch (error) {
        sentryCaptureException(<Error>error)
      }
      return Promise.reject(error)
    }
  )
}

/**
 * sign transaction api list
 */
const signTransactionList = [
  '/socialLogin/projectWallet/sui/signTransaction',
  '/socialLogin/projectWallet/sui/signPersonalMessage',
  '/socialLogin/projectWallet/ethereum/signTransaction',
  '/socialLogin/projectWallet/ethereum/signMessage',
  '/socialLogin/projectWallet/ton/signTransaction',
  'sdk/ton/proof',
  '/socialLogin/projectWallet/solana/signRawTransaction',
  '/socialLogin/projectWallet/solana/signMessage'
]

/**
 * Get sentry tags by config
 * @param {InternalAxiosRequestConfig} config axios config
 * @returns {SentryTags} sentry tags
 */
const getTagsByConfig = (config: InternalAxiosRequestConfig) => {
  const tags: SentryTags = {}

  if (!config) return tags

  // sign transaction api
  const url = config?.url || ''
  const isSign = signTransactionList.find((item) => url.includes(item))
  if (isSign) {
    tags.business = Business.TRADE
    return tags
  }

  // auth api
  const baseUrl = config?.baseURL || ''
  const tagsMap = {
    [import.meta.env.VITE_AUTH_API as string]: Business.AUTH
  }
  const tag = tagsMap[baseUrl]
  if (tag) tags.business = tag

  return tags
}
