import { useAtom } from 'jotai'
import { verifyPinpayAtom } from 'state'

export const useVerifyModal = () => {
  const [popupState, setPopupState] = useAtom(verifyPinpayAtom)

  const showVerify = () => {
    return new Promise((resolve) => {
      setPopupState({ visible: true, resolve })
    })
  }

  return { showVerify }
}
