import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../type'
import * as wagmiChains from 'wagmi/chains'

const chain = wagmiChains.base
const icon = chainsSvgs.baseSvg
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: {
    ...chain,
    rpcUrls: {
      default: {
        http: [
          'https://rpc.ankr.com/base/c2487bd8fe1c09aca100f8c32f847cadb5632bd92c1a9baee1107a81de3c1dfc'
        ]
      }
    }
  },
  type: 'EVM',
  name: chain.name,
  icon: icon,
  networkType
}

export default chainInfo
