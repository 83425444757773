interface NumberFormatterProps {
  value: number | string | undefined
  prefix?: string
  suffix?: string
  separator?: boolean
  fixNum?: number //number >= 1 fix num
  tFixNum?: number //number < 1 fix num
  intClassName?: string
  decimalClassName?: string
}
function getZeroCount(num: number) {
  const [intPart, decimalPart] = num.toString().split('.')
  if (!decimalPart) {
    return 0
  }
  let zeroCount = 0
  for (let i = 0; i < decimalPart.toString().length; i++) {
    if (decimalPart.toString()[i] === '0') {
      zeroCount++
    } else {
      break
    }
  }
  return zeroCount
}
function convertScientificToDecimal(num: number) {
  let str = num.toFixed(12)
  str = str.replace(/0+$/, '')
  str = str.replace(/\.$/, '')
  return str
}
export const NumberFormatter: React.FC<NumberFormatterProps> = ({
  value,
  prefix = '',
  suffix = '',
  separator = true,
  fixNum = 2,
  tFixNum = 4,
  intClassName,
  decimalClassName
}) => {
  const numberValue =
    typeof value === 'number'
      ? convertScientificToDecimal(value)
      : value
        ? value
        : '0'

  const [intPart, decimalPart] = numberValue.toString().split('.')

  const intFormat = separator
    ? intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : intPart

  if (Math.abs(parseFloat(numberValue)) >= 1) {
    return (
      <span>
        {prefix}
        <span className={intClassName}>{intFormat}</span>
        {decimalPart ? (
          <span className={decimalClassName}>
            .{decimalPart.substring(0, fixNum)}
          </span>
        ) : (
          ''
        )}
        {suffix}
      </span>
    )
  }

  if (decimalPart) {
    let zeroCount = 0
    for (let i = 0; i < decimalPart.length; i++) {
      if (decimalPart[i] === '0') {
        zeroCount++
      } else {
        break
      }
    }
    if (zeroCount < tFixNum) {
      return (
        <span>
          {prefix}
          <span className={intClassName}>{intFormat}</span>
          <span className={decimalClassName}>
            .{decimalPart.substring(0, tFixNum)}
          </span>
          {suffix}
        </span>
      )
    }
    return (
      <span>
        {prefix}
        <span className={intClassName}>{intFormat}</span>
        <span className={decimalClassName}>
          .0<sub>{zeroCount}</sub>
          {decimalPart?.substring(zeroCount, zeroCount + tFixNum - 2)}
        </span>
        {suffix}
      </span>
    )
  }
  return (
    <span>
      {prefix}
      <span className={intClassName}>{intPart}</span>
      {suffix}
    </span>
  )
}
type UsdFormatterProps = Omit<NumberFormatterProps, 'prefix'> & {
  value: number
}

export const UsdFormatter: React.FC<UsdFormatterProps> = (props) => {
  return <NumberFormatter {...props} prefix={'$'} />
}
