import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType, Web3Type } from '../type'

const icon = chainsSvgs.tonSvg
const networkType: IWeb3NetworkType = 'test'

const chainInfo: IWeb3ChainType = {
  chain: {
    id: 1101,
    name: 'TON',
    nativeCurrency: { decimals: 9, name: 'TON', symbol: 'TON' }
  },
  id: 1101,
  type: Web3Type.TONTEST,
  name: 'TON Testnet',
  icon: icon,
  networkType
}

export default chainInfo
