import {
  bioStatusAtom,
  // currentChainAtom,
  inviteCodeAtom,
  userAtom,
  walletTypeAtom,
  needEmailVerifyAtom,
  sdkHashAtom,
  isNewUserAtom
} from '../state'
import { avatarPng } from '../assets'
import { useAtom } from 'jotai/index'
import { IWeb3ChainType, Web3Type } from '../proviers/web3Provider/type'
import { useCallback, useMemo } from 'react'
import {
  sdkLogin as sdkLoginApi,
  tonProofLogin,
  getSocialInfo,
  loginJavaApi,
  getDefaultWalletByUserId,
  getDefaultWalletByUserIdWithJwt
} from 'api'
import { mnemonicToPrivateKey, recoverSeedWords } from 'utils/wallet'
import { useQuery } from '@tanstack/react-query'
import { IToken } from './useTokens'
import { btcDefaultType } from '../utils'
// import useBiometricManager from './useBiometricManager'
import { useInitData } from '@vkruglikov/react-telegram-web-app'
import toast from '../components/Toast'
import { Toast } from 'antd-mobile'
import { userStorageAtom } from 'state/storage'
// import { mockTronChainId } from 'config/tron'
import { logout as baseLogout } from '../utils'
import * as Sentry from '@sentry/react'
import useNavigateWithParams from './useNavigateWithParams'

export type UserType = {
  // nickname: string
  username: string
  token: string
  avatar: string
  id?: number
  userId: number
  displayName: string
  bitcoinP2pkhAddress: string
  bitcoinP2trAddress: string
  bitcoinP2shAddress: string
  bitcoinP2wpkhAddress: string
  v2Address: string
  solanaAddress: string
  tronAddress: string
  tonAddress: string
  tonPublicKey?: string
  nickname?: string
  tgId?: string
  tgName?: string
  email?: string
  defaultEthereumAddress: string
  ethereumAddress: string
  defaultWalletId: number
  set_trade_password?: boolean
  setTradePassword: boolean
  recoveryEmailBound?: boolean
  suiAddress?: string
  tonAddressTest?: string
}
/*
bitcoinP2pkhAddress
bitcoinP2shAddress
bitcoinP2trAddress
bitcoinP2wpkhAddress
  }
*/
export type WalletType =
  | 'bitcoinP2Pkh'
  | 'bitcoinP2Tr'
  | 'bitcoinP2Sh'
  | 'bitcoinP2Wpkh'
  | 'EVM'
  | 'SOL'
  | 'TON'
  | 'TRON'

export type TargetType = 'oauth' | 'connect' | 'login' | 'tonProof'

export type TonProofParams = {
  payload: string
  domain: string
  workChain: number
}

export default function useLoginInfo() {
  // const { biometryManager } = useBiometricManager()
  const [user, setUser] = useAtom(userStorageAtom)
  const [walletType, setWalletType] = useAtom(walletTypeAtom)
  const [inviteCode, setInviteCode] = useAtom(inviteCodeAtom)
  const [initDataUnsafe, initData] = useInitData()
  const navigate = useNavigateWithParams()

  const walletAddress = useMemo(() => {
    if (!user || !walletType) return ''
    if (walletType == 'EVM') {
      return user?.ethereumAddress || user?.v2Address
    }
    if (walletType == 'SOL') {
      return user?.solanaAddress
    }
    if (walletType == 'TON') {
      return user?.tonAddress
    }
    if (walletType == 'TRON') {
      return user?.tronAddress
    }
    if (walletType == 'bitcoinP2Pkh') {
      return user?.bitcoinP2pkhAddress
    }
    if (walletType == 'bitcoinP2Tr') {
      return user?.bitcoinP2trAddress
    }
    if (walletType == 'bitcoinP2Sh') {
      return user?.bitcoinP2shAddress
    }
    if (walletType == 'bitcoinP2Wpkh') {
      return user?.bitcoinP2wpkhAddress
    }
    if (walletType == 'SUI') {
      return user?.suiAddress
    }

    return user[`${walletType}Address` as keyof UserType] as string
  }, [user, walletType])

  const evmAddress = user?.v2Address
  const solAddress = user?.solanaAddress
  const tonAddress = user?.tonAddress
  const tronAddress = user?.tronAddress
  const tonPublicKey = user?.tonPublicKey
  const tonAddressTest = user?.tonAddressTest

  const btcAddress = {
    bitcoinP2PkhAddress: user?.bitcoinP2pkhAddress,
    bitcoinP2ShAddress: user?.bitcoinP2shAddress,
    bitcoinP2TrAddress: user?.bitcoinP2trAddress,
    bitcoinP2WpkhAddress: user?.bitcoinP2wpkhAddress
  }

  // console.log({
  //   key: 'allToken',
  //   user,
  //   btcAddress
  // })

  const btcType = useMemo(() => {
    if (walletType == 'EVM' || walletType == 'SOL') return btcDefaultType
    return walletType || btcDefaultType
  }, [walletType])

  const btcWallet = useMemo(() => {
    // console.log({ btcType, walletType })
    return (user?.[`${btcType}Address` as keyof UserType] as string)
      ? (user?.[`${btcType}Address` as keyof UserType] as string)
      : undefined
  }, [btcType, user])

  const logout = () => {
    setUser(null)
    baseLogout()
  }
  const _setUser = (userInfo: UserType) => {
    const displayName = (() => {
      if (userInfo.tgName) {
        return userInfo.tgName
      }
      if (userInfo.nickname) {
        return userInfo.nickname
      }
      if (userInfo.username) {
        if (userInfo.username?.includes('telegram@')) {
          return userInfo.username.substring('telegram@'.length)
        }
      }
      return ''
    })()
    const id = initDataUnsafe?.user?.id
    id && Sentry.setUser({ id })
    setUser({
      ...userInfo,
      avatar: userInfo.avatar || '',
      displayName: displayName
      // email: ''
    })
    localStorage.setItem('user', JSON.stringify({ ...user, ...userInfo }))
  }
  const login = async ({
    success,
    force,
    target,
    params
  }: {
    success?: (user: UserType) => void
    force?: boolean
    target?: TargetType
    params?: TonProofParams | { isNew: boolean }
  }) => {
    if (!initData) {
      return toast.warn('Please open the telegram and try again')
    }
    if (!initDataUnsafe?.start_param) {
      return toast.warn('Params error.')
    }
    Toast.show({
      icon: 'loading',
      content: 'Authorizing...',
      duration: 0,
      maskClickable: false
    })

    if (target == 'connect') {
      const doLogin = async () => {
        const userId = user?.userId
        if (!userId) {
          Toast.clear()
          return navigate('/login')
        }
        const isNew = (params as { isNew: boolean }).isNew
        const getAddress = isNew ? getDefaultWalletByUserId : sdkLoginApi
        const getAddressParams = isNew ? userId : initData
        return await getAddress(getAddressParams as never)
          .then(async (res) => {
            const info = isNew ? res?.result ?? {} : res ?? {}
            _setUser({
              ...user,
              username: initDataUnsafe?.user?.username || '',
              ...info
            })

            typeof success == 'function' &&
              success({
                ...user,
                username: initDataUnsafe?.user?.username || '',
                ...info
              })
            Toast.clear()

            return true
          })
          .catch((err) => {
            Toast.clear()
            console.error(err)
            toast.error(err?.response?.data?.msg || err.message)
            return false
          })
          .finally(() => {
            Toast.clear()
          })
      }

      return await doLogin()
    } else if (target == 'tonProof') {
      const doLogin = async () => {
        return await tonProofLogin(initData as string, params as TonProofParams)
          .then(async (res) => {
            _setUser({
              username: initDataUnsafe?.user?.username || '',
              ...(res?.user || {})
            })

            typeof success == 'function' &&
              success({
                username: initDataUnsafe?.user?.username || '',
                ...(res?.user || {})
              })
            Toast.clear()

            return true
          })
          .catch((err) => {
            Toast.clear()
            console.error(err)
            toast.error(err?.response?.data?.msg || err.message)
            return false
          })
          .finally(() => {
            Toast.clear()
          })
      }
      return await doLogin()
    } else {
      const doLogin = async () => {
        return await loginJavaApi(initData as string)
          .then(async (res) => {
            console.log(res)
            if (res.code !== 10000) return Toast.show('Authorization failed!')

            if (target == 'oauth') {
              Toast.show(
                'Authorization successful! Will close the webapp in 3 seconds.'
              )
            } else {
              const userId = res.result?.userId
              const token = res.result?.token
              const walletInfo = await getDefaultWalletByUserIdWithJwt(userId!, token)
              _setUser({
                username: initDataUnsafe?.user?.username || '',
                ...(res.result || {}),
                ...(walletInfo.result || {}),
              })
            }

            typeof success == 'function' &&
              success({
                username: initDataUnsafe?.user?.username || '',
                ...res.result
              })

            return true
          })
          .catch((err) => {
            Toast.clear()
            console.error(err)
            toast.error(err?.response?.data?.msg || err.message)
            return false
          })
          .finally(() => {
            Toast.clear()
          })
      }
      return await doLogin()
    }
  }

  return {
    login,
    btcType,
    btcWallet,
    setUser: (userInfo: UserType) => {
      setUser({
        ...userInfo,
        avatar: userInfo.avatar || ''
      })
      localStorage.setItem('user', JSON.stringify(userInfo))
    },
    user,
    isLogin: user !== null,
    setWalletType,
    walletType,
    walletAddress,
    // phraseWord,
    // bioStatus,
    // setBioStatus,
    evmAddress,
    solAddress,
    btcAddress,
    tronAddress,
    tonAddress,
    suiAddress: user?.suiAddress,
    tonAddressTest,
    tonPublicKey,
    setInviteCode,
    inviteCode,
    getAddressByToken: (token: IToken) => {
      if (!token || !user) return ''
      if (token.symbol == Web3Type.BTC) {
        if (walletType == 'bitcoinP2Pkh') {
          return btcAddress.bitcoinP2PkhAddress
        } else if (walletType == 'bitcoinP2Tr') {
          return btcAddress?.bitcoinP2TrAddress
        } else if (walletType == 'bitcoinP2Sh') {
          return btcAddress?.bitcoinP2ShAddress
        } else if (walletType == 'bitcoinP2Wpkh') {
          return btcAddress?.bitcoinP2WpkhAddress
        }
        return user?.bitcoinP2trAddress
      }
      if (token.symbol == Web3Type.SOL) {
        return user?.solanaAddress
      }
      if (token.symbol == Web3Type.TON) {
        return user?.tonAddress
      }
      // console.log({
      //   key: 'token',
      //   check: token.chain_id == mockTronChainId
      // })
      // if (token.chain_id == mockTronChainId) {
      //   return user?.tronAddress
      // }
      return user.v2Address
    },

    getAddressAsChainId: (token: IToken) => {
      if (!token || !user) return ''
      if (token.chain_id == 0) {
        if (walletType == 'bitcoinP2Pkh') {
          return btcAddress.bitcoinP2PkhAddress
        } else if (walletType == 'bitcoinP2Tr') {
          return btcAddress?.bitcoinP2TrAddress
        } else if (walletType == 'bitcoinP2Sh') {
          return btcAddress?.bitcoinP2ShAddress
        } else if (walletType == 'bitcoinP2Wpkh') {
          return btcAddress?.bitcoinP2WpkhAddress
        }
        return user?.bitcoinP2trAddress
      }
      if (token.chain_id == 501) {
        return user?.solanaAddress
      }
      if (token.chain_id == 1100) {
        return user?.tonAddress
      }
      if (token.chain_id == 1101) {
        return tonAddressTest
      }
      // if (token.chain_id == mockTronChainId) {
      //   return user?.tronAddress
      // }
      return user.v2Address
    },
    getRenderAddress: (chain: IWeb3ChainType | undefined) => {
      switch (chain?.type) {
        case Web3Type.EVM:
          return evmAddress
        case Web3Type.SOL:
          return solAddress
        case Web3Type.BTC:
          return btcAddress.bitcoinP2PkhAddress
        case Web3Type.TON:
          return tonAddress
        case Web3Type.TRON:
          return tronAddress
        default:
          return user?.v2Address
      }
    },
    logout,
    defaultEthereumAddress: user?.defaultEthereumAddress,
    ethereumAddress: user?.ethereumAddress,
    defaultWalletId: user?.defaultWalletId
  }
}
