import {
  tradePaswordStatus,
  firstSetTradePasword,
  setTradePasword,
  resetTradePwdEmail
  // validatePaymentPasswd
} from 'api'
import toast from '../components/Toast'
import { hashWithWebCrypto } from 'utils'
// import useLoginInfo from './useLoginInfo'

const successCode = 10000

const useTradePwd = () => {
  const checkTradePwd = async () => {
    try {
      const { code, result, message } = await tradePaswordStatus()
      if (code === successCode) {
        return result
      }
      // toast.error(message)
      return null
    } catch (err: any) {
      // toast.error(err?.response?.data?.message || err?.message || '')
      throw err?.response?.data?.message || err?.message || err
    }
  }
  const setTradePwd = async (passwd: string) => {
    console.log('passwd', passwd)
    try {
      const { code, data, message } = await firstSetTradePasword({
        newTradePassword: await hashWithWebCrypto(passwd)
      })
      if (code === successCode) {
        return true
      }
      throw message
    } catch (err: any) {
      throw err?.response?.data?.message || err?.message || err
    }
  }
  const changeTradePwd = async (oldpwd: string, newpwd: string) => {
    try {
      const { code, data, message } = await setTradePasword({
        oldTradePassword: await hashWithWebCrypto(oldpwd),
        newTradePassword: await hashWithWebCrypto(newpwd)
      })
      if (code === successCode) {
        return true
      }
      toast.error(message)
      return false
    } catch (err: any) {
      toast.error(err?.response?.data?.message || err?.message || '')
    }
  }

  const resetTradePwd = async (mailCode: string, pass: string) => {
    try {
      const { code, data, message } = await resetTradePwdEmail({
        code: mailCode,
        tradePassword: await hashWithWebCrypto(pass)
      })
      if (code === successCode) {
        return true
      }
      throw message
    } catch (err: any) {
      throw err?.response?.data?.message || err?.message || err
    }
  }

  return {
    checkTradePwd,
    setTradePwd,
    changeTradePwd,
    resetTradePwd
  }
}

export default useTradePwd
