import axios from 'axios'
import { intRandom } from 'utils/helper'
import { getPassKey, getUserInfo, getSdkHash, logout } from 'utils'
// import {
//   BtcCreateSendBtcPsbt,
//   BtcSignPsbt,
//   ISwapTokensParams,
//   MemeOverview,
//   PriceItem,
//   Result,
//   SolSendTx,
//   TokenInfo,
//   TradeToken,
//   TrendingToken,
//   BtcPushTx
// } from './type'
import { config } from 'antd-mobile/es/components/toast/methods'
// import { ISwapTokenTypeV2 } from 'pages/swap/hooks/useSwapTokensV2'
import { IChainId } from 'proviers/web3Provider/type'
import { sentryInterceptors } from '@/utils/sentry/axiosInterceptors'
import { errorContents } from '@/config/const'
import { Result, TrendingToken } from './type'

export type PageParams = {
  page: number
  limit: number
  skip: number
}
export const successCode = 8000
console.log('import.meta.env.VITE_NODE_ENV', import.meta.env.VITE_NODE_ENV)
const index = axios.create({ baseURL: import.meta.env.VITE_BOT_API }) //bot-server/
const avpimApi = axios.create({ baseURL: import.meta.env.VITE_AVPIM_API }) //
const tomoApi = axios.create({ baseURL: import.meta.env.VITE_TOMO_API }) //api/
const tomoApi2 = axios.create({ baseURL: import.meta.env.VITE_TOMO_API2 }) //api/
export const authApi = axios.create({ baseURL: import.meta.env.VITE_AUTH_API }) //tg-auth/
const avpimTomoApi = axios.create({ baseURL: 'https://avpim.tomo.inc' }) //tg-auth/
const configApi = axios.create({ baseURL: import.meta.env.VITE_CONFIG_API }) //tg-auth/
const mempoolApi = axios.create({ baseURL: import.meta.env.VITE_MEMPOOL_API })

;[index, avpimApi, tomoApi, tomoApi2, authApi].forEach((apiItem) => {
  sentryInterceptors(apiItem)

  apiItem.interceptors.request.use(
    (config) => {
      const { token } = getUserInfo()
      const passKey = getPassKey()
      // const sdkHash = getSdkHash()
      if (
        passKey &&
        (config.baseURL === import.meta.env.VITE_TOMO_API ||
          config.baseURL === import.meta.env.VITE_TOMO_API2)
      ) {
        config.headers.mfa = passKey
      }
      if (config.baseURL === import.meta.env.VITE_TOMO_API2) {
        config.headers['tomo-session-id'] = getSdkHash()
      }
      // if (passKey) {
      // }
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }

      /*
         else {
          // default token
          config.headers.Authorization = `Bearer ${IS_DEV ? devToken : prodToken}`
        }
        */

      return config
    },
    (error) => {
      console.log(error)
      if (error?.response?.status === 401) {
        return Promise.reject(error)
      }
    }
  )
  apiItem.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        if (location.hostname === 'localhost') {
          // no redirect in localhost
          console.error('401')
        } else {
          logout()
          window.location.href = '/login'
        }
        return
      }

      return Promise.reject(error)
    }
  )
})
;[avpimTomoApi, configApi, mempoolApi].forEach((apiItem) => {
  sentryInterceptors(apiItem)
})

export const loginApi = async (query: string, session_code?: string) => {
  const headers: any = {}
  if (session_code) {
    headers['session-code'] = session_code
  }
  const ret = await index.get(`login?${query}`, {
    headers: headers
  })
  return ret.data
}

export const getTransactionList: (params: PageParams) => Promise<
  {
    id: number
    method: 'in' | 'out'
    value: number
    status: 'success' | 'pending' | 'failed'
    timestamp: number
  }[]
> = async (params: any) => {
  // console.log('getTransactionList', { params })
  if (params.page > 3) {
    return []
  }
  //return 10 data after 1500ms
  const statusList: ['success', 'pending', 'failed'] = [
    'success',
    'pending',
    'failed'
  ]
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(
        Array.from({ length: 10 }).map((_, index) => ({
          id: intRandom(100, 100000),
          method: intRandom(1, 2) == 0 ? 'in' : 'out',
          value: Math.random() * 99999,
          status: statusList[intRandom(0, 2)],
          timestamp: new Date().getTime()
        }))
      )
    }, 1500)
  })
}

export const keyShareApi = async () => {
  const ret = await tomoApi.get(`user/keyShare`, {})
  return ret.data
}

export type INetworkInfoType = {
  chainID: number
  img: string
  name: string
  rpc: string
}

export type INetworkType = {
  mainnet: INetworkInfoType[]
  testnet: INetworkInfoType[]
}

export const networksApi = async (): Promise<INetworkType> => {
  const ret = await index.get(`/networks`, {})
  return ret.data
}

export const regKeyApi = async () => {
  const res = await avpimApi.post('pk/reg', {})
  // console.log('apiRes', res)
  return res.data
}

export const signKeyApi = async (data: { message: string; pubkey: string }) => {
  const res = await avpimApi.post('pk/sign', data)
  return res.data
}

export const v2PkRegApi = async (device_no: string) => {
  const res = await avpimApi.post('pk/v2/reg/' + device_no, {})
  // console.log('apiRes', res)
  return res.data
}

export const v2PkSignApi = async (data: {
  message: string
  device_no: string
}) => {
  const res = await avpimApi.post('pk/v2/sign', data)
  return res.data
}
export const v2PkCheckApi = async (device_no: string) => {
  const res = await authApi.get('v2/passkey/check/' + device_no)
  return res.data
}
export const v2PkCreateApi = async (data: {
  device_no: string
  pubkey: string
}) => {
  const res = await authApi.post('v2/passkey/create/', data)
  return res.data
}
export const v2PkValidateApi = async (data: {
  device_no: string
  message: string
  sig: string
}) => {
  const res = await authApi.post('v2/passkey/validate/', data)
  return res.data
}
// export const getTradingTokens = async (): Promise<TradeToken[]> => {
//   // https://app.unyx.tech/api/
//   // import.meta.env.VITE_TOMO_API
//   const ret = await axios.get(
//     `${import.meta.env.VITE_TOMO_API}wallet/tradingTokens`
//   )
//   return ret.data.result
// }

// export const getAllTokens = async (): Promise<TokenInfo[]> => {
//   const ret = await avpimApi.get(`/tg-auth/v1/token/all`)
//   return ret.data.data
// }

export const getAllBalance = async (params: {
  evm_address: string
  solana_address: string
}): Promise<
  {
    balance: string
    chain_id: number
    contract: string
    decimals: number
    image: string
    is_native: boolean
    mercuryo_support: string
    name: string
    price: number
    ramp_support: string
    symbol: string
  }[]
> => {
  const ret = await avpimApi.get(`/tg-auth/v1/token/balance`, { params })
  return ret.data.data
}

export const createPassKeyApi = async (data: {
  kind: string
  pubkey: string
}) => {
  const res = await authApi.post('v1/passkey/create', data)
  // console.log('apiRes', res)
  return res.data
}

export const checkPasskeyApi = async (kind: string) => {
  try {
    const res = await authApi.get('v1/passkey/check/' + kind)

    return res.data
  } catch (error) {
    localStorage.removeItem('user')
    localStorage.removeItem('pass-key')
    localStorage.removeItem('pubkey')
    throw error
  }
}

export const validatePasskeyApi = async (data: {
  kind: string
  message: string
  sig: string
}) => {
  const res = await authApi.post('v1/passkey/validate', data)
  return res.data
}

export const evmSignApi = async (data: {
  tx: string
  kind: string
  sig: string
  chainid: number
}) => {
  const res = await authApi.post('v1/evm/sign', data)
  return res.data
}

export const sendTranSactionApi = async ({
  url,
  raw
}: {
  url: string
  raw: string
}) => {
  const ret = await axios.post(url, {
    jsonrpc: '2.0',
    method: 'eth_sendRawTransaction',
    id: new Date().getTime(),
    params: [raw]
  })
  return ret
}

export const signEvmTransaction = async (
  mfa: string,
  data: {
    transaction: {
      data: string | undefined
      gas: string | undefined
      gasPrice: string | undefined
      maxFeePerGas: string | undefined
      maxPriorityFeePerGas: string | undefined
      nonce: number | undefined
      to: string | undefined
      value: string | undefined
    }
    chainId: IChainId
    rpc: string | undefined | null
    walletId: number
  }
) => {
  const { token } = getUserInfo()
  const res = await tomoApi2.post(
    'socialLogin/projectWallet/ethereum/signTransaction',
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        mfa: mfa
      }
    }
  )
  return res.data
}

export const sendEvmTransactionApi = async (
  mfa: string,
  data: {
    transaction: { [key: string]: any }
    chainId: number
    rpc: string
  }
) => {
  const { token } = getUserInfo()

  const res = await tomoApi.post(
    'socialLogin/projectWallet/ethereum/sendTransaction',
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        MFA: mfa
      }
    }
  )
  return res.data
}

export const tronSignRawTransaction = async (params: SolSendTx) => {
  const { data } = await tomoApi.post(
    `socialLogin/projectWallet/tron/signRawTransaction`,
    params
  )
  return data
}

export const solSignRawTransactionWithMFA = async ({
  mfa,
  data
}: {
  mfa: string
  data: any
}) => {
  const { token } = getUserInfo()
  const res = await tomoApi2.post(
    '/socialLogin/projectWallet/solana/signRawTransaction',
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        mfa: mfa
      }
    }
  )
  return res.data
}

export const getTokenDetailByAddress = async (
  chain: string,
  address: string
) => {
  const { data } = await tomoApi.get(
    '/socialLogin/projectWallet/token/getByAddressAndChain',
    {
      params: { chain, address }
    }
  )
  return data.result
}

export const solSignMessageWithMFA = async ({
  mfa,
  data
}: {
  mfa: string
  data: { message: string; walletId: number }
}) => {
  const { token } = getUserInfo()
  const res = await tomoApi2.post(
    '/socialLogin/projectWallet/solana/signMessage',
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        mfa: mfa
      }
    }
  )
  return res.data
}

export const solSignRawTransaction = async (params: SolSendTx) => {
  const { data } = await tomoApi.post(
    `/socialLogin/projectWallet/solana/signRawTransaction`,
    params
  )
  return data
}

export const btcSignPsbt = async (params: BtcSignPsbt) => {
  const { data } = await tomoApi.post(
    `/socialLogin/projectWallet/bitcoin/signPsbt`,
    params
  )
  return data
}

// send BTC
export const btcCreateSendBtcPsbt = async (params: BtcCreateSendBtcPsbt) => {
  const { data } = await tomoApi.post(
    `/socialLogin/projectWallet/bitcoin/createSendBitcoinPsbt`,
    params
  )
  return data
}

export const decodeInfoByHex = async (params: any) => {
  const { data } = await tomoApi.post(
    `/socialLogin/projectWallet/bitcoin/decode`,
    params
  )
  return data
}

export const btcSignMessage = async (params: any) => {
  const { data } = await tomoApi.post(
    `/socialLogin/projectWallet/bitcoin/signMessage`,
    params
  )
  return data
}

export const btcPushTx = async (params: BtcPushTx) => {
  const { data } = await tomoApi.post(
    `/socialLogin/projectWallet/bitcoin/pushTransaction`,
    params
  )
  return data
}

export const btcSignPsbtAndPush = async (params: BtcSignPsbt) => {
  const { data } = await tomoApi.post(
    `/socialLogin/projectWallet/bitcoin/signPsbtAndPush`,
    params
  )
  return data
}

export const btcAddressAndPK = async (networkType: string) => {
  try {
    const res = await tomoApi.get(
      `/socialLogin/projectWallet/bitcoin/address?networkType=${networkType}`
    )
    return res.data
  } catch (err) {
    console.log('req btcAddressAndPK failed: ', err)
    return {}
  }
}

export const getBtcTipHeight = async () => {
  try {
    const res = await mempoolApi.get(`/api/blocks/tip/height`)
    return res.data
  } catch (err) {
    console.log('req getBtcHeight failed: ', err)
    return null
  }
}

export const getBtcNetworkFees = async () => {
  try {
    const res = await mempoolApi.get(`/api/v1/fees/recommended`)
    return res.data
  } catch (err) {
    console.log('req getBtcNetworkFees failed: ', err)
    return null
  }
}

export const getBtcUtxos = async (address: string) => {
  try {
    const res = await mempoolApi.get(`/api/address/${address}/utxo`)
    return res.data
  } catch (err) {
    console.log('req getBtcUtxos failed: ', err)
    return []
  }
}

export const validateAddressUrl = async (address: string) => {
  try {
    const res = await mempoolApi.get(`/api/v1/validate-address/${address}`)
    return res.data
  } catch (err) {
    console.log('req validateAddressUrl failed: ', err)
    return []
  }
}

export const getTxInfo = async (txId: string) => {
  try {
    const res = await mempoolApi.get(`/api/tx/${txId}`)
    return res.data
  } catch (err) {
    console.log('req getTxInfo failed: ', err)
    return []
  }
}

export const ReferralApi = async () => {
  const { data } = await authApi.get(`/v1/referral`)
  return data
}

export const ReferralBindApi = async (code: string) => {
  const { data } = await authApi.post(`/v1/referral/bind/${code}`)
  return data
}

export const ReferralBindApiV2 = async (code: string) => {
  const { data } = await tomoApi.post(`socialLogin/teleGram/bindInviteCode`, {
    inviteCode: code
  })
  return data
}

// https://tg-dev.tomo.inc/fans-compose-es-backend/api/v3/memes-trading?chain=56&page=1&size=10&keyword=1inch

export const getSwapAllTokens = async (
  queryParameters: ISwapTokensParams
): Promise<any> => {
  const ret = await avpimApi.get(
    `/fans-compose-es-backend/api/v3/memes-trading`,
    // `/wallet/tokens/search`,
    {
      params: queryParameters
    }
  )
  return ret.data
}

export const getSwapAllTokensV2 = async (): Promise<{
  result: ISwapTokenTypeV2[]
}> => {
  const ret = await tomoApi.get(`socialLogin/teleGram/wallet/tokens`, {})
  return ret.data
}

/*
https://app.unyx.tech/api/
*/
export const getSwapAllTokensV3 = async (params: {
  chain: string
}): Promise<{
  result: ISwapTokenTypeV2[]
}> => {
  const ret = await axios.get(
    `${import.meta.env.VITE_TOMO_API}token/swapList`,
    {
      params
    }
  )
  return ret.data
}

export const getSwapAllTokensV2Search = async (queryParameters: {
  content: string
  chain?: string
}): Promise<{
  result: ISwapTokenTypeV2[]
}> => {
  const ret = await axios.get(
    `${import.meta.env.VITE_TOMO_API}wallet/tokens/search`,
    // `/wallet/tokens/search`,
    {
      params: queryParameters
    }
  )
  return ret.data
}
export const v1AddAssetApi = async (data: {
  chain_id: number
  decimals: number
  image?: string
  name?: string
  symbol: string
  token: string
}) => {
  const res = await authApi.post('v1/asset/add', data)
  // console.log('apiRes', res)
  return res.data
}
export const v1AllAssetApi = async (params: {
  page: number
  pageSize: number
  chain_ids?: number[]
}) => {
  const res = await authApi.get('v1/asset/all', { params })
  return res.data.data
}
// https://app.unyx.tech/api/socialLogin/projectWallet/ethereum/signMessage
export const signEvmMessage = async (
  mfa: string,
  data: {
    message: string
    walletId?: number
  }
) => {
  const { token } = getUserInfo()
  const res = await tomoApi2.post(
    'socialLogin/projectWallet/ethereum/signMessage',
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        mfa: mfa
      }
    }
  )
  return res.data
}

export const tonSignMessage = async (
  mfa: string,
  data: { signingMessageBoc: string; stateInitBoc: string }
) => {
  const { token } = getUserInfo()

  const res = await tomoApi2.post(
    '/socialLogin/projectWallet/ton/signTransaction',
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        MFA: mfa
      }
    }
  )
  return res.data
}

export const getTotalPoint = async (): Promise<{ result: string }> => {
  const ret = await tomoApi.get(`socialLogin/teleGram/totalPoint`)
  return ret.data
}

export const getInviteCode = async (): Promise<{
  result: {
    inviteCode: string
    bindFlag: boolean
  }
}> => {
  const res = await tomoApi.get(`socialLogin/teleGram/myInviteCode`)
  return res.data
}

export const getInviteCount = async (): Promise<{
  result: string
}> => {
  const res = await tomoApi.get(`socialLogin/teleGram/inviteTotalNum`)
  return res.data
}

export const getReferralInfo = async (): Promise<{
  userId: number
  totalRefund: string
  inviteCount: number
}> => {
  const res = await tomoApi.get('/socialLogin/teleGram/referral/info')
  return res.data?.result
}

export const getInvitedUserList = async (): Promise<
  {
    avatar: string
    inviteCount: number
    nickname: string
    totalAttribute: string
    totalRefund: string
    username: string
  }[]
> => {
  const res = await tomoApi.get(`socialLogin/teleGram/v2/inviteList`, {
    params: { cursor: 1, size: 2000 }
  })
  return res?.data?.result
}

export interface Task {
  completed: boolean
  name: string
  point: string
}
export const getTasksStatus = async (): Promise<Record<string, Task[]>> => {
  const res = await tomoApi.get(`socialLogin/teleGram/v2/task/status`)
  return res.data.result
}

export const submitRamp = async (symbol: string): Promise<any> => {
  const res = await tomoApi.post(
    `socialLogin/teleGram/task/ramp?value=${symbol}`
  )
  return res.data.result
}

export type SocialMediaPlatform = 'x' | 'ig' | 'tiktok'

export const submitFollowSocialMedia = async (
  platform: SocialMediaPlatform
): Promise<any> => {
  const res = await tomoApi.post(
    `socialLogin/teleGram/task/follow?value=${platform}`
  )
  return res.data.result
}

export enum NewTaskType {
  FollowTgGroup = 1,
  FollowTgChannel = 2,
  FollowTomoWalletOnX = 3
}

export const submitFollowTg = async (taskType: NewTaskType): Promise<any> => {
  const res = await tomoApi.post(`socialLogin/teleGram/finishTgTask`, {
    taskType
  })
  return res.data.result
}

export const submitSwap = async (symbol: string): Promise<any> => {
  const res = await tomoApi.post(
    `socialLogin/teleGram/task/swap?value=${symbol}`
  )
  return res.data.result
}

export const getTrendingTokens = async () => {
  // const { data } = await tomoApi.get(`wallet/trendingTokens`)
  const { data } = await tomoApi.get<Result<TrendingToken[]>>(
    `/socialLogin/teleGram/wallet/trendingTokens`
  )
  return data.result
}
export const getAllTokens = async () => {
  const { data } = await tomoApi.get<Result<TrendingToken[]>>(
    `/socialLogin/teleGram/wallet/tokens`
  )
  return data.result
}

export const getSelectedTokens = async () => {
  const { data } = await tomoApi.get<Result<TrendingToken[]>>(
    `/socialLogin/teleGram/wallet/selectedTokens`
  )
  return data.result
}

export const getTokenByName = async (tokenName: string) => {
  const { data } = await tomoApi.get<Result<TrendingToken>>(
    `/socialLogin/teleGram/getByName`,
    {
      params: { tokenName }
    }
  )
  return data.result
}

export const getTokenSearch = async (content: string, chain: string) => {
  const { data } = await tomoApi.get<Result<TrendingToken[]>>(
    `/socialLogin/teleGram/wallet/tokens/search`,
    {
      params: chain ? { chain, content } : { content }
    }
  )
  return data.result
}

export type IntervalType =
  | 'hour'
  | 'four_hour'
  | 'day'
  | 'week'
  | 'month'
  | 'minute'
  | 'five_minute'
  | 'fifteen_minute'

export const getTokenPrices = async (
  tokenName: string,
  interval: IntervalType
) => {
  const { data } = await tomoApi.get<Result<PriceItem[]>>(
    `/socialLogin/teleGram/${tokenName}/OHLCV`,
    {
      params: { interval: interval.toLocaleUpperCase() }
    }
  )
  return data.result
}

export const getMemesOverview = async (chain: string, address: string) => {
  const { data } = await avpimTomoApi.get<Result<MemeOverview>>(
    `/prod/avp_tomoji/v4/memes-overview`,
    {
      params: {
        address,
        chain
      }
    }
  )
  return data.result
}

export const getFavouriteTokens = async () => {
  const { data } = await tomoApi.get<Result<TrendingToken[]>>(
    `/socialLogin/teleGram/wallet/favouriteTokens`
  )
  return data.result
}

export const favouriteTokens = async (tokens: string[]) => {
  const { id } = getUserInfo()
  const { data } = await tomoApi.post<Result<TrendingToken[]>>(
    `/socialLogin/teleGram/wallet/favouriteTokens`,
    {
      userId: id,
      tokens
    }
  )
  return data.result
}

export const checkPaymentPasswd = async () => {
  const res = await authApi.get('v1/payment/passwd/check', {})
  return res.data
}
export const setPaymentPasswd = async (data: { passwd: string }) => {
  const res = await authApi.post('v1/payment/passwd/set', data)
  return res.data
}
export const changePaymentPasswd = async (data: {
  old_passwd: string
  new_passwd: string
}) => {
  const res = await authApi.post('v1/payment/passwd/change', data)
  return res.data
}
export const validatePaymentPasswd = async (data: { passwd: string }) => {
  const res = await authApi.post('v1/payment/passwd/validate', data)
  return res.data
}
export const bindEmailCodeVerify = async (params: {
  email: string
  code: string
}) => {
  const { data } = await tomoApi.post(
    `/socialLogin/projectUser/bindEmailCodeVerify`,
    params
  )
  return data
}
export const bindEmailCodeSend = async (email: string) => {
  const { data } = await tomoApi.get(
    `/socialLogin/projectUser/bindEmailCode?email=${email}`
  )
  return data
}

export const bindRecoverCodeSend = async (email: string) => {
  const { data } = await tomoApi.get(
    `/socialLogin/projectUser/bindRecoverEmailCode?email=${email}`
  )
  return data
}

export const bindRecoverEmailCodeVerify = async (params: {
  email: string
  code: string
}) => {
  const { data } = await tomoApi.post(
    `/socialLogin/projectUser/bindRecoverEmailCodeVerifyToken`,
    params
  )
  return data
}

export const getSocialInfo = async (token?: string) => {
  let userToken = token
  if (!userToken) {
    const { token } = getUserInfo()
    userToken = token
  }
  const res = await tomoApi.get(`/socialLogin/projectUser/socialInfo`, {
    headers: {
      Authorization: `Bearer ${userToken}`
    }
  })
  return res?.data || { result: {} }
}

export const getConfigChainsAll = async (): Promise<{
  chain_id_name: {
    [key in IChainId]?: string
  }
}> => {
  const { data } = await index.get(`/chain/all`)
  return data.result
}

// https://app.tomo.inc/api/socialLogin/teleGram/wallet/tokens/v2/preciseSearch?address=0x9eC02756A559700d8d9e79ECe56809f7bcC5dc27&chain=ETH

export const getSwapTokenByTokenInfo = async (params: {
  address: string
  chain: string
}): Promise<ISwapTokenTypeV2[]> => {
  const { data } = await tomoApi.get(
    '/socialLogin/teleGram/wallet/tokens/v2/preciseSearch',
    {
      params
    }
  )
  return data.result
}

// login and register
export const reLoginEmailSend = async () => {
  const res = await authApi.get('v1/email/send', {})
  return res.data
}
export const reLoginEmailVerify = async (data: {
  device_no: string
  code: string
}) => {
  const res = await authApi.post('v1/email/verify', data)
  return res.data
}
export const reLoginIncreaseAuth = async (data: {
  deadline: number
  device_no: string
  encry_data: string
}) => {
  const res = await avpimApi.post('pk/v2/increase', data)
  return res.data
}
export const reLoginPassIncrease = async (data: {
  deadline: number
  device_no: 'string'
  encry_data: 'string'
  pubkey: 'string'
}) => {
  const res = await authApi.post('v2/passkey/increase', data)
  return res.data
}
export const tradePaswordStatus = async () => {
  const ret = await tomoApi.get(
    `socialLogin/teleGram/user/tradePaswordStatus`,
    {}
  )
  return ret.data
}
export const sendTradePwdEmail = async () => {
  const ret = await tomoApi.get(
    `socialLogin/teleGram/user/sendTradePwdEmail`,
    {}
  )
  return ret.data
}
export const firstSetTradePasword = async (data: {
  newTradePassword: string
}) => {
  const ret = await tomoApi.post(
    `socialLogin/teleGram/user/firstSetTradePasword`,
    data
  )
  return ret.data
}
export const setTradePasword = async (data: {
  oldTradePassword: string
  newTradePassword: string
}) => {
  const ret = await tomoApi.post(
    `socialLogin/teleGram/user/setTradePasword`,
    data
  )
  return ret.data
}

export const mfaAuthVerificationApi = async (data: any) => {
  const ret = await tomoApi.post(`socialLogin/mfa/auth/verification`, data)
  return ret.data
}
export const mfaAuthVerificationApiV2 = async (data: any) => {
  const ret = await tomoApi.post(`socialLogin/mfa/auth/verification/v2`, data)
  return ret.data
}
export const verifyTradeEmail = async (code: string) => {
  const ret = await tomoApi.post(`socialLogin/teleGram/user/verifyTradeEmail`, {
    code
  })
  return ret.data
}
export const getTelegramUserInfoApi = async () => {
  const res = await tomoApi.get(`socialLogin/teleGram/userInfo`)
  return res.data
}

export const getPayload = async () => {
  const res = await index.get('sdk/payload', {
    headers: {
      'tomo-session-id': getSdkHash()
    }
  })
  return res.data
}

export const sdkLogin = async (query: string) => {
  const ret = await index.get(`sdk/login?${query}`, {
    headers: {
      'tomo-session-id': getSdkHash()
    }
  })
  return ret.data
}

export const walletImport = async (data: { mnemonic: string }) => {
  const res = await tomoApi2.post('socialLogin/projectWallet/import', data)
  return res.data
}
export const walletList = async () => {
  const res = await tomoApi.get('socialLogin/projectWallet/list')
  return res.data
}
export const walletCurrent = async () => {
  const res = await tomoApi.get('socialLogin/projectWallet/current')
  return res.data
}
export const walletSwitch = async (
  data: { walletId: number },
  headers = {}
) => {
  const res = await tomoApi2.post('socialLogin/projectWallet/current', data, {
    headers
  })
  return res.data
}
export const sdkReject = async () => {
  const ret = await index.post(
    `sdk/reject`,
    {},
    {
      headers: {
        'tomo-session-id': getSdkHash()
      }
    }
  )
  return ret.data
}

export const resetTradePwdEmail = async (data: {
  code: string
  tradePassword: string
}) => {
  const ret = await tomoApi.post(
    `socialLogin/teleGram/user/resetTradePasword`,
    data
  )
  return ret.data
}

export const tonProofLogin = async (
  query: string,
  data: { domain: string; payload: string; workChain: number }
) => {
  const ret = await index.post(`sdk/ton/proof?${query}`, data, {
    headers: {
      'tomo-session-id': getSdkHash()
    }
  })
  return ret.data
}

export const loginJavaApi = async (initData: string) => {
  const res = await tomoApi.post(
    'socialLogin/projectUser/loginByTelegramMini',
    {
      telegramAuthData: initData
    }
  )
  return res.data
}

export const suiSignTransaction = async ({
  mfa,
  params
}: {
  mfa: string
  params: { tx: string }
}) => {
  const { token } = getUserInfo()
  const { data } = await tomoApi2
    .post(`/socialLogin/projectWallet/sui/signTransaction`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
        MFA: mfa
      }
    })
    .catch((error) => {
      const message = error?.response?.data?.message
      return Promise.reject(message ? message : errorContents.transactionError)
    })
  return data
}

export const suiSignMessage = async ({
  mfa,
  params
}: {
  mfa: string
  params: { message: string }
}) => {
  const { token } = getUserInfo()
  const { data } = await tomoApi2
    .post(`/socialLogin/projectWallet/sui/signPersonalMessage`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
        MFA: mfa
      }
    })
    .catch((error) => {
      const message = error?.response?.data?.message
      return Promise.reject(message ? message : errorContents.transactionError)
    })
  return data
}

export const getDefaultWalletByUserId = async (userId: number) => {
  const { token } = getUserInfo()
  const { data } = await tomoApi2
    .get(`/socialLogin/projectWallet/getDefaultWalletByUserId?userId=${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .catch((error) => {
      const message = error?.response?.data?.message
      return Promise.reject(message ? message : errorContents.transactionError)
    })
  return data
}

export const getDefaultWalletByUserIdWithJwt = async (userId: number, token?: string) => {
  const { token: localToken } = getUserInfo()
  const { data } = await tomoApi
    .get(`socialLogin/projectWallet/getDefaultWalletByUserId?userId=${userId}`, {
      headers: {
        Authorization: `Bearer ${token || localToken}`
      }
    })
    .catch((error) => {
      const message = error?.response?.data?.message
      return Promise.reject(message ? message : errorContents.transactionError)
    })
  return data
}
