import {
  mfaAuthVerificationApi,
  successCode,
  v2PkSignApi,
  v2PkValidateApi
} from '../api'
import useBiometricManager from './useBiometricManager'
import { useDeviceId } from './useDeviceId'
import { hashWithWebCrypto, isDevMode } from '../utils'
import toast from '../components/Toast'
import useLoginInfo from './useLoginInfo'
import { Toast } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import useBackupSetting from './useBackupSetting'
import { useVerifyModal } from './useVerifyModal'

export const useMfa = () => {
  const biometricManager = useBiometricManager()
  const { create, getDeviceIdFromToken } = useDeviceId()
  const { logout, user } = useLoginInfo()
  const navigate = useNavigate()
  const { setBackupSetting } = useBackupSetting()
  const { showVerify } = useVerifyModal()

  // const [isSendBioAuth, setIsSendBioAuth] = useAtom(isSendBioAuthAtom)
  const signMessage = async (
    params: {
      content: any
      chainid: number
    },
    device_no: string
  ) => {
    const message = JSON.stringify(params.content)
    const signRes = await v2PkSignApi({
      message,
      device_no
    })
    if (!signRes) {
      throw new Error('sign key api failed')
    }
    if (signRes.code != successCode) {
      throw new Error(signRes.message)
    }
    const valRes = await v2PkValidateApi({
      device_no,
      message,
      sig: signRes.data.signature
    })
    if (!valRes) {
      throw new Error('validate passkey api failed')
    }
    if (valRes.code != successCode) {
      throw new Error(valRes.message)
    }
    return {
      signature: signRes.data.signature,
      mfa: valRes.data.key
    }
  }
  const getMfaParams = async (
    params: {
      content: any
      chainid: number
      faildCallBack?: (success: boolean) => void
    }
    // callback: (params: { signature: string; mfa: string }) => void
  ): Promise<{
    mfa: any
    signature: any
  }> => {
    return new Promise((resolve, reject) => {
      //check api verify
      try {
        showVerify()
          .then((data) => {
            resolve({
              mfa: data,
              signature: ''
            })
          })
          .catch((error) => {
            reject(error)
          })
      } catch (error) {
        console.log('errr', error)
        reject(error)
      }
    })
  }
  return { getMfaParams }
}
