import React, { useEffect } from 'react'
import { backupSettingAtom, pageSettingAtom } from 'state'
import { useAtom } from 'jotai/index'

export type BackupSetting = {
  show?: boolean
  forceShowLogin?: boolean
  onSkip?: () => void
  onConfirm?: () => void
  afterClose?: () => void
  showEmail?: boolean
  email?: string
  emailClose?: () => void
  emailSuccess?: () => void
  checkForLogin?: boolean
  showPin?: boolean
  pinClose?: (last: string, first?: string) => void
  pinStatus?: 'normal' | 'loading' | 'success'
  showFA2?: boolean
  FA2Pass?: () => void
  FA2Close?: () => void
  showPinVerify?: boolean
  pinVerified?: (last: string, first?: string) => void
  pinCanceled?: () => void
}

export const DEFAULT_BACKUP_SETTING = {
  show: false,
  forceShowLogin: false,
  showEmail: false,
  email: '',
  onSkip: () => {},
  onConfirm: () => {},
  afterClose: () => {},
  emailClose: () => {},
  emailSuccess: () => {},
  checkForLogin: false,
  showPin: false,
  pinClose: (last: string, first?: string) => {},
  pinStatus: 'normal',
  showFA2: false,
  FA2Close: () => {},
  showPinVerify: false,
  pinVerified: (last: string, first?: string) => {},
  pinCanceled: () => {}
} as BackupSetting

const useBackupSetting = (props?: BackupSetting) => {
  const [backupSetting, setBackupSetting] = useAtom(backupSettingAtom)
  const setBackupNew = (props: BackupSetting) => {
    setBackupSetting((old) => {
      return {
        ...old,
        ...props
      }
    })
  }
  useEffect(() => {
    props && setBackupNew(props)
  }, [])

  return {
    setBackupSetting: setBackupNew,
    setBackupOpen: (visible: boolean, forceShowLogin = false) => {
      setBackupNew({ show: visible, forceShowLogin })
    },
    backupSetting
  }
}

export default useBackupSetting
