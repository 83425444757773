import { BackButton, useWebApp } from '@vkruglikov/react-telegram-web-app'
// import { getPayload } from 'api'
import { Button } from 'components/Button'
import toast from 'components/Toast'
import useSdk from 'hooks/useSdk'
import useSendTransaction from 'hooks/useSendTransaction'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import useChains from 'hooks/useChains'
import useLoginInfo from 'hooks/useLoginInfo'
// import useWallet from 'hooks/useWallet'
import { logoWalletSvg } from 'assets'
import useApp from 'hooks/useApp'
import { hexToString, isHex } from 'viem'

function ListItem({
  title,
  children,
  className,
  np
}: {
  title: string
  children: React.ReactNode
  className?: string
  np?: boolean
}) {
  return (
    <div
      className={`flex items-center justify-between gap-[8px] ${
        np ? '' : 'py-[12px]'
      } ${className}`}
    >
      <div className={`text-lg text-[#757580]`}>{title}</div>
      <div className={`text-lg text-black`}>{children}</div>
    </div>
  )
}

export default function PersonalSign() {
  const { getPayload } = useSdk()
  const { getChain } = useChains()
  const { evmAddress, defaultEthereumAddress, defaultWalletId } = useLoginInfo()
  const webApp = useWebApp()
  const { webAppReject } = useApp()

  const { data: payloadData, isLoading } = useQuery({
    queryKey: ['sign-message'],
    queryFn: async () => {
      return await getPayload()
    }
  })

  const signParam = useMemo(() => {
    if ((payloadData?.data?.params || []).length) {
      const data = payloadData?.data?.params[0]

      return data
    }
    return ''
  }, [payloadData?.data?.params])

  const chainId = 1
  const chain = getChain(chainId)

  const [status, setStatus] = useState<'normal' | 'loading' | 'success'>(
    'normal'
  )

  const { signEVMMessage } = useSendTransaction({
    chainId: chainId
  })

  const handleConfirm = async () => {
    if (status !== 'normal') return
    // if (evmAddress != ) {
    //   toast.error('From address error.')
    //   return
    // }
    try {
      setStatus('loading')
      const signData = {
        message: signParam,
        walletId: defaultWalletId || -1
      }

      const result = await signEVMMessage(signData)
      if (result && result?.code == 10000) {
        setStatus('success')

        setTimeout(() => {
          // webApp.enableClosingConfirmation()
          // webApp.disableClosingConfirmation()
          webApp.close()
        }, 500)
      } else {
        setStatus('normal')
        throw result?.message || 'Network error.'
      }
    } catch (err: any) {
      toast.error(err?.response?.data?.message || err?.message || err)
      setStatus('normal')
    }
  }

  const showMessage = useMemo(() => {
    let message = signParam
    if (isHex(signParam)) {
      message = hexToString(signParam)
    }
    return message
  }, [signParam])

  return (
    <>
      <div
        className={`flex h-full flex-1 flex-col justify-between px-[16px] py-[20px]`}
      >
        <div className={`flex h-full flex-col`}>
          <div className="">
            {/* <div className="mt-5 flex w-full flex-col items-center justify-center gap-[30px] text-xl font-medium leading-[1.3] text-title"> */}
            {/* <img src={logoWalletSvg} className="size-14" alt="logo" /> */}

            <p className="text-[20px] font-bold leading-[1.3] text-title">
              Sign Message
            </p>
            {/* </div> */}

            <div
              className={
                'mb-[40px] mt-[20px] w-full rounded-[12px] bg-[#F9F9F9] py-[8px]'
              }
            >
              {/* <ListItem title={'Network'} np>
                <div
                  className={`flex items-center gap-[10px]  px-[8px] py-[6px] text-lg`}
                >
                  <img src={chain?.icon} className={`size-[16px]`} />
                  <span>{chain?.name}</span>
                </div>
              </ListItem>
              <div className={`my-[12px] h-px bg-[white]`}></div> */}

              <div className="max-h-[300px] min-h-[150px] overflow-hidden whitespace-pre-wrap break-words px-[20px] py-[12px] text-lg text-title">
                {showMessage || signParam || ''}
              </div>
            </div>
          </div>

          <div className={`grid w-full flex-1 grid-cols-2 items-end gap-5`}>
            <Button
              size="large"
              block
              onClick={() => webAppReject(false)}
              theme="ghost"
            >
              Reject
            </Button>
            <Button
              size="large"
              className="w-full"
              onClick={handleConfirm}
              status={status}
              disabled={
                isLoading ||
                !payloadData?.data ||
                !signParam ||
                !signParam.length
              }
            >
              Approve
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
