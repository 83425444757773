import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../type'
import * as wagmiChains from 'wagmi/chains'

const chain = wagmiChains.sepolia
const icon = chainsSvgs.ethSvg
const networkType: IWeb3NetworkType = 'custom'

const name = 'Nubit-O1-Testnet'
const chainInfo: IWeb3ChainType = {
  chain: {
    ...chain,
    id: 331239,
    name: name,
    nativeCurrency: {
      name: name,
      symbol: 'SepoliaETH',
      decimals: 18
    },
    rpcUrls: {
      default: {
        http: ['https://gamer-rpc.nubit.org']
      }
    },
    blockExplorers: {
      default: {
        name: 'SepoliaETHscan',
        url: 'https://gamer-explorer.nubit.org'
      }
    }
  },
  type: 'EVM',
  name: name,
  icon: icon,
  networkType
}

export default chainInfo
