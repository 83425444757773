import classNames from 'classnames'
import { useAtomValue } from 'jotai'
import React, { PropsWithChildren } from 'react'
import { pageSettingAtom } from 'state'

const Container = ({
  children,
  className
}: PropsWithChildren & {
  className?: string
}) => {
  const pageSetting = useAtomValue(pageSettingAtom)

  return (
    <div
      className={classNames(
        ' flex w-full flex-col items-center justify-start p-[20px] space-y-[16px] box-border ',
        className,
        {
          ' h-[calc(100%-45px)] ': !!pageSetting.navBar?.show
          // ' min-h-full ': !pageSetting.navBar?.show
        }
      )}
    >
      {children}
    </div>
  )
}

export default Container
