// import React from 'react'
import { createBrowserRouter, RouteObject } from 'react-router-dom'
import Layout from 'layout'
import * as Sentry from '@sentry/react'
// import Home from 'pages/Home'
// import Login from 'pages/Login'
import GuardRoute from './GuardRoute'
// import Test from 'pages/Test'
// import SelectNetwork from 'pages/SelectNetwork'
// import TokenDetail from 'pages/token/detail'
// import TokenReceive from 'pages/token/detail/Receive'
// import TokenSend from 'pages/token/send'
// import Logout from 'pages/Logout'
// import Manage from 'pages/Manage'
// import RecoveryEmail from 'pages/RecoveryEmail'
// import RecoveryPhrase from 'pages/RecoveryPhrase'
// import CheckPhrase from 'pages/CheckPhrase'
// import PrivateKey from 'pages/PrivateKey'
// import ShowPhrase from 'pages/ShowPhrase'
// import Invite from '../pages/Invite'
// import BioTest from '../pages/BioTest'
// import InviteNavigate from '../pages/InviteNavigate'
// import SwapOkx from 'pages/swap-okx/Swap'
// import SwapRangoBasic from '../pages/swap/Swap'
// import { ManageAssets } from 'pages/ManageAssets'
// import { Market } from 'pages/market/Market'
// import { MarketToken } from 'pages/market/Detail'
// import Task from 'pages/task/Task'
// import ShowSecret from 'pages/ShowSecret'
import ErrorBoundary from './ErrorBoundary'

import Oauth from 'pages/Oauth'
import Login from 'pages/Login'
import RecoveryEmail from 'pages/RecoveryEmail'
import PinPaySet from 'pages/components/pinpay/Set'
import BackupPage from 'pages/Backup'
import PinPayVerify from 'pages/components/pinpay/Verify'
import ForgetPage from 'pages/Forget'
import NotFound from 'pages/components/NotFound'
import NoEmail from 'pages/NoEmail'
import PageLoading from 'pages/PageLoading'
import RawData from '@/pages/RawData'
import NetworkFee from '@/pages/NetworkFee'

export type RouteConfig = RouteObject & {
  auth?: boolean
  children?: RouteConfig[]
  pin?: boolean
  email?: boolean
  recoverEmail?: boolean
}

const routes: RouteConfig[] = [
  {
    path: '/',
    element: (
      <ErrorBoundary>
        <Layout />
      </ErrorBoundary>
    ),
    children: [
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/recovery-email',
        element: <RecoveryEmail />,
        auth: true,
      },
      {
        path: '/verifyPinpay',
        element: <PinPayVerify />,
        auth: true
      },
      // {
      //   path: '/recovery-phrase',
      //   element: <RecoveryPhrase />,
      //   auth: true
      // },
      // {
      //   path: '/check-phrase',
      //   element: <CheckPhrase />,
      //   auth: true
      // },
      // {
      //   path: '/private-key',
      //   element: <PrivateKey />,
      //   auth: true
      // },
      // {
      //   path: '/show-secret',
      //   element: <ShowSecret />,
      //   auth: true
      // },
      // {
      //   path: '/test',
      //   element: <Test />
      // },
      // {
      //   path: '/logout',
      //   element: <Logout />,
      //   auth: true
      // },
      // {
      //   path: '/l/:inviteCode',
      //   element: <InviteNavigate />
      // },
      // {
      //   path: '/market',
      //   element: <Market />,
      //   auth: true
      // },
      // {
      //   path: '/market/token/:chain/:token',
      //   auth: true,
      //   element: <MarketToken />
      // },
      // {
      //   path: '/swap',
      //   // element: <Swap />,
      //   element: <SwapRangoBasic />,
      //   auth: true
      // },
      // {
      //   path: '/swap-okx',
      //   element: <SwapOkx />,
      //   auth: true
      // },
      // // {
      // //   path: '/swap-confirm',
      // //   element: <SwapConfirm />,
      // //   auth: true
      // // },
      // {
      //   path: '/manage',
      //   element: <Manage />,
      //   auth: true
      // },
      // {
      //   path: '/select-network',
      //   element: <SelectNetwork />,
      //   auth: true
      // },
      // // {
      // //   path: '/invite',
      // //   element: <Invite />
      // // },
      // {
      //   path: '/task',
      //   element: <Task />
      // },
      // {
      //   path: '/biotest',
      //   element: <BioTest />
      // },
      // {
      //   path: '/token/:chain/:address',
      //   auth: false,
      //   children: [
      //     {
      //       path: '/token/:chain/:address/send',
      //       element: <TokenSend />
      //     },
      //     {
      //       path: '/token/:chain/:address/receive',
      //       element: <TokenReceive />
      //     },
      //     {
      //       index: true,
      //       element: <TokenDetail />
      //     }
      //   ]
      // },
      // {
      //   path: '/manageAssets',
      //   element: <ManageAssets />,
      //   auth: true
      // },
      {
        path: '/setPinpay',
        element: <PinPaySet />,
        auth: true
      },
      {
        path: '/noEmail',
        element: <NoEmail />,
        auth: true
      },
      {
        path: '/forget',
        element: <ForgetPage />,
        auth: true,
        pin: true,
        email: true
      },
      {
        path: '/backup',
        element: <BackupPage />,
        auth: true,
        pin: true
      },
      {
        index: true,
        element: <Oauth />,
        auth: true,
        pin: true,
        recoverEmail: true
      },
      {
        path: '/raw-data',
        element: <RawData />,
        auth: true,
        pin: true
      },
      {
        path: '/network-fee',
        element: <NetworkFee />,
        auth: true,
        pin: true
      },
      {
        path: '/loading',
        element: <PageLoading />
      }
    ]
  },
  {
    path: '/*',
    element: <NotFound />
  }
]

const wrapWithGuard = (route: RouteConfig): RouteConfig => {
  if (route.auth || route.pin) {
    return {
      ...route,
      element: <GuardRoute route={route}>{route.element}</GuardRoute>
    }
  }

  if (route.children) {
    return {
      ...route,
      children: route.children.map(wrapWithGuard)
    }
  }

  return route
}

const guardedRoutes = routes.map(wrapWithGuard)
const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)
export const routers = sentryCreateBrowserRouter(guardedRoutes)
