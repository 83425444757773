import { useWebApp } from '@vkruglikov/react-telegram-web-app'

// import { useEffect } from 'react'
import { useAtom } from 'jotai'
import { bioAvailableAtom, bioInitedAtom, isTelegramReadyAtom } from 'state'
import { sleep } from 'utils'

export default function useBiometricManager() {
  const [bioAvailable, setBioAvailable] = useAtom(bioAvailableAtom)
  const [bioInited, setBioInited] = useAtom(bioInitedAtom)
  const WebApp = useWebApp()
  const biometryManager = WebApp?.BiometricManager
  const [isTelegramReady, setIsTelegramReady] = useAtom(isTelegramReadyAtom)

  const requestAccess = ({
    reason = 'We will use biometry to protect your account',
    callback
  }: {
    reason?: string
    callback: (success: boolean) => void
  }) => {
    if (!biometryManager?.isAccessGranted) {
      biometryManager?.requestAccess(
        {
          reason
        },
        (success: boolean) => {
          callback(success)
        }
      )
      return
    }
    callback(true)
  }

  const authenticate = ({
    reason = 'We need to authenticate you to continue',
    callback,
    failCallback
  }: {
    reason?: string
    callback: (success: boolean, token: string) => void
    failCallback?: (error: Error) => void
  }) => {
    if (!bioAvailable) {
      failCallback && failCallback(new Error('Biometry is not available'))
      return
    }

    if (!bioInited) {
      failCallback && failCallback(new Error('Biometry is not inited'))
      return
    }

    try {
      biometryManager?.authenticate(
        {
          reason
        },
        callback
      )
      return
    } catch (error) {
      requestAccess({
        callback: (success) => {
          if (!success) {
            failCallback &&
              failCallback(new Error('You must allow biometry to continue'))
            return
          }

          biometryManager?.authenticate(
            {
              reason
            },
            callback
          )
        }
      })
    }
  }

  const waitForBioavailable = async ({
    callback,
    timeoutMs = 6 * 100
  }: {
    callback: (bioTimes: number) => void
    timeoutMs?: number
  }) => {
    console.log(
      'WebApp.platform',
      WebApp.platform,
      WebApp,
      biometryManager.isBiometricAvailable,
      bioAvailable
    )
    if (WebApp.platform == 'tdesktop' || WebApp.platform == 'macos') {
      console.log('directly', WebApp)
      callback(0)
      return
    }
    const endTime = Date.now() + timeoutMs

    while (Date.now() < endTime) {
      if (biometryManager.isBiometricAvailable) {
        setBioAvailable(true)
        callback(5)
        break
      } else {
        await sleep(200)
      }
    }
    callback(5)
  }

  return {
    biometryManager,
    requestAccess,
    authenticate,
    bioAvailable,
    bioInited,
    setBioAvailable,
    setBioInited,
    waitForBioavailable,
    isTelegramReady,
    setIsTelegramReady
  }
}
