import { WalletType } from 'hooks/useLoginInfo'
// import { IWeb3ChainType, Web3Type } from 'proviers/web3Provider/type'
// import { mockSolEvmChainId } from 'config/sol'
// import { mockBtcEvmChainId } from 'config/btc'
// import { IS_DEV } from '../config/index'
import { mockTonChainId } from 'config/ton'
// import { mockTronChainId } from 'config/tron'
import CryptoJS from 'crypto-js'
import { IWeb3ChainType, Web3Type } from 'proviers/web3Provider/type'

export const TOMO_PASS_KEY = 'pass-key'

export function classNames(...classes: unknown[]): string {
  return classes.filter(Boolean).join(' ')
}

export const getUserInfo = () => {
  try {
    const userRaw = localStorage.getItem('user')
    const user = userRaw && JSON.parse(userRaw)
    // const token = user && JSON.parse(user)?.projectToken
    return {
      ...user,
      token: user?.token ?? ''
    }
  } catch (e) {
    return null
  }
}

export const getPassKey = () => {
  try {
    const token = localStorage.getItem(TOMO_PASS_KEY)
    return token ?? ''
  } catch (e) {
    return null
  }
}

export const setPassKey = (key: string) => {
  try {
    localStorage.setItem(TOMO_PASS_KEY, key)
  } catch (e) {
    // do something
  }
}

export const removePassKey = () => {
  try {
    localStorage.removeItem(TOMO_PASS_KEY)
  } catch (e) {
    // do something
  }
}

export const CLEAR_VERSION = '5'

export const isDev = () => {
  // return IS_DEV
  // return true;
  return !!localStorage.getItem('IS_DEV')
}

export const isLocationEnv = () => {
  return import.meta.env.VITE_NODE_ENV == 'location'
}

export const isDevEnv = () => {
  return import.meta.env.VITE_NODE_ENV == 'development'
}

export const isTestEnv = () => {
  return import.meta.env.VITE_NODE_ENV == 'test'
}

export const isDevMode = () => {
  return isLocationEnv() || isDevEnv()
}

export const numberFormat = (
  number: string | number | undefined,
  tFixNum = 4
) => {
  return Math.floor(Number(number) * 10 ** tFixNum) / 10 ** tFixNum
}
export const btcDefaultType = 'bitcoinP2Tr'

export const btcTypeMaps = {
  bitcoinP2Wpkh: 'Native Segwit',
  bitcoinP2Sh: 'Nested Segwit',
  bitcoinP2Tr: 'Taproot',
  bitcoinP2Pkh: 'Legacy'
}

export type IBtcAddressType =
  | 'bitcoinP2Wpkh'
  | 'bitcoinP2Sh'
  | 'bitcoinP2Tr'
  | 'bitcoinP2Pkh'

export const btcAddressTypeMaps: IBtcAddressType[] = [
  'bitcoinP2Wpkh',
  'bitcoinP2Sh',
  'bitcoinP2Tr',
  'bitcoinP2Pkh'
]

export const getBitCoinType = (type: IBtcAddressType | null) => {
  return type ? btcTypeMaps[type] : btcTypeMaps[btcDefaultType]
}

// 'P2PKH' | 'P2WPKH' | 'P2TR' | 'P2SH'
export enum BTCAddressType {
  P2PKH = 'P2PKH',
  P2WPKH = 'P2WPKH',
  P2TR = 'P2TR',
  P2SH = 'P2SH'
}

export const getBitCoinTypeBySend = (type: WalletType | null) => {
  switch (type) {
    case 'bitcoinP2Pkh':
      return BTCAddressType.P2PKH
    case 'bitcoinP2Wpkh':
      return BTCAddressType.P2WPKH
    case 'bitcoinP2Sh':
      return BTCAddressType.P2SH
    case 'bitcoinP2Tr':
      return BTCAddressType.P2TR
    default:
      return BTCAddressType.P2TR
  }
}

export async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const getChainId = (chain: IWeb3ChainType | undefined) => {
  const chainId = (() => {
    switch (chain?.type) {
      case Web3Type.EVM:
        return chain.chain?.id
      // case Web3Type.SOL:
      //   return mockSolEvmChainId
      // case Web3Type.BTC:
      //   return mockBtcEvmChainId
      case Web3Type.TON:
        return mockTonChainId
      // case Web3Type.TRON:
      //   return mockTronChainId
      // default:
      //   return undefined
    }
  })()

  return chainId
}

// export const isBtc = (chainId: number): boolean => {
//   return mockBtcEvmChainId === chainId
// }

// export async function hashWithWebCrypto(message: string) {
//   const msgBuffer = new TextEncoder().encode(message)
//
//   // const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)
//   const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)
//
//   // const hash = CryptoJS.SHA256(message).toString();
//
//   const hashArray = Array.from(new Uint8Array(hashBuffer))
//   const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
//
//   return hashHex
// }

export async function hashWithWebCrypto(message: string) {
  const msgBuffer = new TextEncoder().encode(message)

  if (typeof crypto !== 'undefined' && crypto.subtle) {
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
  } else {
    return CryptoJS.SHA256(message).toString()
  }
}

export function encrypt(text: string, secretKey: string) {
  return CryptoJS.AES.encrypt(text, secretKey).toString()
}

export function decrypt(encryptedMessage: string, secretKey: string) {
  try {
    return CryptoJS.AES.decrypt(encryptedMessage, secretKey).toString(
      CryptoJS.enc.Utf8
    )
  } catch (e) {
    return ''
  }
}

const sdkHashKey = 'sdkhash'
export function getSdkHash() {
  return sessionStorage.getItem(sdkHashKey)
}
export function setSdkHash(hash: string) {
  return sessionStorage.setItem(sdkHashKey, hash)
}
export function logout() {
  try {
    localStorage.removeItem('user')
    localStorage.removeItem('pass-key')
    localStorage.removeItem('TASKS_STATE')
    localStorage.removeItem('REFERRAL_QRCODE_IMAGE')
    localStorage.removeItem('sdkhash')
  } catch (err) {
    //
  }
}
