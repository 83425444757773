import { TextArea } from 'antd-mobile'
import { copybSvg, logoWalletSvg, trashSvg } from 'assets'
import Container from 'components/Container'
import toast from 'components/Toast'
import { useCallback, useEffect, useMemo, useState } from 'react'
import * as bip39 from 'bip39'
import { NativeProps } from 'antd-mobile/es/utils/native-props'
import { Button } from 'components/Button'
import useWallet from 'hooks/useWallet'
import { useWebApp } from '@vkruglikov/react-telegram-web-app'
import classNames from 'classnames'
import useApp from 'hooks/useApp'
import { createHash } from 'crypto'

function Parse({ onParse }: { onParse: (seeds: Array<string>) => void }) {
  const [seed, setSeed] = useState('')
  const [status, setStatus] = useState<'normal' | 'loading' | 'success'>(
    'normal'
  )
  const { webAppReject } = useApp()

  const handleConfirm = () => {
    if (!seed || !seed.trim().length) {
      toast.error('Seeds error.')
      return
    }
    const temp = seed.trim()
    const mnemonics = temp.split(' ')

    if (
      mnemonics.length != 12 &&
      mnemonics.length !== 24
      // && mnemonics.length !== 18
    ) {
      // toast.error('Only 12 words.')
      toast.error('Only 12 or 24 words.')
      return
    }
    onParse(mnemonics)
    setSeed('')
  }

  const disabled = !seed

  return (
    <div className="flex w-full flex-1 flex-col">
      <TextArea
        rows={4}
        placeholder="If you're using a seed phrase to import thewallet, separate each word with a space."
        value={seed}
        onChange={(val) => {
          setSeed(val.replace(/\s+/g, ' '))
        }}
        // showCount={(length: number, maxLength?: number) => {
        //   return (
        //     <button
        //       className="absolute bottom-5 right-[12px] flex items-center gap-2 rounded-lg border border-[#E3E3E3] bg-white px-3 py-2 text-lg font-normal leading-[1.3]"
        //       onClick={handleParse}
        //     >
        //       <img src={copybSvg} className="size-5" />
        //       Paste
        //     </button>
        //   )
        // }}
        className="relative !mt-0 h-[187px] rounded-[8px] bg-[#F9F9F9] px-2 py-5 text-xl font-normal leading-[1.3] text-title"
        style={{
          '--placeholder-color': 'rgba(18, 18, 18, 0.6)'
        }}
      />

      <div className={`grid w-full flex-1 grid-cols-2 items-end gap-5 pb-5`}>
        <Button
          size="large"
          block
          onClick={() => webAppReject(false)}
          theme="ghost"
        >
          Reject
        </Button>
        <Button
          size="large"
          className="w-full"
          onClick={handleConfirm}
          disabled={disabled}
          status={status}
        >
          Confirm
        </Button>
      </div>
    </div>
  )
}

function MnemonicItem({
  mnemonic,
  order,
  ...others
}: {
  mnemonic: string
  order: number
} & NativeProps) {
  return (
    <div
      {...others}
      className={classNames(
        others?.className,
        'flex  items-center gap-[6px] overflow-hidden rounded-lg bg-[#F9F9F9] py-3 pl-[10px] pr-[14px] text-xl font-normal text-title'
      )}
    >
      <span className="flex-none text-title/40">
        {order >= 10 ? order : `0${order}`}
      </span>
      <span className="truncate">{mnemonic}</span>
    </div>
  )
}

type Bip39ValidType = {
  word: string
  isValid: boolean
}
function Mnemonic({
  mnemonics,
  onTrash,
  onConfirm
}: {
  mnemonics: Array<string>
  onTrash: () => void
  onConfirm: () => void
}) {
  const wordlist = bip39.wordlists.EN
  const { walletImport, walletSwitch } = useWallet()
  const [status, setStatus] = useState<'normal' | 'loading' | 'success'>(
    'normal'
  )
  const webApp = useWebApp()
  const { webAppReject } = useApp()

  const [results, setResults] = useState<Array<Bip39ValidType>>([])

  const handleTrash = () => {
    onTrash()
  }

  // const updateSuggestions = (input) => {
  //   const words = input.trim().split(/\s+/);
  //   const lastWord = words[words.length - 1];

  //   if (lastWord.length > 0) {
  //     const matches = wordlist.filter(word => word.startsWith(lastWord));
  //     setSuggestions(matches);
  //   } else {
  //     setSuggestions([]);
  //   }
  // };

  function checkLastWord(mnemonicPhrase: string) {
    // const words = mnemonicPhrase.split(' ')
    if (!bip39.validateMnemonic(mnemonicPhrase)) {
      return false
    }

    // // Get the entropy and hash it
    // const entropy = bip39.mnemonicToEntropy(mnemonicPhrase)
    // const hash = createHash('sha256').update(entropy).digest('hex')

    // // Calculate the checksum length
    // const checksumLength = Math.ceil((words.length * 11) / 33)
    // const checksum = hash.slice(0, checksumLength)

    // // Get the last word and its index
    // const lastWord = words[words.length - 1]
    // const lastWordIndex = wordlist.indexOf(lastWord)

    // // Calculate expected checksum bits
    // const expectedChecksum = (lastWordIndex & ((1 << checksumLength) - 1))
    //   .toString(2)
    //   .padStart(checksumLength, '0')

    // return checksum === expectedChecksum
    return true
  }

  const validateMnemonic = () => {
    let verifyLast = true
    if (!checkLastWord(mnemonics.join(' '))) {
      verifyLast = false
    }
    const results = mnemonics.map((word, index) => {
      let isValid = wordlist.includes(word)
      if (index == mnemonics.length - 1 && !verifyLast) {
        isValid = verifyLast
      }
      return {
        word,
        isValid: isValid
      }
    })

    setResults(results)
  }

  const disabled = useMemo(() => {
    if (results.length == 0) return true
    else if (results.length < 12) return true
    return results.some((item: Bip39ValidType) => {
      if (!item.isValid) return true
      return false
    })
  }, [results])

  const handleConfirm = async () => {
    if (status != 'normal') return
    setStatus('loading')
    try {
      const result = await walletImport(mnemonics.join(' '))
      if (result && typeof result != 'boolean') {
        await walletSwitch(result['id'], result['ethereumAddress'])
      }
      // if (result) {
      //   setStatus('success')
      //   setTimeout(() => {
      //     onConfirm()
      //   }, 500)
      // } else {
      //   setStatus('normal')
      // }
    } catch (err) {
      setStatus('normal')
    } finally {
      setTimeout(() => {
        // webApp.enableClosingConfirmation()
        // webApp.disableClosingConfirmation()
        webApp.close()
      }, 200)
    }
  }

  useEffect(() => {
    validateMnemonic()
  }, [mnemonics])

  return (
    <div className="!mt-0 flex w-full flex-1 flex-col gap-5">
      <div className="flex min-h-[188px] w-full flex-none flex-wrap items-start justify-start gap-3">
        {results.map((item: Bip39ValidType, index: number) => {
          return (
            <MnemonicItem
              mnemonic={item.word}
              key={index}
              order={index + 1}
              className={classNames({
                'border border-[#FF0000] bg-[#EB4B6D0F]': !item.isValid
              })}
            />
          )
        })}
      </div>
      <div className="flex w-full flex-none flex-col">
        <p className="h-[18px] text-lg leading-[1.3] text-[#FF0000]">
          {disabled && results.length > 0 && <span>Mnemonic error.</span>}
        </p>
        <div className="flex items-center justify-end">
          <button
            className="flex items-center gap-2 rounded-lg border border-[#E3E3E3] bg-white px-3 py-2 text-lg font-normal leading-[1.3]"
            onClick={handleTrash}
          >
            <img src={trashSvg} className="size-5" />
            Clear
          </button>
        </div>
      </div>

      <div className={`grid w-full flex-1 grid-cols-2 items-end gap-5 pb-5`}>
        <Button
          size="large"
          block
          onClick={() => webAppReject(false)}
          theme="ghost"
        >
          Reject
        </Button>
        <Button
          size="large"
          className="w-full"
          onClick={handleConfirm}
          disabled={disabled}
          status={status}
        >
          Confirm
        </Button>
      </div>
    </div>
  )
}

function Success() {
  const webApp = useWebApp()
  useEffect(() => {
    setTimeout(() => {
      // webApp.enableClosingConfirmation()
      // webApp.disableClosingConfirmation()
      webApp.close()
    }, 1500)
  })
  return (
    <div className="mt-[177px] flex w-full flex-col items-center justify-center gap-[30px] text-xl font-medium leading-[1.3] text-title">
      <img src={logoWalletSvg} className="size-14" alt="logo" />

      <p>Importing Your Wallet</p>
    </div>
  )
}

export default function AddSeed() {
  const [mnemonicArr, setMnemonicArr] = useState<Array<string>>([])
  const [step, setStep] = useState(1)

  return (
    <Container className="flex h-full min-h-[500px] flex-col gap-5">
      <div className="mt-5 flex w-full flex-col items-center justify-center gap-[30px] text-xl font-medium leading-[1.3] text-title">
        <img src={logoWalletSvg} className="size-14" alt="logo" />
      </div>
      {step != 3 && (
        <h2 className="w-full text-left text-[20px] font-bold leading-[1.3] text-title">
          Enter seed phrase
          {/* or private key */}
        </h2>
      )}

      {step === 1 && (
        <Parse
          onParse={(seeds: Array<string>) => {
            setMnemonicArr(seeds)
            setStep(2)
          }}
        />
      )}

      {step === 2 && (
        <Mnemonic
          mnemonics={mnemonicArr}
          onTrash={() => {
            setMnemonicArr([])
            setStep(1)
          }}
          onConfirm={() => {
            setStep(3)
          }}
        />
      )}

      {step === 3 && <Success />}
    </Container>
  )
}
