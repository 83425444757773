import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../type'
import { storyTestnet } from '@wagmi/core/chains'

const icon = chainsSvgs.storyTestSvg
const networkType: IWeb3NetworkType = 'test'

const chainInfo: IWeb3ChainType = {
  chain: storyTestnet,
  id: storyTestnet.id,
  type: 'EVM',
  name: storyTestnet.name,
  icon: icon,
  networkType
}

export default chainInfo
