import { signEvmMessage, signEvmTransaction } from 'api'
import { IChainType, IWeb3ChainType } from 'proviers/web3Provider/type'
import { getChainId, sleep } from 'utils'
import {
  createPublicClient,
  Hex,
  http,
  PublicClient,
  serializeTransaction
} from 'viem'

export const attemptsSendTransaction = async ({
  maxAttempts = 5,
  chain,
  serializedTransaction
}: {
  maxAttempts?: number
  serializedTransaction: Hex
  chain: IChainType | undefined
}) => {
  let attempt = 0

  const publicClient = createPublicClient({
    chain: chain,
    transport: http()
  })

  while (attempt < maxAttempts + 1) {
    try {
      const hash = await publicClient.sendRawTransaction({
        serializedTransaction: serializedTransaction
      })

      return hash
    } catch (error) {
      console.warn(
        `Attempt ${attempt + 1} failed. Retrying in 1 second...`,
        error
      )
      await sleep(1000)
      if (attempt === maxAttempts - 1) {
        throw error
      }
      attempt++
    }
  }
}

export const sendTransaction = async ({
  chain,
  serializedTransaction
}: {
  chain: IWeb3ChainType
  serializedTransaction: Hex
}) => {
  const chainId = getChainId(chain)
  const evmChain = chain.chain

  if (typeof chainId === 'number') {
    const hash = await attemptsSendTransaction({
      chain: evmChain,
      serializedTransaction
    })

    return hash
  }
}

export const sendRawTransactionApi = async ({
  mfa,
  mfaParams,
  chain,
  walletId
}: {
  mfa: any
  mfaParams: {
    gas: string | undefined
    maxFeePerGas: string | undefined
    maxPriorityFeePerGas: string | undefined
    gasPrice: string | undefined
    value: string | undefined
    from: string | undefined
    to: string | undefined
    nonce: number | undefined
    data: string | undefined
  }
  chain: IWeb3ChainType
  walletId: number
}) => {
  const chainId = getChainId(chain)
  if (chainId) {
    const signRes = await signEvmTransaction(mfa, {
      transaction: mfaParams,
      chainId,
      rpc:
        chain.networkType == 'custom'
          ? chain.chain?.rpcUrls.default.http[0]
          : undefined,
      walletId
    })
    // console.log('signRes', chainId, signRes)
    return signRes

    // const readyHash = serializeTransaction(signRes.result)

    const rawHash = await sendTransaction({
      chain: chain,
      serializedTransaction: signRes.result
    })

    const hash = rawHash
    console.log({ rawHash })
    return hash
  }
}

export const signMessageApi = async ({
  mfa,
  signData
}: {
  mfa: any
  signData: {
    message: string
    walletId?: number
  }
}) => {
  const signRes = await signEvmMessage(mfa, signData)
  // console.log('signRes', signRes)
  return signRes
}
