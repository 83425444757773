import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../type'
import { zksync } from 'wagmi/chains'

const chain = zksync
const icon = chainsSvgs.zkSyncSvg
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: chain,
  type: 'EVM',
  name: chain.name,
  icon: icon,
  networkType
}

export default chainInfo
