import {
  allChain,
  prodChains,
  solana,
  ton,
  tonTestnet,
  sui
} from 'proviers/web3Provider/chains'
import { mockSolEvmChainId } from 'config/sol'
import { mockSuiChainId } from 'config/sui'
import { mockTonChainId, mockTonTestnetChainId } from 'config/ton'

import { IChainId } from 'state/type'
import { getChainId } from 'utils'
import { IToken } from './useTokens'
import { IWeb3ChainType } from 'proviers/web3Provider/type'
import { IS_DEV } from 'config'

export const buildAllChains = () => {
  const renderChains = IS_DEV ? [...prodChains] : [...prodChains]

  const buildChains = [
    allChain,
    ...(prodChains
      ? renderChains.filter((item) => {
          return !!item
        })
      : [])
  ]

  return buildChains
}

export const evmAllChains = () => {
  return buildAllChains()
    .filter((chain) => !!chain.chain)
    .map((chain) => chain.chain)
    .flat()
}

const useChains = () => {
  const customChains: IWeb3ChainType[] = []
  const renderChains = [...prodChains] // IS_DEV ? [...prodChains] : [...prodChains]
  const buildChains = [
    allChain,
    ...(prodChains
      ? renderChains.filter((item) => {
          return !!item
        })
      : [])
  ]
  const chainMappings = buildChains.reduce(
    (prev, next) => {
      prev[next.name] = next
      return prev
    },
    {} as Record<string, IWeb3ChainType>
  )
  const chainIds = buildChains
    .map((chain) => {
      return getChainId(chain)
    })
    .filter((item) => typeof item === 'number')
  const getChain = (chainId: number) => {
    if (chainId === mockSolEvmChainId) {
      return solana
    }
    if (chainId === mockTonChainId) {
      return ton
    }
    if (chainId === mockTonTestnetChainId) {
      return tonTestnet
    }
    if (chainId === mockSuiChainId) {
      return sui
    }
    // if (chainId === mockTronChainId) {
    //   return tron
    // }
    return buildChains.find((tomoChain) => {
      return tomoChain?.chain?.id === chainId
    })
  }
  const getChainByToken = (token: IToken) => {
    const tokenChainId = token.chain_id
    return getChain(tokenChainId)
  }
  const checkChainIdIsEVM = (chainId: IChainId) => {

    return true
  }
  return {
    evmChains: buildChains
      .filter((chain) => !!chain.chain)
      .map((chain) => chain.chain)
      .flat(),
    chains: buildChains,
    // swapChains,
    chainIds,
    chainMappings,
    // devChains: devChains.filter((item) => {
    //   return !!item
    // }),
    prodChains: buildChains,
    customChains,
    getChainId,
    getChain,
    // getChainIdByName,
    // getSwapChainId,
    // getChainNameById,
    getChainByToken,
    checkChainIdIsEVM
    // currentChain,
    // setCurrentChain
  }
}

export default useChains
