import React, { PropsWithChildren } from 'react'
import { WagmiProvider } from 'wagmi'
import useConfig from 'hooks/useConfig'

const Provider = ({ children }: PropsWithChildren) => {
  const { config } = useConfig()

  return <WagmiProvider config={config}>{children}</WagmiProvider>
}

export default Provider
