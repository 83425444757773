import VerifyModal from './components/pinpay/VerifyModal'
import { BackButton } from '@vkruglikov/react-telegram-web-app'
import { useLocation, useNavigate } from 'react-router-dom'
import { pageSettingAtom } from '@/state'
import { useAtomValue } from 'jotai'
import {
  NetworkFeeDetail,
  NetworkFeeProps
} from './components/SignTransaction/NetworkFee'

const NetworkFeePage = () => {
  const pageSetting = useAtomValue(pageSettingAtom)
  const navigate = useNavigate()
  const location = useLocation()
  const networkFee = (location?.state as NetworkFeeProps) || {}
  const onBack = () => {
    pageSetting.navBar?.onBack ? pageSetting.navBar?.onBack() : navigate(-1)
  }

  return (
    <>
      <BackButton onClick={onBack}></BackButton>
      <NetworkFeeDetail {...networkFee}></NetworkFeeDetail>
      <VerifyModal />
    </>
  )
}

export default NetworkFeePage
