import { useState, useEffect, useCallback } from 'react';

const useCountdown = (key: string, groupKey: string, initialTime: number=60) => {
  const diffTimeLeft = (key:string) => {
    const now = Date.now();
    const savedData = localStorage.getItem(key);
    if (savedData) {
      const { startTime, duration } = JSON.parse(savedData);
      const elapsed = Math.floor((now - startTime) / 1000);
      const remainingTime = Math.max(duration - elapsed, 0);
      return remainingTime;
    }
    return 0;
  }
  const resetExpireStorage = (addKey?: string) => {
    const groupData = localStorage.getItem(groupKey);
    const groups = groupData ? JSON.parse(groupData) : [];
    const groupSet = new Set<string>(Array.from(groups));
    for(const key1 of groupSet){
      if(!diffTimeLeft(key1)){
        groupSet.delete(key1);
        localStorage.removeItem(key1);
      }
    }
    if(addKey) groupSet.add(addKey)
    localStorage.setItem(groupKey, JSON.stringify(Array.from(groupSet)))
  }

  const [timeLeft, setTimeLeft] = useState(() => diffTimeLeft(key));

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (timeLeft <= 0) {
      localStorage.removeItem(key);
      resetExpireStorage()
      setIsActive(false);
      return;
    }

    if (isActive) {
      const interval = setInterval(() => {
        setTimeLeft(prevTime => {
          const newTime = prevTime - 1;
          if (newTime <= 0) {
            clearInterval(interval);
          }
          return newTime;
        });
      }, 1000);

      const now = Date.now();
      localStorage.setItem(key, JSON.stringify({
        startTime: now - (initialTime - timeLeft) * 1000,
        duration: initialTime
      }));

      return () => clearInterval(interval);
    }

  }, [key, timeLeft, isActive, initialTime]);

  const startCountdown = useCallback(() => {
    if (!isActive) {
      setIsActive(true);
    }
  }, [isActive]);

  const stopCountdown = useCallback(() => {
    setIsActive(false);
  }, []);

  const resetCountdown = useCallback(() => {
    const now = Date.now();
    setTimeLeft(initialTime);
    localStorage.setItem(key, JSON.stringify({
      startTime: now,
      duration: initialTime
    }));
    resetExpireStorage(key)
    setIsActive(true);
  }, [initialTime, key]);

  return {
    timeLeft,
    startCountdown,
    stopCountdown,
    resetCountdown,
    diffTimeLeft
  };
};

export default useCountdown
