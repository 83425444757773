import { toast as rToast } from 'react-toastify'
import { toastSvgs } from '../assets'
export const defaultToastOptions: { [key: string]: any } = {
  position: 'top-right',
  hideProgressBar: true,
  autoClose: 3000,
  pauseOnHover: false,
  className: 'theme-toast i_toast'
}
const toast = (
  content: string,
  options?: {
    [key: string]: any
  }
) => {
  return rToast(content, {
    ...defaultToastOptions,
    ...options
  })
}

toast.success = (content: string, options?: { [key: string]: any }) => {
  return rToast.success(content, {
    icon: <img src={toastSvgs.toastSuccessSvg} className={'size-[28px]'} />,
    ...defaultToastOptions,
    ...options
  })
}

toast.error = (content: string, options?: { [key: string]: any }) => {
  return rToast.error(content, {
    icon: <img src={toastSvgs.toastErrorSvg} className={'size-[28px]'} />,
    ...defaultToastOptions,
    ...options
  })
}

toast.info = (content: string, options?: { [key: string]: any }) => {
  return rToast.info(content, {
    icon: <img src={toastSvgs.toastInfoSvg} className={'size-[28px]'} />,
    ...defaultToastOptions,
    ...options
  })
}

toast.warn = (content: string, options?: { [key: string]: any }) => {
  return rToast.warn(content, {
    icon: <img src={toastSvgs.toastWarningSvg} className={'size-[28px]'} />,
    ...defaultToastOptions,
    ...options
  })
}

toast.loading = (content: string, options?: { [key: string]: any }) => {
  return rToast.loading(content, {
    // icon: <img src={toastSvgs.toastWarningSvg} className={'size-[28px]'} />,
    ...defaultToastOptions,
    ...options
  })
}

export default toast
