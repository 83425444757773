import * as Sentry from '@sentry/react'
import { SeverityLevel } from './const'

/**
 * Enum representing different business types.
 */
export const enum Business {
  TRADE = 'Trade',
  AUTH = 'Auth'
}
interface Extra {
  [key: string]: unknown
}
export interface SentryTags {
  [key: string]: string | number
}
interface Context {
  level?: Sentry.SeverityLevel
  extra?: Extra
  tags?: SentryTags
}

/**
 * Capture a trade related exception with Sentry.
 *
 * @param message - The exception message, either a string or an Error instance.
 * @param extra - Additional information to attach to the Sentry event.
 * @param context - Additional context to apply to the Sentry event.
 */
export const sentryCaptureTradeException = (
  message: string | Error,
  extra?: Extra,
  context?: Context
) => {
  sentryCaptureBusinessException(Business.TRADE, message, extra, context)
}
export const sentryCaptureAuthException = (
  message: string | Error,
  /**
   * Capture an auth related exception with Sentry.
   *
   * @param message - The exception message, either a string or an Error instance.
   * @param extra - Additional information to attach to the Sentry event.
   * @param context - Additional context to apply to the Sentry event.
   */
  extra?: Extra,
  context?: Context
) => {
  sentryCaptureBusinessException(Business.AUTH, message, extra, context)
}
export const sentryCaptureBusinessException = (
  business: Business,
  message: string | Error,
  extra?: Extra,
  context?: Context
) => {
  sentryCaptureException(message, {
    ...context,
    extra,
    tags: {
      business,
      ...(context?.tags || {})
    }
  })
}

/**
 * Capture an exception with Sentry.
 * @param message The error message to report.
 * @param context Additional context to report with the exception.
 * @param context.level The severity level of the exception.
 * @param context.extra Additional information to report with the exception.
 * @param context.tags Additional tags to report with the exception.
 */
export const sentryCaptureException = (
  message: string | Error,
  context: {
    level?: Sentry.SeverityLevel
    extra?: Extra
    tags?: {
      [key: string]: string | number
    }
  } = {}
) => {
  try {
    console.log('sentry captureException', message, context)
    const err = message instanceof Error ? message : new Error(message)
    Sentry.captureException(err, {
      ...context,
      level: context?.level || SeverityLevel.ERROR,
      extra: format(context?.extra),
      tags: {
        business: 'Unknown',
        ...(context?.tags || {})
      }
    })
  } catch (error) {
    console.error('sentry captureException', error)
  }
}

/**
 * Format the `extra` object by excluding any keys with values of `null`,
 * `undefined`, or an empty string.
 * @param extra The object to format.
 * @returns The formatted object.
 */
export const format = (extra: Extra = {}): Extra => {
  if (!extra) return {}
  const newExtra: Extra = {}
  for (const key in extra) {
    if (extra[key] !== null && extra[key] !== undefined && extra[key] !== '') {
      if (typeof extra[key] === 'object') {
        newExtra[key] = JSON.stringify(extra[key])
      } else {
        newExtra[key] = extra[key]
      }
    }
  }
  return newExtra
}
