import logoSvg from '@/assets/imgs/logo.svg'
import tgSvg from '@/assets/imgs/tg.svg'
import ethSvg from '@/assets/imgs/chains/eth.svg'
import btcSvg from '@/assets/imgs/chains/btc.svg'
import solSvg from '@/assets/imgs/chains/sol.svg'
import bscSvg from '@/assets/imgs/chains/bsc.svg'
import arbSvg from '@/assets/imgs/chains/arb.svg'
import opSvg from '@/assets/imgs/chains/op.svg'
import baseSvg from '@/assets/imgs/chains/base.svg'
import lineaSvg from '@/assets/imgs/chains/linea.svg'
import blastSvg from '@/assets/imgs/chains/blast.svg'
import polygonSvg from '@/assets/imgs/chains/polygon.svg'
import avaxSvg from '@/assets/imgs/chains/avax.svg'
import mantleSvg from '@/assets/imgs/chains/mantle.svg'
import scrollSvg from '@/assets/imgs/chains/scroll.svg'
import b3Jpg from '@/assets/imgs/chains/b3.jpg'
import b3Svg from '@/assets/imgs/chains/b3.svg'
import allChainSvg from '@/assets/imgs/chains/all-chain.svg'
import zkSyncSvg from '@/assets/imgs/chains/zksync.svg'
import duckChainSvg from '@/assets/imgs/chains/duck-chain.svg'
import neoXSvg from '@/assets/imgs/chains/neox.svg'

import UpSvg from '@/assets/imgs/up.svg'
import DownSvg from '@/assets/imgs/down.svg'
import copySvg from '@/assets/imgs/copy.svg'
import copy2Svg from '@/assets/imgs/copy2.svg'
import shareSvg from '@/assets/imgs/share.svg'
import roundUpSvg from '@/assets/imgs/round-up.svg'
import roundDownSvg from '@/assets/imgs/round-down.svg'
import usdtSvg from '@/assets/imgs/tokens/usdt.svg'
import bnbSvg from '@/assets/imgs/tokens/bnb.svg'
import tgBlueSvg from '@/assets/imgs/socialMedia/tg-blue.svg'
import tgWhiteSvg from '@/assets/imgs/socialMedia/tg-white.svg'
import orbiterSvg from '@/assets/imgs/socialMedia/orbiter.svg'
import xSvg from '@/assets/imgs/socialMedia/x.svg'
import tiktokSvg from '@/assets/imgs/socialMedia/tiktok.svg'
import instagramSvg from '@/assets/imgs/socialMedia/instagram.svg'
import rangoSvg from '@/assets/imgs/socialMedia/rango.svg'
import mintSvg from '@/assets/imgs/mint.svg'
import editSvg from '@/assets/imgs/edit.svg'
import loginBg1Svg from '@/assets/imgs/login-bg-1.svg'
import loginBg2Svg from '@/assets/imgs/login-bg-2.svg'
import receiveSvg from '@/assets/imgs/receive.svg'
import sendSvg from '@/assets/imgs/send.svg'
import historySvg from '@/assets/imgs/history.svg'
import recoveryPhraseSvg from '@/assets/imgs/recovery-phrase.svg'
import toastSuccessSvg from '@/assets/imgs/toast/success.svg'
import toastErrorSvg from '@/assets/imgs/toast/error.svg'
import toastInfoSvg from '@/assets/imgs/toast/info.svg'
import toastWarningSvg from '@/assets/imgs/toast/warning.svg'
import rightSvg from '@/assets/imgs/right.svg'
import accountNameSvg from '@/assets/imgs/account-name.svg'
import accountAddressSvg from '@/assets/imgs/account-address.svg'
import accountPhraseSvg from '@/assets/imgs/account-phrase.svg'
import accountKeySvg from '@/assets/imgs/account-key.svg'
import accountRemoveSvg from '@/assets/imgs/account-remove.svg'
import accountRemoveEnableSvg from '@/assets/imgs/account-remove-enable.svg'
import qrCodeSvg from '@/assets/imgs/qr-code.svg'
import qrCode2Svg from '@/assets/imgs/qr-code2.svg'
import accountEditSvg from '@/assets/imgs/account-edit.svg'
import warnSvg from '@/assets/imgs/warn.svg'
import warningSvg from '@/assets/imgs/warning.svg'
import noticeSvg from '@/assets/imgs/notice.svg'
import tgWhiteRoundedSvg from '@/assets/imgs/tg-white-rounded.svg'
import swapSvg from '@/assets/imgs/swap.svg'
import selectEmptySvg from '@/assets/imgs/select-empty.svg'
import selectedSvg from '@/assets/imgs/selected.svg'
import infoSvg from '@/assets/imgs/info.svg'
import infoGraySvg from '@/assets/imgs/info-gray.svg'
import rankSvg from '@/assets/imgs/rank.svg'
import copyThemeSvg from '@/assets/imgs/copy-theme.svg'
import linkThemeSvg from '@/assets/imgs/link-theme.svg'
import linkSvg from '@/assets/imgs/link.svg'
import avatarPng from '@/assets/imgs/avatar.png'
import tgWhiteLineSvg from '@/assets/imgs/tg-white.svg'
import CloseRoundSvg from '@/assets/imgs/close-round.svg'
import roundSvg from '@/assets/imgs/round.svg'
import roundCheckedSvg from '@/assets/imgs/round-checked.svg'
import emptySvg from '@/assets/imgs/empty.svg'
import okxPng from '@/assets/imgs/okx.png'

import depositHomeSvg from '@/assets/imgs/deposit-home.svg'
import receiveHomeSvg from '@/assets/imgs/receive-home.svg'
import swapHomeSvg from '@/assets/imgs/swap-home.svg'
import tokenSwapSvg from '@/assets/imgs/token-swap.svg'

import arbJpeg from '@/assets/imgs/chains/arb.jpeg'
import stakeSvg from '@/assets/imgs/stake.svg'
import babylonSvg from '@/assets/imgs/icon-babylon.svg'
import lombardSvg from '@/assets/imgs/icon-lombard.svg'
import lorenzoSvg from '@/assets/imgs/icon-lorenzo.svg'
import backSvg from '@/assets/imgs/icon-back.svg'
import btcDownSvg from '@/assets/imgs/btc-down.svg'
import simpleCopySvg from '@/assets/imgs/icon-copy.svg'
import checkPassSvg from '@/assets/imgs/check-pass.svg'
import tomoBlackSvg from '@/assets/imgs/icon-tomo-blank.svg'
import buySvg from '@/assets/imgs/buy.svg'
import roundedPlusSvg from '@/assets/imgs/rounded-plus.svg'
import arrowRightSvg from '@/assets/imgs/arrow-right.svg'
import arrowRightNewSvg from '@/assets/imgs/arrow-right-new.svg'
import arrowRightRoundSvg from '@/assets/imgs/arrow-right-round.svg'
import arrowDownSvg from '@/assets/imgs/arrow-down.svg'
import tonSvg from '@/assets/imgs/chains/ton.png'
import rewardTokensSvg from '@/assets/imgs/task/reward-tokens.svg'
import share2Svg from '@/assets/imgs/share2.svg'
import share2ThemeSvg from '@/assets/imgs/share2-theme.svg'
import tokenSwapOutlinedSvg from '@/assets/imgs/task/token-swap-outlined.svg'
import RightBlackSvg from '@/assets/imgs/right-black.svg'
import swapLoadingSvg from '@/assets/imgs/swap-loading.svg'
import swapSuccessSvg from '@/assets/imgs/swap-success.svg'
import swapLineSvg from '@/assets/imgs/swap-line.svg'
import swapRightSvg from '@/assets/imgs/swap-right.svg'
import swapHistoryLoadingSvg from '@/assets/imgs/swap-history-loading.svg'
import swapHistoryItemSvg from '@/assets/imgs/swap-history-item.svg'
import swapWhiteSvg from '@/assets/imgs/swap-white.svg'
import popupCloseSvg from '@/assets/imgs/popup-close.svg'
import checkboxSvg from '@/assets/imgs/checkbox.svg'
import checkboxActiveSvg from '@/assets/imgs/checkbox-active.svg'
import visaSvg from '@/assets/imgs/visa.svg'
import mastercardSvg from '@/assets/imgs/mastercard.svg'
import backupSvg from '@/assets/imgs/backup.svg'
import resetSvg from '@/assets/imgs/reset.svg'
import secretSvg from '@/assets/imgs/secret.svg'
import tipsSvg from '@/assets/imgs/tips.svg'
import exchangeSvg from '@/assets/imgs/exchange.svg'
import telegramSvg from '@/assets/imgs/telegram.png'
import telegram2Svg from '@/assets/imgs/telegram2.svg'
import weiboPng from '@/assets/imgs/weibo.png'
import whyPng from '@/assets/imgs/tokens/why.png'
import wifPng from '@/assets/imgs/tokens/wif.png'
import copy2ThemeSvg from '@/assets/imgs/copy2-theme.svg'
import mailSvg from '@/assets/imgs/mail.svg'
import backupMailSvg from '@/assets/imgs/backup_mail.svg'
import qrcodeTwoSvg from '@/assets/imgs/qrcode_two.svg'
import copyBlackSvg from '@/assets/imgs/copy_black.svg'
import timeSvg from '@/assets/imgs/time.svg'
import gasSvg from '@/assets/imgs/gas.svg'
import confirmArrowSvg from '@/assets/imgs/confirm-arrow.svg'
import roundCheckedWhiteSvg from '@/assets/imgs/round-checked-white.svg'
import marketInfoSvg from '@/assets/imgs/market/info.svg'
import marketCheckedSvg from '@/assets/imgs/market/checked.svg'
import marketLogoInvitePng from '@/assets/imgs/market/logoInvite.png'
import downloadSvg from '@/assets/imgs/download.svg'
import securitySvg from '@/assets/imgs/security.svg'
import successRoundSvg from '@/assets/imgs/success-round.svg'
import accountAddress2Svg from '@/assets/imgs/account-address2.svg'
import keySvg from '@/assets/imgs/key.svg'
import sliveryXSvg from '@/assets/imgs/slivery-x.svg'
import checkedThemeSvg from '@/assets/imgs/checked-theme.svg'
import securityKeySvg from '@/assets/imgs/security-key.svg'
import back1Svg from '@/assets/imgs/back-1.svg'
import spinerSvg from '@/assets/imgs/spiner.svg'
import eyeSvg from '@/assets/imgs/eye.svg'
import eyeCloseSvg from '@/assets/imgs/eye-close.svg'
import giftSvg from '@/assets/imgs/gift.svg'
import referSvg from '@/assets/imgs/refer.svg'
import lombardLogoSvg from '@/assets/imgs/task/lombard.svg'
import stakestoneLogoSvg from '@/assets/imgs/task/stakestone.svg'
import babylonLogoSvg from '@/assets/imgs/task/babylon.svg'
import qrCode3Svg from '@/assets/imgs/qr-code3.svg'
import ellipsisRoundSvg from '@/assets/imgs/ellipsis-round.svg'
import settingSvg from '@/assets/imgs/setting.svg'
import externalWalletSvg from '@/assets/imgs/external-wallet.svg'
import scanSvg from '@/assets/imgs/scan.svg'
import tomoTransactionSentSvg from '@/assets/imgs/tomo-transaction-sent.svg'
import bnbMaticSvg from '@/assets/imgs/bnb-matic.svg'
import payPinSvg from '@/assets/imgs/pay_pin.svg'
import spiner2Svg from '@/assets/imgs/spiner2.svg'
const tronChainSvg = '/assets/imgs/chains/tron.svg'
const errorBoundarySvg = '/assets/imgs/error-boundary.svg'
const logoOutlinedSvg = '/assets/imgs/task/logo-outlined.svg'
const loginTomoLogoSvg = '/assets/imgs/login-tomo-logo.svg'
const referLogoSvg = '/assets/imgs/refer-logo.svg'
import tradeSvg from '@/assets/imgs/task/trade.svg'
import oauthLogoSvg from '@/assets/imgs/oauth-logo.svg'
import copybSvg from '@/assets/imgs/copy_b.svg'
import trashSvg from '@/assets/imgs/trash.svg'
import logoWalletSvg from '@/assets/imgs/logo-wallet.svg'
import connectSvg from '@/assets/imgs/connect.svg'
import logoBackupPng from '@/assets/imgs/logo-backup.png'
import reload from '@/assets/imgs/reload.svg'
import refresh from '@/assets/imgs/refresh.svg'
import merlin from '@/assets/imgs/chains/merlin.svg'
import aiLayer from '@/assets/imgs/chains/aiLayer.svg'
import bitlayer from '@/assets/imgs/chains/bitlayer.svg'
import storyTestSvg from '@/assets/imgs/chains/story.svg'
import berachainTestJpg from '@/assets/imgs/chains/berachain.jpg'
import botanixTestnetJpg from '@/assets/imgs/chains/botanix-testnet.jpg'
import suiSvg from '@/assets/imgs/chains/sui.svg'
import worldChainPng from '@/assets/imgs/chains/world-chain.png'
import gravityJpg from '@/assets/imgs/chains/gravity.jpg'
import opbnbSvg from '@/assets/imgs/chains/opbnb.svg'

const chainsSvgs = {
  ethSvg,
  allChainSvg,
  btcSvg,
  solSvg,
  arbSvg,
  arbJpeg,
  opSvg,
  bscSvg,
  lineaSvg,
  blastSvg,
  polygonSvg,
  avaxSvg,
  mantleSvg,
  baseSvg,
  scrollSvg,
  b3Jpg,
  b3Svg,
  tronChainSvg,
  tonSvg,
  zkSyncSvg,
  duckChainSvg,
  neoXSvg,
  merlin,
  aiLayer,
  bitlayer,
  storyTestSvg,
  berachainTestJpg,
  botanixTestnetJpg,
  suiSvg,
  worldChainPng,
  gravityJpg,
  opbnbSvg
}
const tokensSvgs = {
  usdtSvg,
  whyPng,
  wifPng,
  bnbSvg
}
const socialMediaSvgs = {
  tgBlueSvg,
  tgWhiteSvg,
  orbiterSvg,
  rangoSvg,
  okxPng,
  xSvg,
  tiktokSvg,
  instagramSvg
}
const toastSvgs = {
  toastSuccessSvg,
  toastErrorSvg,
  toastInfoSvg,
  toastWarningSvg
}
const arrowSvgs = {
  rightNewSvg: arrowRightSvg,
  rightNewSvgV1: arrowRightNewSvg,
  rightSvg,
  downSvg: DownSvg,
  leftSvg: popupCloseSvg,
  rightV2Svg: RightBlackSvg
}
export {
  gasSvg,
  timeSvg,
  popupCloseSvg,
  swapHistoryItemSvg,
  swapWhiteSvg,
  swapHistoryLoadingSvg,
  swapRightSvg,
  swapLineSvg,
  swapLoadingSvg,
  swapSuccessSvg,
  swapHomeSvg,
  depositHomeSvg,
  receiveHomeSvg,
  stakeSvg,
  babylonSvg,
  lombardSvg,
  lorenzoSvg,
  backSvg,
  btcDownSvg,
  simpleCopySvg,
  checkPassSvg,
  tomoBlackSvg,
  loginTomoLogoSvg,
  swapSvg,
  selectEmptySvg,
  selectedSvg,
  warnSvg,
  warningSvg,
  accountRemoveSvg,
  qrCodeSvg,
  qrCode2Svg,
  accountRemoveEnableSvg,
  accountNameSvg,
  accountPhraseSvg,
  accountKeySvg,
  accountAddressSvg,
  arrowSvgs,
  arrowDownSvg,
  tgWhiteSvg,
  recoveryPhraseSvg,
  historySvg,
  loginBg2Svg,
  loginBg1Svg,
  socialMediaSvgs,
  logoSvg,
  tgSvg,
  chainsSvgs,
  tokensSvgs,
  UpSvg,
  DownSvg,
  copySvg,
  copy2Svg,
  shareSvg,
  roundUpSvg,
  roundDownSvg,
  mintSvg,
  editSvg,
  sendSvg,
  receiveSvg,
  toastSvgs,
  accountEditSvg,
  noticeSvg,
  tgWhiteRoundedSvg,
  infoSvg,
  infoGraySvg,
  rankSvg,
  copyThemeSvg,
  linkThemeSvg,
  linkSvg,
  avatarPng,
  tgWhiteLineSvg,
  CloseRoundSvg,
  roundSvg,
  roundCheckedSvg,
  emptySvg,
  tokenSwapSvg,
  okxPng,
  buySvg,
  referLogoSvg,
  roundedPlusSvg,
  backupSvg,
  resetSvg,
  secretSvg,
  tipsSvg,
  exchangeSvg,
  telegramSvg,
  telegram2Svg,
  weiboPng,
  rewardTokensSvg,
  share2Svg,
  share2ThemeSvg,
  logoOutlinedSvg,
  tokenSwapOutlinedSvg,
  RightBlackSvg,
  checkboxSvg,
  checkboxActiveSvg,
  visaSvg,
  mastercardSvg,
  copy2ThemeSvg,
  mailSvg,
  backupMailSvg,
  qrcodeTwoSvg,
  copyBlackSvg,
  arrowRightRoundSvg,
  confirmArrowSvg,
  roundCheckedWhiteSvg,
  marketInfoSvg,
  marketCheckedSvg,
  marketLogoInvitePng,
  downloadSvg,
  securitySvg,
  successRoundSvg,
  accountAddress2Svg,
  keySvg,
  sliveryXSvg,
  checkedThemeSvg,
  securityKeySvg,
  back1Svg,
  spinerSvg,
  eyeSvg,
  eyeCloseSvg,
  giftSvg,
  referSvg,
  errorBoundarySvg,
  lombardLogoSvg,
  stakestoneLogoSvg,
  qrCode3Svg,
  ellipsisRoundSvg,
  settingSvg,
  babylonLogoSvg,
  externalWalletSvg,
  scanSvg,
  tomoTransactionSentSvg,
  bnbMaticSvg,
  payPinSvg,
  spiner2Svg,
  tradeSvg,
  oauthLogoSvg,
  copybSvg,
  trashSvg,
  logoWalletSvg,
  connectSvg,
  logoBackupPng,
  reload,
  refresh
}
