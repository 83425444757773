import { useAtom, useAtomValue } from 'jotai'
import React from 'react'
// import { clientMapAtom, IHistoryType } from 'state'
import useConfig from './useConfig'
import { prepareTransactionRequest } from '@wagmi/core'
import {
  useEstimateGas,
  useEstimateFeesPerGas,
  useEstimateMaxPriorityFeePerGas,
  useGasPrice,
  useAccount
} from 'wagmi'
import {
  createPublicClient,
  encodeDeployData,
  encodeFunctionData,
  formatUnits,
  http,
  parseGwei,
  parseUnits,
  rpcSchema,
  Transaction
} from 'viem'
import toast from 'components/Toast'
import { sepolia } from 'viem/chains'
import { useMfa } from './useMfa'
import {
  btcSignPsbtAndPush,
  signEvmTransaction,
  solSignMessageWithMFA,
  solSignRawTransaction,
  solSignRawTransactionWithMFA,
  tonSignMessage,
  tronSignRawTransaction,
  v1AddAssetApi,
  suiSignTransaction,
  suiSignMessage
} from 'api'
import useLoginInfo from './useLoginInfo'
import {
  getConnection,
  getSendSplToken,
  getSolTokenTx,
  mockSolEvmChainId,
  sendSolTx
} from 'config/sol'
import { getChainId, setPassKey } from 'utils'
import { Transaction as TransactionSol } from '@solana/web3.js'
import { BTCNetworkAddressType, BTCNetworkType } from 'api/type'
// import { mockBtcEvmChainId, sendTx } from 'config/btc'
import { useQuery } from '@tanstack/react-query'
import useChains from './useChains'
import { IChainId, Web3Type } from 'proviers/web3Provider/type'
import { IToken } from './useTokens'
import {
  sendTransaction as getTransactionHex,
  sendRawTransactionApi,
  signMessageApi
} from 'config/evm'
// import {
//   mockTronChainId,
//   sendRawTransaction,
//   sendTransaction
// } from 'config/tron'
// import useTransactionHistory from './useTransactionHistory'
import { waitForTransactionSuccess } from 'utils/wallet'
import {
  createSigningTransaction,
  createSigningTransactionPure,
  mockTonChainId,
  mockTonTestnetChainId,
  sendMessageFee,
  sendTransaction,
  TonSigningTransactionType
} from '@/config/ton'
import {
  createSigningTransaction as createSigningTonTestnetTransaction,
  createSigningTransactionPure as createSigningTonTestnetTransactionPure,
  TonSigningTransactionType as TonSigningTonTestnetTransactionType
} from '@/config/tonTestnet'
import { ITransactionToken, ITransactionType } from '@/state/type'
import { constructEstGasParams } from 'utils/helper'
import { TonTxParams, TonTxRequestStandard } from '@tomo-inc/tomo-telegram-sdk'
import { Transaction as SuiTransaction } from '@mysten/sui/transactions'
import {
  getSendSuiCoinTx,
  getSuiClient,
  SendSuiTransactionParams
} from '@/config/sui'
import { sui } from '@/proviers/web3Provider/chains'

// export const useReadyBtcTransaction = (params: {
//   network: BTCNetworkType
//   addressType: BTCNetworkAddressType | undefined
//   toAddress: string
//   amount: string
// }) => {
//   const { btcWallet } = useLoginInfo()
//   const readyBtcTransaction = useQuery({
//     queryKey: [
//       'readyBtcTransaction',
//       params.addressType,
//       params.amount,
//       params.network,
//       params.toAddress,
//       btcWallet
//     ],
//     queryFn: async () => {
//       const txStr = await sendTx({
//         network: params.network, // my Address
//         addressType: params.addressType, // toAddress
//         toAddress: params.toAddress, //value
//         amount: params.amount // contract Address  // token address or undefined
//       })
//       return txStr
//     }
//   })
//   return readyBtcTransaction
// }

export type CustomRpcSchema = [
  {
    Method: 'eth_sendRawTransaction'
    Parameters: [string]
    ReturnType: string
  }
]
const useSendTransaction = ({ chainId }: { chainId?: number | undefined }) => {
  // const { evmWallet } = useAtomValue(clientMapAtom)
  // const { transactions, setTransactionHistory } = useTransactionHistory()
  const { config } = useConfig()
  const { getMfaParams } = useMfa()
  const { chains, getChainByToken, checkChainIdIsEVM, getChain } = useChains()

  // const connect = getConnection()
  const selectChain = chains.find((chain: any) => {
    switch (chain.type) {
      case Web3Type.EVM:
        return chain.chain?.id == chainId
      // case Web3Type.BTC:
      //   return chainId === mockBtcEvmChainId
      case Web3Type.SOL:
        return chainId === mockSolEvmChainId
      // case Web3Type.TON:
      //   return chainId === mockTonChainId
      default:
        return undefined
    }
  })

  const gasPrice = useGasPrice({ chainId: chainId })
  const {
    data: EstimateFees,
    isError,
    isLoading
  } = useEstimateFeesPerGas({ chainId })

  const sendEVMTransaction = async (params: {
    chainId: number | undefined
    fromAddress: string | undefined
    toAddress: string | undefined
    value: bigint | undefined
    data?: string | undefined
    tokenValue?: bigint | undefined
    token?: IToken | undefined
    walletId?: number
    gasLimit?: string
    gasPrice?: string
  }) => {
    const { tokenValue, token } = params
    try {
      console.log('sendEVMTransaction', params)
      const data = params.data || '0x'
      const chainId = params.chainId as IChainId
      let res
      try {
        res = await prepareTransactionRequest(
          config,
          constructEstGasParams({
            // chainId: params.chainId || undefined,
            chainId: chainId,
            account: params.fromAddress as any,
            to: params.toAddress as any,
            value: params.value || 0n,
            data: data as any
          })
        )
      } catch (e) {
        console.log('prepareTransactionRequest error:', e)
      }
      // console.log({ res })

      const mfaParams: any = {
        gas: res?.gas.toString() ?? '21000',
        maxFeePerGas:
          res?.maxFeePerGas?.toString() ??
          EstimateFees?.maxFeePerGas.toString(),
        maxPriorityFeePerGas:
          res?.maxPriorityFeePerGas?.toString() ??
          EstimateFees?.maxPriorityFeePerGas.toString(),
        gasPrice: ((gasPrice?.data || 0n) * 2n)?.toString(),
        value: params.value?.toString() ?? res?.value?.toString(),
        from: params.fromAddress as string,
        to: params.toAddress as string,
        nonce: res?.nonce ?? Math.floor(Date.now() / 1000),
        data: data
      }

      if (params.gasLimit) {
        console.log('using custom gas limit')
        mfaParams.gasLimit = params.gasLimit
        mfaParams.gas = params.gasLimit // todo, may only apply to scroll
      }

      if (params.gasPrice) {
        console.log('using custom gas price')
        mfaParams.gasPrice = params.gasPrice
      }

      if (
        typeof mfaParams?.maxFeePerGas &&
        typeof mfaParams?.maxPriorityFeePerGas &&
        mfaParams?.maxFeePerGas >= 0n &&
        mfaParams?.maxPriorityFeePerGas >= 0n
      ) {
        delete mfaParams.gasPrice
      }

      console.log('mfaParams', mfaParams)
      if (!chainId) throw new Error('Chain id error.')
      const mfaRes = await getMfaParams({
        content: mfaParams,
        chainid: chainId
      })
      console.log({ mfaRes })
      if (mfaRes) {
        const { mfa } = mfaRes

        if (!mfa) {
          throw new Error('Mfa error.')
          return
          // sendTranSactionApi({
          //   url: sepolia.rpcUrls.default.http[0],
          //   raw: raw
          // })
        }
        console.log({
          mfa
        })
        setPassKey(mfa)
        // return addTx(
        //   chainId,
        //   '0x74e0ca966035bd65da917fe8b3181e7deae861cb58777618caa07d73c08c7bbb'
        // )
        // const rpc = selectChain?.chain?.rpcUrls.default.http[0]
        // if (!rpc) {
        //   throw new Error('Rpc error.')
        //   return
        // }

        return await sendRawTransactionApi({
          mfa,
          mfaParams,
          chain: selectChain,
          walletId: params.walletId
        })
        // const hash = await sendRawTransactionApi({
        //   mfa,
        //   mfaParams,
        //   chain: selectChain
        // })

        // if (!hash) {
        //   return
        // }

        // const check = await waitForTransactionSuccess({
        //   txHash: hash,
        //   chain: selectChain?.chain
        // })

        // if (!check) {
        // }

        // if (hash) {
        // const chain = chains.find(
        //   (chain) => chain.chain?.id === params.chainId
        // )
        // const historySave: IHistoryType = {
        //   fromAddress: params?.fromAddress,
        //   toAddress: params?.toAddress,
        //   fromAmount: params?.tokenValue?.toString(),
        //   toAmount: params?.tokenValue?.toString() || '0',
        //   fromSwapTokens: {
        //     token: {
        //       ...params.token,
        //       balance: params.token?.balance?.toString() || '0',
        //       balanceItem: undefined
        //     },
        //     chain: chains.find((chain) => chain.chain?.id === chainId),
        //     balance: undefined
        //   },
        //   toSwapTokens: {
        //     token: {
        //       ...params.token,
        //       balance: params.token?.balance?.toString() || '0',
        //       balanceItem: undefined
        //     },
        //     chain: chains.find((chain) => chain.chain?.id === chainId),
        //     balance: undefined
        //   },
        //   nonce: res.nonce,
        //   time: new Date().getTime(),
        //   hash: hash,
        //   historyType: 'Send',
        //   chain: chain,
        //   status: 'pending'
        // }

        // if (typeof chainId === 'number') {
        //   setTransactionHistory({
        //     history: historySave,
        //     chainId
        //   })
        // }
        //   return hash
        // }
      }
      // } else {
      //   console.error('prepareTransactionRequest')
      // }
    } catch (error: any) {
      console.warn({
        error
      })
      if (error.name === 'EstimateGasExecutionError') {
        toast.error(error.details)
      } else {
        toast.error(error?.message || error.details || error)
      }
    }
  }

  const sendSolTransaction = async (params: {
    fromAddress: string | undefined
    toAddress: string
    value: bigint | undefined //1e9   decimal: 9
    contract?: string
    token?: IToken | undefined
  }) => {
    try {
      if (params.value && params.fromAddress) {
        let txStr
        if (!params.contract) {
          txStr = await sendSolTx(
            params.fromAddress, // my Address
            params.toAddress, // toAddress
            params.value || 0n, //value
            // signData.txMeta.mintAddress // contract Address
            params.contract
          )
        } else {
          txStr = await getSolTokenTx(
            params.fromAddress,
            params.toAddress,
            Number(params.value),
            params.contract
          )
        }

        const mfaRes = await getMfaParams({
          content: txStr,
          chainid: chainId
        })

        if (mfaRes.mfa) {
          setPassKey(mfaRes.mfa)

          const signRes = await solSignRawTransactionWithMFA({
            data: { rawTransaction: txStr ?? '' },
            mfa: mfaRes.mfa
          })

          return signRes
        }
      }
    } catch (error) {
      console.error({
        error
      })
    }
  }
  const signSolRawTx = async (txHex: string) => {
    try {
      const txStr = txHex

      const mfaRes = await getMfaParams({
        content: txStr,
        chainid: chainId || mockSolEvmChainId
      })

      if (mfaRes.mfa) {
        setPassKey(mfaRes.mfa)

        const signRes = await solSignRawTransactionWithMFA({
          data: { rawTransaction: txStr ?? '' },
          mfa: mfaRes.mfa
        })

        return signRes
      }
    } catch (error) {
      console.error({
        error
      })
    }
  }

  const signSolMessage = async (params: {
    message: string
    walletId: number
  }) => {
    const mfaRes = await getMfaParams({
      content: params,
      chainid: chainId || 501
    })

    if (mfaRes.mfa) {
      setPassKey(mfaRes.mfa)

      const signRes = await solSignMessageWithMFA({
        data: params,
        mfa: mfaRes.mfa
      })

      return signRes
    }
  }

  const signSuiMessage = async (params: {
    message: string
    walletId: number
  }) => {
    const mfaRes = await getMfaParams({
      content: params,
      chainid: chainId || sui.id
    })

    if (mfaRes.mfa) {
      setPassKey(mfaRes.mfa)

      const signRes = await suiSignMessage({
        params: params,
        mfa: mfaRes.mfa
      })

      return signRes
    }
  }

  // const sendBtcTransaction = async (params: {
  //   network: BTCNetworkType
  //   addressType: BTCNetworkAddressType
  //   txStr: any
  //   value: string
  //   token?: IToken
  // }) => {
  //   if (params.txStr) {
  //     const mfaRes = await getMfaParams({
  //       content: params.txStr,
  //       chainid: mockSolEvmChainId
  //     })

  //     if (mfaRes) {
  //       setPassKey(mfaRes.mfa)

  //       const res = await btcSignPsbtAndPush({
  //         networkType: params.network,
  //         addressType: params.addressType, // toAddress
  //         psbtHex: params.txStr.result.psbtHex,
  //         autoFinalized: true
  //       })

  //       if (res && res.result) {
  //         const hash = res.result

  //         const chain = chains.find((chain) => chain.type === Web3Type.BTC)
  //         const chainId = mockBtcEvmChainId

  //         const historySave: IHistoryType = {
  //           fromAddress: params.network,
  //           toAddress: params.addressType,
  //           fromAmount: params?.value,
  //           toAmount: params?.value,
  //           fromSwapTokens: {
  //             token: {
  //               ...params.token,
  //               balance: params.token?.balance?.toString() || '0',
  //               balanceItem: undefined
  //             },
  //             chain: chain,
  //             balance: undefined
  //           },
  //           toSwapTokens: {
  //             token: {
  //               ...params.token,
  //               balance: params.token?.balance?.toString() || '0',
  //               balanceItem: undefined
  //             },
  //             chain: chain,
  //             balance: undefined
  //           },
  //           nonce: new Date().getTime(),
  //           time: new Date().getTime(),
  //           hash: hash,
  //           historyType: 'Send',
  //           chain: chain,
  //           status: 'pending'
  //         }

  //         if (typeof chainId === 'number') {
  //           setTransactionHistory({
  //             history: historySave,
  //             chainId
  //           })
  //         }

  //         return hash
  //       }
  //     }
  //   }
  // }

  // const sendTronTransaction = async ({
  //   fromAddress,
  //   toAddress,
  //   fromValue,
  //   toValue,
  //   contract,
  //   transaction,
  //   fromToken,
  //   historyType,
  //   saveHistory,
  //   toToken
  // }: {
  //   fromAddress: string | undefined
  //   toAddress: string
  //   fromValue: bigint | undefined //1e9   decimal: 9
  //   toValue?: bigint | undefined //1e9   decimal: 9
  //   contract?: string
  //   fromToken?: IToken | undefined
  //   toToken?: IToken | undefined
  //   transaction?: {
  //     raw_data: any
  //     raw_data_hex: any
  //     txID: any
  //     visible: any
  //   }
  //   historyType?: 'Send' | 'Swap' | 'Approve'
  //   saveHistory?: boolean
  // }) => {
  //   const tx = await (async () => {
  //     if (transaction) {
  //       return transaction
  //     }
  //     if (fromAddress && toAddress && fromValue) {
  //       return await sendTransaction({
  //         amount: fromValue,
  //         from: fromAddress,
  //         to: toAddress,
  //         contract
  //       })
  //     }
  //   })()

  //   const rawTransaction = (() => {
  //     if (contract) {
  //       return Buffer.from(JSON.stringify(tx), 'utf8').toString('hex')
  //     } else {
  //       return Buffer.from(JSON.stringify(tx), 'utf8').toString('hex')
  //     }
  //   })()

  //   const mfaRes = await getMfaParams({
  //     content: rawTransaction,
  //     chainid: mockTronChainId
  //   })

  //   if (mfaRes.mfa) {
  //     setPassKey(mfaRes.mfa)

  //     const signRes = await tronSignRawTransaction({
  //       rawTransaction: rawTransaction ?? ''
  //     })

  //     if (signRes) {
  //       const rawTransaction: {
  //         visible: boolean
  //         txID: string
  //         raw_data_hex: string
  //         raw_data: any
  //         signature: string[]
  //       } = JSON.parse(Buffer.from(signRes.result, 'hex').toString('utf-8'))
  //       const hash: {
  //         result: boolean
  //         txid: string
  //         transaction: any
  //       } = await sendRawTransaction({
  //         rawTransaction: rawTransaction
  //       })

  //       if (!hash) {
  //         return
  //       }

  //       const chain = chains.find((chain) => chain.type === Web3Type.TRON)
  //       const chainId = mockTronChainId
  //       const historySave: IHistoryType = {
  //         fromAddress: fromAddress,
  //         toAddress: toAddress,
  //         fromAmount: fromValue?.toString(),
  //         toAmount: (toValue ? toValue : fromValue)?.toString() || '0',
  //         fromSwapTokens: {
  //           token: {
  //             ...fromToken,
  //             balance: fromToken?.balance?.toString() || '0',
  //             balanceItem: undefined
  //           },
  //           chain: chain,
  //           balance: undefined
  //         },
  //         toSwapTokens: {
  //           token: toToken
  //             ? {
  //                 ...toToken,
  //                 balance: toToken?.balance?.toString() || '0',
  //                 balanceItem: undefined
  //               }
  //             : {
  //                 ...fromToken,
  //                 balance: fromToken?.balance?.toString() || '0',
  //                 balanceItem: undefined
  //               },
  //           chain: chain,
  //           balance: undefined
  //         },
  //         nonce: new Date().getTime(),
  //         time: new Date().getTime(),
  //         hash: hash.txid,
  //         historyType: historyType ? historyType : 'Send',
  //         chain: chain,
  //         status: 'pending'
  //       }

  //       historySave.historyType !== 'Approve' &&
  //         setTransactionHistory({
  //           history: historySave,
  //           chainId
  //         })

  //       if (typeof saveHistory === 'boolean' ? saveHistory : true) {
  //         const chainDivId = toToken && getChainId(getChainByToken(toToken))

  //         if (toToken && toToken?.address && chainDivId) {
  //           await v1AddAssetApi({
  //             chain_id: chainDivId,
  //             decimals: toToken.decimals,
  //             image: toToken.image,
  //             name: toToken.name,
  //             symbol: toToken.symbol,
  //             token: checkChainIdIsEVM(chainDivId)
  //               ? toToken.address.toLowerCase()
  //               : toToken.address
  //           })
  //         }
  //       }

  //       if (hash) {
  //         return hash.txid
  //       }
  //     }
  //   }
  // }

  const signEVMMessage = async (params: {
    message: string
    walletId?: number
  }) => {
    try {
      console.log('signEVMMessage', params)

      // if (res) {
      const mfaParams = params
      console.log('mfaParams', mfaParams)
      // if (!chainId) throw new Error('Chain id error.')
      const mfaRes = await getMfaParams({
        content: mfaParams,
        chainid: chainId || mockTonChainId
      })
      console.log({ mfaRes })
      if (mfaRes) {
        const { mfa } = mfaRes

        if (!mfa) {
          throw new Error('Mfa error.')
        }
        console.log({
          mfa
        })
        setPassKey(mfa)

        return await signMessageApi({
          mfa,
          signData: params
        })
      }
    } catch (error: any) {
      console.warn({
        error
      })
      toast.error(error?.message || error.details || error)
    }
  }

  const sendTonTransaction = async ({
    params,
    paramsForPure
  }: {
    params?: TonSigningTransactionType
    paramsForPure?: TonTxRequestStandard
  }) => {
    try {
      let singingMessage: Awaited<ReturnType<typeof createSigningTransaction>>
      if (paramsForPure)
        singingMessage =
          chainId === mockTonTestnetChainId
            ? await createSigningTonTestnetTransactionPure(paramsForPure)
            : await createSigningTransactionPure(paramsForPure)
      else
        singingMessage =
          chainId === mockTonTestnetChainId
            ? await createSigningTonTestnetTransaction(
                params as TonSigningTonTestnetTransactionType
              )
            : await createSigningTransaction(
                params as TonSigningTransactionType
              )
      if (!singingMessage?.signingMessageBoc) return

      const mfaRes = await getMfaParams({
        content: singingMessage,
        chainid: chainId || mockTonChainId
      })

      const { mfa } = mfaRes
      if (!mfa) return

      setPassKey(mfa)

      const mfaParams = {
        signingMessageBoc: singingMessage.signingMessageBoc,
        stateInitBoc: singingMessage?.stateInitBoc || '',
        isTestnet: chainId === mockTonTestnetChainId
      }

      const {
        result: signedTransaction,
        code,
        message
      } = await tonSignMessage(mfa, mfaParams)

      // console.log('tonSignMessage ==>', signedTransaction, code, message);

      if (+code !== 10000) throw new Error(message)

      return {
        signedTransaction,
        code,
        message
      }
    } catch (error: any) {
      console.warn({
        error
      })
      if (error.name === 'EstimateGasExecutionError') {
        toast.error(error.details)
      } else {
        toast.error(error?.message || error.details || error)
      }
    }
  }

  const signSuiTransaction = async (
    params: SendSuiTransactionParams
  ): Promise<{ result: string; code: number; message: string } | null> => {
    const { bytes, transactionBlock, chainId } = params
    const mfaRes = await getMfaParams({
      content: transactionBlock,
      chainid: chainId
    })

    const { mfa } = mfaRes
    if (!mfa) {
      return null
    }
    setPassKey(mfa)

    const signRes = await suiSignTransaction({
      mfa,
      params: {
        tx: transactionBlock
      }
    })
    return signRes
    // const { result: signature } = signRes

    // if (!signature || !bytes) return ''

    // return signature
  }

  return {
    // evmWallet,
    sendEVMTransaction,
    sendSolTransaction,
    signSolMessage,
    signSuiMessage,
    // sendBtcTransaction,
    // sendTronTransaction,
    signEVMMessage,
    sendTonTransaction,
    signSolRawTx,
    signSuiTransaction
  }
}

export default useSendTransaction
