import { logoSvg, oauthLogoSvg, tgWhiteLineSvg } from 'assets'
import { Button } from 'components/Button'
import React, { useEffect } from 'react'
import {
  MainButton,
  useInitData,
  useWebApp
} from '@vkruglikov/react-telegram-web-app'
import useLoginInfo, { UserType } from 'hooks/useLoginInfo'
// import useApp from 'hooks/useApp'
import useBiometricManager from 'hooks/useBiometricManager'
import useTradePwd from 'hooks/useTradePwd'
import useNavigateWithParams from 'hooks/useNavigateWithParams'
import { useAtom, useAtomValue } from 'jotai'
import { userStorageAtom } from 'state/storage'
import { SafeArea } from 'antd-mobile'
import { classNames } from 'utils'
import { Link } from 'react-router-dom'

export default function Login() {
  const [initDataUnsafe, initData] = useInitData()
  const { login } = useLoginInfo()
  const { isTelegramReady, setIsTelegramReady } = useBiometricManager()
  const { checkTradePwd } = useTradePwd()
  const navigate = useNavigateWithParams()
  const userAtom = useAtomValue(userStorageAtom)

  useEffect(() => {
    // if (isTelegramReady && localStorage.getItem('user')) {
    //   login({
    //     success: (user) => {
    //       props?.onLogin && props?.onLogin('auto', user)
    //     }
    //   })
    // }
  }, [isTelegramReady])

  const handleLogin = async () => {
    await login({
      force: true,
      success: async (usercall) => {
        usercall && navigate('/')
      },
      target: 'login'
    })
  }

  return (
    <div
      className={
        'login-bg flex h-full flex-col items-center justify-between overflow-hidden'
      }
    >
      <div className="relative flex w-full flex-1  flex-col items-center">
        <Link to={'/forget'}>
          <img src={logoSvg} className={`mt-[90px] size-[100px]`} />
        </Link>
        <div className="flex size-full flex-col justify-between rounded-t-[12px] px-[16px]">
          <div className={`flex-none`}>
            <h2 className="mt-[14px] text-center text-[32px] font-extrabold leading-normal text-title">
              Welcome
            </h2>
            <p className="m-auto w-full  text-center text-xl font-normal leading-[21px] text-title/40">
              Trade and manage crypto across all major chains, all in Telegram.
            </p>
          </div>
        </div>
      </div>
      <div className={classNames('flex-none w-full px-[16px] py-[20px]')}>
        <Button
          size="large"
          block
          onClick={handleLogin}
          disabled={!isTelegramReady || !initData}
        >
          Continue
        </Button>
        {/* <SafeArea position="bottom" style={{ '--multiple': 0.5 }} /> */}
      </div>
    </div>
  )
}
