import useTradePwd from 'hooks/useTradePwd'
import PinPayBase, { PayPinBaseRefType } from './Base'
import { useEffect, useMemo, useRef, useState } from 'react'
import toast from 'components/Toast'
import { Toast } from 'antd-mobile'
import Container from 'components/Container'
import useNavigateWithParams from 'hooks/useNavigateWithParams'
import { useSetAtom } from 'jotai'
import { userStorageAtom } from 'state/storage'
import { errorContents } from 'config/const'
import useLoginInfo from '@/hooks/useLoginInfo'

const PinPaySet = () => {
  const { setTradePwd } = useTradePwd()
  const navigate = useNavigateWithParams()
  const setUserAtom = useSetAtom(userStorageAtom)
  const { user } = useLoginInfo()

  const paypinRef = useRef<PayPinBaseRefType | null>(null)
  const [isError, setIsError] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [step, setStep] = useState(1)
  const [old, setOld] = useState('')
  const [failedCnt, setFailedCnt] = useState(0)
  const title = useMemo(() => {
    return step == 1 ? 'Set' : 'Confirm'
  }, [step])
  const [btnStatus, setBtnStatus] = useState('normal')

  useEffect(() => {
    if (user?.setTradePassword || user?.set_trade_password) {
      navigate('/')
    }
  }, [user?.setTradePassword, user?.set_trade_password])

  const verifyFirst = async (pass: string) => {
    if (!pass) {
      setStep(1)
      setOld('')
      setIsError(true)
      setErrMsg(errorContents.paypinErrors.wrong1)
      paypinRef.current?.handleInit()
    } else {
      setStep(2)
      setOld(pass)
      setIsError(false)
      setErrMsg('')
      setFailedCnt(0)
      paypinRef.current?.handleInit()
    }
  }

  const setPaypin = async (pass: string) => {
    if (btnStatus != 'normal') return

    if (!pass || pass != old) {
      setStep(1)
      setOld('')
      // setFailedCnt(0)
      setIsError(true)
      setErrMsg(errorContents.paypinErrors.NotMatch)
      paypinRef.current?.handleInit()
      return
    }

    Toast.show({
      icon: 'loading',
      duration: 0,
      maskClickable: false
    })
    try {
      setBtnStatus('loading')
      const result = await setTradePwd(pass)
      if (result) {
        setIsError(false)
        setBtnStatus('success')
        setUserAtom((prev) => {
          if (prev) {
            localStorage.setItem(
              'user',
              JSON.stringify({
                ...prev,
                set_trade_password: true,
                setTradePassword: true
              })
            )
            return { ...prev, set_trade_password: true, setTradePassword: true }
          }
          localStorage.setItem(
            'user',
            JSON.stringify({ set_trade_password: true, setTradePassword: true })
          )
          return { set_trade_password: true, setTradePassword: true }
        })
        navigate('/recovery-email') // don't need to backup page, next to set recovery email
      } else {
        setStep(1)
        setOld('')
        setIsError(true)
        setErrMsg(errorContents.paypinErrors.wrong1)
        paypinRef.current?.handleInit()
        setBtnStatus('normal')
      }
    } catch (err) {
      setStep(1)
      setOld('')
      setIsError(true)
      setErrMsg(
        typeof err == 'string' ? err : errorContents.paypinErrors.wrong1
      )
      paypinRef.current?.handleInit()
      setBtnStatus('normal')
    } finally {
      Toast.clear()
    }
  }

  const onConfirm = async (pass: string) => {
    if (step == 1) {
      verifyFirst(pass)
    } else {
      await setPaypin(pass)
    }
  }

  return (
    <Container>
      <PinPayBase
        from="set"
        onConfirm={onConfirm}
        isError={isError}
        errMsg={errMsg}
        title={title}
        failedCnt={failedCnt}
        ref={paypinRef}
      />
    </Container>
  )
}

export default PinPaySet
