export const SENTRY_DSN =
  'https://77e7b773293df6bc6cfd3eaa7479fbad@o4508120700289024.ingest.us.sentry.io/4508294136987648'

// Set `sampleRate` to 1.0 to capture 100%
// of events for error monitoring.
export const SENTRY_SAMPLE_RATE = 1.0

// Set `ignoreErrors` to ignore some errors.
export const SENTRY_IGNORE_ERRORS = [
  'Network Error',
  // the error messages of telegram-web-app itself
  'Java object is gone',
  // the error messages of telegram-web-app itself
  'Java bridge method invocation error'
]

// Set tracesSampleRate to 0.2 to capture 20%
// of transactions for performance monitoring.
export const SENTRY_TRACES_SAMPLE_RATE = 0.2

// Set denyUrls to ignore some urls.
export const SENTRY_DENY_URLS = ['localhost', '127.0.0.1']

/**
 * Severity levels.
 * this is a copy of the enum from @sentry/types
 */
export enum SeverityLevel {
  FATAL = 'fatal',
  ERROR = 'error',
  WARNING = 'warning',
  LOG = 'log',
  INFO = 'info',
  DEBUG = 'debug'
}

/**
 * this is a list of success api business code
 */
export const SENTRY_HTTP_SUCCESS_BIZ_CODE = [20000, 10000, 8000]
// this is a list of http ignore error STATUS
export const SENTRY_HTTP_IGNORE_ERROR_STATUS = [401]
