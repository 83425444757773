import App from 'App'
import React from 'react'
import * as Sentry from '@sentry/react'
import {
  SENTRY_DENY_URLS,
  SENTRY_DSN,
  SENTRY_IGNORE_ERRORS,
  SENTRY_SAMPLE_RATE,
  SENTRY_TRACES_SAMPLE_RATE
} from './utils/sentry/const'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import Providers from './proviers'
import 'tailwindcss/tailwind.css'
import 'index.css'
import eruda from 'eruda'
import { isLocationEnv } from './utils'

const container = document.getElementById('root') as HTMLDivElement
const root = createRoot(container)

// init sentry
if (!isLocationEnv()) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      })
    ],
    sampleRate: SENTRY_SAMPLE_RATE,
    ignoreErrors: SENTRY_IGNORE_ERRORS,
    denyUrls: SENTRY_DENY_URLS,
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    environment: import.meta.env.VITE_NODE_ENV
  })
}

// console.log('env', import.meta.env)
if (import.meta.env.VITE_CONSOLE == '1') {
  eruda.init()
  const shadowRoot = document.querySelector('#eruda')?.shadowRoot
  const element = shadowRoot?.querySelector('.eruda-entry-btn') as HTMLElement
  if (element) {
    element.style.top = '0px'
  }
}
root.render(<App />)
