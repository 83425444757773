import { Popup, NumberKeyboard, Space } from 'antd-mobile'
import React, {
  useState,
  useRef,
  useEffect
  // useMemo,
  // useEffect,
  // useLayoutEffect
} from 'react'
import { Button } from '../components/Button'
// import QRCode from 'react-qr-code'
// import Copy from '../components/Copy'
// import CopyToClipboard from 'react-copy-to-clipboard'
// import toast from '../components/Toast'
import {
  CloseRoundSvg,
  popupCloseSvg,
  // back1Svg,
  // ellipsisRoundSvg,
  settingSvg,
  securityKeySvg,
  payPinSvg
} from 'assets'
// import BaseAvatar from '../components/BaseAvatar'
import 'assets/styles/pass.css'
// import { useNavigate } from 'react-router-dom'
import {
  classNames
  // btcTypeMaps,
  // IBtcAddressType,
  // btcAddressTypeMaps
} from 'utils'
import PasscodeInput, { MyPasscodeInputRef } from 'components/PasscodeInput'
import { backupMailSvg, arrowSvgs } from 'assets'
// import useLoginInfo from './useLoginInfo'
// import useTokens from './useTokens'
// import { mockBtcEvmChainId } from 'config/btc'
// import { NumberFormatter } from 'components/NumberFormatter'
import { md5, shortenAddress } from 'utils/helper'
// import { zeroAddress } from 'viem'
import { EmailStep1, EmailStep2 } from '../pages/RecoveryEmail'
// import ShowAccountItem from 'pages/manages/components/ShowAccountItem'
// import { IWeb3Type } from 'proviers/web3Provider/type'
// import { btc, ethereum, solana, ton, tron } from 'proviers/web3Provider/chains'
// import { useSecondaryPage } from 'components/SecondaryPage'
// import { ReceivePageComponent } from 'pages/token/detail/Receive'
// import { SendPageComponent } from 'pages/token/send'
// import SendChose from 'pages/token/sendChose'
// import CopySvg from '@/assets/imgs/copy.svg?react'
import SecurityItem from 'pages/wallet/components/SecurityItem'
// import useBiometricManager from './useBiometricManager'
import { useAtom } from 'jotai'
import { backupSettingAtom } from 'state'
// import Loading from 'components/Loading'

const usePopup = ({
  title,
  content,
  showCloseButton = true,
  onClose,
  contentClassName,
  containerClassName,
  closeButtonClassName,
  afterClose,
  showCloseRoundButton,
  initVisible = false,
  onMaskClick,
  titleClassName,
  fullscreen = false,
  className,
  rightIcon,
  cusCloseOnMaskClick = true
}: {
  title?: string | React.ReactNode
  content: React.ReactNode
  showCloseButton?: boolean
  onClose?: () => void
  contentClassName?: string
  closeButtonClassName?: string
  containerClassName?: string
  afterClose?: () => void
  showCloseRoundButton?: boolean
  initVisible?: boolean
  onMaskClick?: () => void
  className?: string
  titleClassName?: string
  fullscreen?: boolean
  rightIcon?: React.ReactNode
  cusCloseOnMaskClick?: boolean
}) => {
  const [open, setOpen] = useState(initVisible)
  const [loading, setLoading] = useState(false)

  // useLayoutEffect(() => {
  //   if (open) {
  //     setTimeout(() => {
  //       setLoading(false)
  //     }, 100)
  //   } else {
  //     setLoading(true)
  //   }
  // }, [open])

  return {
    open,
    setOpen,
    component: (
      <Popup
        closeIcon={null}
        visible={open}
        showCloseButton={showCloseButton}
        onMaskClick={() => {
          cusCloseOnMaskClick && setOpen(false)
          onMaskClick?.()
        }}
        onClose={() => {
          onClose && onClose()
          setOpen(false)
        }}
        afterClose={afterClose}
        className={`keyboard_foot ${className}`}
        bodyStyle={{
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px'
          // minHeight: '40vh'
        }}
      >
        <div
          className={classNames(
            'flex size-full flex-col p-4 pt-0 ',
            [fullscreen ? 'h-[calc(100vh-var(--popup-top-space))]' : ''],
            containerClassName
          )}
        >
          {
            title ? (
              <div
                className={
                  `relative flex-shrink-0 flex h-[--popup-title-height] items-center justify-center text-[16px] font-semibold leading-[18px]` +
                  closeButtonClassName
                }
              >
                {showCloseButton && (
                  <img
                    src={popupCloseSvg}
                    className={`absolute left-0 w-[24px] cursor-pointer`}
                    onClick={() => {
                      setOpen(false)
                      onClose && onClose()
                    }}
                  />
                )}
                {showCloseRoundButton && (
                  <img
                    src={CloseRoundSvg}
                    className={`absolute right-0 w-[24px] cursor-pointer`}
                    onClick={() => {
                      setOpen(false)
                      onClose && onClose()
                    }}
                  />
                )}

                {rightIcon && (
                  <div className={`absolute right-0 w-[24px] cursor-pointer`}>
                    {rightIcon}
                  </div>
                )}

                <div
                  className={
                    (showCloseButton ? ' ' : `w-full text-[#121212] `) +
                    titleClassName
                  }
                >
                  {title}
                </div>
              </div>
            ) : (
              <div
                className={
                  `flex flex-shrink-0 h-[60px] items-center justify-end text-xl font-bold ` +
                  closeButtonClassName
                }
              >
                <img
                  src={CloseRoundSvg}
                  className={`cursor-pointer`}
                  onClick={() => {
                    setOpen(false)
                    onClose && onClose()
                  }}
                />
              </div>
            )
            // showBackButton ? (
            //   <div
            //     className={
            //       `flex w-full h-[60px] items-center justify-between text-xl ` +
            //       closeButtonClassName
            //     }
            //   >
            //     <div
            //       className="flex "
            //       onClick={() => {
            //         setOpen(false)
            //         onClose && onClose()
            //       }}
            //     >
            //       <img
            //         src={arrowSvgs.leftSvg}
            //         className={`mr-[4px] cursor-pointer`}
            //       />
            //       Back
            //     </div>
            //     <img
            //       src={ellipsisRoundSvg}
            //       alt=""
            //       onClick={() => onBackClick && onBackClick()}
            //     />
            //   </div>
            // ) : (
            //   <div
            //     className={
            //       `flex flex-shrink-0 h-[60px] items-center justify-end text-xl font-bold ` +
            //       closeButtonClassName
            //     }
            //   >
            //     <img
            //       src={CloseRoundSvg}
            //       className={`cursor-pointer`}
            //       onClick={() => {
            //         setOpen(false)
            //         onClose && onClose()
            //       }}
            //     />
            //   </div>
            // )
          }
          <div
            className={classNames(
              'max-h-[calc(100%-var(--popup-title-height))] pb-[20px] flex w-full flex-1 flex-col items-center justify-start pt-[20px] overflow-y-auto no-scrollbar',
              contentClassName
            )}
          >
            {loading ? 'loading...' : open && content}
            {/* {loading ? <Loading></Loading> : open && content} */}
          </div>
        </div>
      </Popup>
    )
  }
}

export const usePopupBackOption = (): any => {
  const backOptions = [
    {
      title: 'Add Wallet Shotcut',
      onClick: () => {
        console.log('Add Wallet Shotcu')
      }
    },
    {
      title: 'Terms of Use',
      onClick: () => {}
    },
    {
      title: 'Reload Page',
      onClick: () => {}
    }
  ]

  const popup = usePopup({
    title: 'Options',
    showCloseButton: false,
    showCloseRoundButton: true,
    containerClassName: 'z-[1000]',
    content: (
      <>
        {backOptions.map((e) => (
          <div
            key={e.title}
            className="menu-solid flex w-full flex-col"
            onClick={() => e.onClick()}
          >
            <div className="flex justify-between pb-[27px] pt-[11px]">
              <div className="flex items-center">
                <img src={settingSvg} alt="" />

                <span className="ml-4 text-xl font-[400]">{e.title}</span>
              </div>
            </div>
            <div></div>
          </div>
        ))}
      </>
    ),
    fullscreen: true
  })

  return popup
}

export const usePopupConfirm = ({
  title,
  content,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  onConfirm,
  showCloseButton = true
}: {
  title?: string | React.ReactNode
  content: string | React.ReactNode
  confirmText?: string
  cancelText?: string
  onConfirm: () => void
  showCloseButton?: boolean
}) => {
  const popup = usePopup({
    title,
    showCloseButton,
    content: (
      <div className={` flex w-full flex-col gap-[20px]`}>
        <div className={`text-xl text-[#9E9E9D]`}>{content}</div>
        <div className={'flex w-full flex-col gap-[8px]'}>
          <Button
            block
            theme="white"
            onClick={() => {
              popup.setOpen(false)
            }}
          >
            {cancelText}
          </Button>
          <Button
            block
            onClick={() => {
              onConfirm()
              popup.setOpen(false)
            }}
          >
            {confirmText}
          </Button>
        </div>
      </div>
    )
  })
  return popup
}
// export const usePopupAddress = ({
//   title,
//   address,
//   qrcodeIcon,
//   copyable,
//   network,
//   symbol
// }: {
//   title: string | React.ReactNode
//   address: string
//   qrcodeIcon?: string
//   copyable?: boolean
//   network?: string
//   symbol?: string
// }) => {
//   const onCopy = () => {
//     return toast.success('Copied to clipboard successfully')
//   }
//   const { checkEmailExists } = useLoginInfo()

//   return usePopup({
//     title: title,
//     content: (
//       <div className={`flex w-full flex-col items-center gap-[24px]`}>
//         <div
//           className={`relative flex size-[160px] items-center justify-center rounded-[23px]`}
//           style={{
//             boxShadow: '0px 10px 20px 0px #0000001C'
//           }}
//         >
//           <QRCode value={address || ''} className={`size-[138px]`} />
//           <div className={`absolute inset-0 flex items-center justify-center`}>
//             <div
//               className={`flex size-[32px] items-center justify-center rounded-full bg-[#ffffff]`}
//             >
//               <BaseAvatar
//                 name={symbol || ''}
//                 className="size-[26px] rounded-full"
//                 src={qrcodeIcon}
//                 alt=""
//               />
//             </div>
//           </div>
//         </div>
//         <div className={`flex w-full flex-col  gap-[8px]`}>
//           {(network || symbol) && (
//             <div
//               className={`flex items-center justify-between rounded-[12px] bg-[#F2F4F6]  px-[12px] py-[16px]`}
//             >
//               <div className={`text-df text-[#757580]`}>Network</div>
//               <div className={`flex items-center gap-[4px]`}>
//                 {network && <div className={`text-df`}>{network}</div>}
//                 {symbol && (
//                   <div
//                     className={`h-[16px] rounded-[4px] bg-[#F21F7F] px-[4px] text-df leading-[16px] text-[white]`}
//                   >
//                     {symbol}
//                   </div>
//                 )}
//               </div>
//             </div>
//           )}

//           <div
//             className={`flex flex-col rounded-[12px] bg-[#F2F4F6] px-[12px] py-[16px]`}
//           >
//             <div className={`text-lg text-[#757580]`}>Address</div>
//             <div className={`flex w-full items-center gap-[8px]`}>
//               <div className="min-w-0 flex-1 break-all text-xl">{address}</div>

//               {copyable && (
//                 <Copy text={address || ''} onClick={() => checkEmailExists()} />
//               )}
//             </div>
//           </div>
//         </div>
//         {copyable && (
//           <div className={`mt-[8px] w-full`}>
//             <CopyToClipboard text={address || ''} onCopy={onCopy}>
//               <Button block size={`large`} onClick={() => checkEmailExists()}>
//                 Copy Address
//               </Button>
//             </CopyToClipboard>
//           </div>
//         )}
//       </div>
//     )
//   })
// }

// export const usePopupAddressQRCode = ({
//   title,
//   address,
//   qrcodeIcon,
//   copyable,
//   fullscreen,
//   symbol
// }: {
//   title: string | React.ReactNode
//   address: string | undefined
//   qrcodeIcon?: string | undefined
//   copyable?: boolean
//   fullscreen?: boolean
//   symbol?: string | undefined
// }) => {
//   const onCopy = () => {
//     return toast.success('Copied to clipboard successfully')
//   }
//   const { checkEmailExists } = useLoginInfo()
//   return usePopup({
//     title: title,
//     fullscreen,
//     content: (
//       <div className={`flex size-full flex-col items-center gap-[24px]`}>
//         <div
//           className={`relative flex size-[160px] items-center justify-center rounded-[23px]`}
//           // style={{
//           //   boxShadow: '0px 10px 20px 0px #0000001C'
//           // }}
//         >
//           {address && <QRCode value={address} className={`size-[138px]`} />}
//           {/* <div className={`absolute inset-0 flex items-center justify-center`}>
//             <div
//               className={`flex size-[32px] items-center justify-center rounded-full bg-[#ffffff]`}
//             >
//               {qrcodeIcon && (
//                 <img src={qrcodeIcon} className={`size-[26px] rounded-full`} />
//               )}
//             </div>
//           </div> */}
//         </div>
//         <div className={`flex size-full flex-col  gap-[8px]`}>
//           <div
//             className={`flex flex-col rounded-[12px] bg-[#f9f9f9] px-[14px] py-[16px]`}
//           >
//             {/* <div className={`text-lg text-[#757580]`}>Address</div> */}
//             <div className="flex items-center text-xl font-[400] leading-[21px] ">
//               {qrcodeIcon && (
//                 <img src={qrcodeIcon} className={`size-[32px] rounded-full`} />
//               )}
//               <span className="ml-[6px]">{symbol}</span>
//             </div>
//             <div className={`mt-[8px] flex w-full items-start gap-[8px]`}>
//               <div className="min-w-0 flex-1 break-all text-lg leading-[20px] text-[#6d6d6d]">
//                 {address}
//               </div>
//               {copyable && address && (
//                 <CopyToClipboard text={address} onCopy={onCopy}>
//                   {/*<Button*/}
//                   {/*  className="grow border border-black bg-white text-title"*/}
//                   {/*  size={`large`}*/}
//                   {/*  onClick={() => checkEmailExists()}*/}
//                   {/*>*/}
//                   {/*  Copy Address*/}
//                   {/*</Button>*/}
//                   <CopySvg width={16} height={16} className={'mt-[2px]'} />
//                 </CopyToClipboard>

//                 // <Copy
//                 //   width="14px"
//                 //   height="14px"
//                 //   text={address}
//                 //   onClick={() => checkEmailExists()}
//                 // />
//               )}
//             </div>
//           </div>
//         </div>
//         {copyable && (
//           <div className={`mt-[8px] w-full`}>
//             {address && (
//               <div className="flex items-start gap-[12px] py-[16px] ">
//                 <CopyToClipboard text={address} onCopy={onCopy}>
//                   <Button
//                     className="grow border border-black bg-white text-title"
//                     size={`large`}
//                     onClick={() => checkEmailExists()}
//                   >
//                     Copy Address
//                   </Button>
//                 </CopyToClipboard>
//                 <Button
//                   className="grow border border-title bg-white text-title"
//                   size={`large`}
//                   onClick={() => {}}
//                 >
//                   Share QR Code
//                 </Button>
//               </div>
//             )}
//           </div>
//         )}
//       </div>
//     )
//   })
// }

export const usePopupTradePassword = ({
  onConfirm,
  from = 'confirm',
  afterClose
}: {
  onConfirm: (last: string, first?: string) => void
  from?: 'confirm' | 'set' | 'change'
  afterClose?: () => void
}) => {
  const [old, setOld] = useState('')
  const [pass, setPass] = useState('')
  const [step, setStep] = useState(1)
  const [error, setError] = useState(false)
  const passcodeRef = useRef<MyPasscodeInputRef | null>(null)
  const keyboardRef = useRef<HTMLParagraphElement | null>(null)
  const [keyboardOpen, setKeyboardOpen] = useState(true)
  const [isDisableButton, setIsDisableButton] = useState(true)
  const [backupSetting, setBackupSetting] = useAtom(backupSettingAtom)
  const length = 6

  const title =
    from == 'confirm'
      ? 'Confirm Pay PIN'
      : from == 'set'
        ? 'Set a Pay PIN'
        : step == 1
          ? 'Original Pay PIN'
          : 'Confirm Pay PIN'

  const handleConfirm = async () => {
    if (from == 'confirm' || from == 'set') {
      onConfirm(pass.slice(0, length), old.slice(0, length))
    } else {
      // 'origin'
      if (step == 2) {
        onConfirm(pass.slice(0, length), old.slice(0, length))
      } else {
        setOld(pass.slice(0, length))
        setPass('')
        setStep(2)
        passcodeRef.current?.reset()
      }
    }
  }
  const handleClose = () => {
    setOld('')
    setPass('')
    setStep(2)
    setKeyboardOpen(false)
  }
  const handleInit = () => {
    setOld('')
    setPass('')
    setStep(1)
    setError(false)
    passcodeRef.current?.reset()
    setIsDisableButton(true)
  }

  const handleFocus = () => {
    setKeyboardOpen(true)
    if (
      window.visualViewport?.height &&
      window.visualViewport?.height - 300 <= 250
    ) {
      keyboardRef.current?.scrollIntoView()
    }
  }

  // const handleFill = (value: string) => {
  //   if (value.length == length) {
  //     setPass(value)
  //   }
  // }

  const [small, setSmall] = useState(false)
  useEffect(() => {
    setSmall(window.innerHeight < 42 + 160 + 295 + 20)
  }, [])

  return {
    ...usePopup({
      title,
      onClose: handleClose,
      afterClose: () => {
        afterClose && afterClose()
        handleInit()
      },
      fullscreen: true,
      className: 'z-[1001]',
      content: (
        <div
          className={classNames(
            'pass transition-all w-full',
            keyboardOpen && 'pb-[200px]'
          )}
        >
          <h3 className="text-[20px] font-bold leading-[1.3] text-title">
            {title}
          </h3>
          <Space
            direction="vertical"
            block
            // className="mb-[90px] pt-[24px]"
            className={classNames('mb-[90px]', !small && 'pt-[24px]')}
            style={{ '--gap': '20px' }}
          >
            <p className="h-[21px] text-lg font-normal leading-[21px]">
              {step > 1 && '****'}
            </p>

            <PasscodeInput
              plain={from == 'confirm' ? false : true}
              seperated={true}
              caret={false}
              value={pass}
              setValue={setPass}
              // onFill={handleFill}
              onShow={() => setKeyboardOpen(true)}
              onHide={() => setKeyboardOpen(false)}
              onFocus={handleFocus}
              onChange={(value) => {
                setIsDisableButton(value.length < length)
              }}
              error={error}
              length={length}
              style={{ '--cell-gap': '11px', '--cell-size': '50px' }}
              ref={passcodeRef}
              className="flex w-full items-center justify-center"
            />
            <p ref={keyboardRef}></p>
            {error && (
              <div className="flex items-center justify-between text-lg leading-[21px]">
                <p className="text-[#F21F7F]">Password inconsistent</p>
                <button
                  className="font-medium text-[#0D0E0E]"
                  onClick={() => {
                    setPass('')
                    setError(false)
                    passcodeRef.current?.reset()
                  }}
                >
                  Reset
                </button>
              </div>
            )}
          </Space>
          <Button
            disabled={isDisableButton}
            size="large"
            block
            onClick={handleConfirm}
            status={backupSetting.pinStatus}
          >
            Confirm
          </Button>
        </div>
      )
    }),
    handleInit
  }
}

export const usePopupBackup = ({
  onSkip,
  onConfirm,
  afterClose
}: {
  title?: string
  onSkip?: () => void
  onConfirm?: () => void
  afterClose?: () => void
}) => {
  return usePopup({
    title: ' ',
    afterClose,
    showCloseButton: false,
    showCloseRoundButton: false,
    className: 'email-popup',
    cusCloseOnMaskClick: false,
    content: (
      <>
        <div
          className={
            'lh-[50px] z-[-1] mt-[-75px] flex h-[50px] w-full items-center justify-start text-[20px] font-[700] text-[#121212]'
          }
        >
          Backup
        </div>
        <div className="mt-[30px] flex flex-col items-center gap-[24px] text-xl leading-6">
          <img className="size-[102px]" src={backupMailSvg} />

          <div className="flex flex-col items-center justify-center">
            <span className="font-bold">Add Recovery Email</span>
            <p className="mb-[30px] mt-3 px-4 text-center text-lg font-normal leading-[21px]">
              If you lose access to your account, you can easily restore it by
              logging into the TOMO App with your email address to complete the
              recovery process.
            </p>
          </div>
        </div>

        <div className="mt-[62px] w-full text-xl leading-6">
          <Button block size="large" onClick={onConfirm}>
            Add Now
          </Button>

          {/* <div className="mt-[17px] flex items-center justify-center">
            <button className="font-medium text-title" onClick={onSkip}>
              Skip
            </button>
          </div> */}
        </div>
        {/* <div
          className="mt-[20px] w-full pb-[20px] pt-[12px]"
          onClick={() => {
            navigate('/recovery-phrase')
          }}
        >
          <Button block>Copy Address</Button>
        </div> */}
      </>
    )
  })
}

export const usePopupEmail = ({
  onSuccess,
  afterClose,
  email
}: {
  onSuccess?: () => void
  afterClose?: () => void
  email?: string
}) => {
  const stepRef = useRef(1)
  const [step, setStep] = useState(1)
  const [emailText, setEmailText] = useState('')
  const countdownKey = `be${md5(emailText)}`

  const ret = usePopup({
    title: email ? 'Email backup' : 'Wallet',
    afterClose,
    showCloseButton: false,
    fullscreen: true,
    onClose() {
      if (email) {
        ret.setOpen(false)
        afterClose && afterClose()
      } else {
        if (stepRef.current == 1) {
          ret.setOpen(false)
          afterClose && afterClose()
        } else {
          stepRef.current--
          setStep(stepRef.current)
        }
      }
    },
    cusCloseOnMaskClick: false,
    className: 'email-popup',
    content: email ? (
      <div className={`flex h-[80vh] w-full flex-col items-start gap-[10px]`}>
        <div className={`text-[20px] font-bold text-[#121212]`}>
          Current Email
        </div>
        <div className={`text-lg text-[#121212]`}>{email}</div>
      </div>
    ) : (
      <div className={`no-scrollbar h-[80vh] overflow-y-auto`}>
        {step == 1 ? (
          <EmailStep1
            onConfirm={() => {
              stepRef.current = 2
              setStep(2)
            }}
            email={emailText}
            onChange={setEmailText}
            countdownKey={countdownKey}
          />
        ) : (
          <EmailStep2
            email={emailText}
            countdownKey={countdownKey}
            onSuccess={() => {
              onSuccess && onSuccess()
              ret.setOpen(false)
              afterClose && afterClose()
            }}
          />
        )}
      </div>
    )
  })
  return ret
}

// export const usePopupBtcType = ({
//   title = 'Select address type',
//   afterClose,
//   routeType = 'receive'
// }: {
//   title?: string
//   afterClose?: () => void
//   routeType?: 'receive' | 'send'
// }) => {
//   const secondaryPage = useSecondaryPage()
//   const navigate = useNavigate()
//   const { btcAddress, setWalletType } = useLoginInfo()
//   const { tokens } = useTokens()
//   const token =
//     tokens?.find((item) => item.chain_id === mockBtcEvmChainId) || ({} as any)
//   const btcWallets = useMemo(() => {
//     const price = token?.price || 0
//     return Object.keys(btcTypeMaps).map((walletKey) => {
//       const item = (token?.balanceItem || []).find(
//         (it: any) => it.type === walletKey
//       )

//       return {
//         ...item,
//         address: btcAddress[`${walletKey}Address`],
//         image: token?.image,
//         price,
//         typeName: btcTypeMaps[walletKey],
//         type: walletKey
//       }
//     })
//   }, [token, btcAddress])
//   return usePopup({
//     title,
//     afterClose,
//     fullscreen: true,
//     content: (
//       <>
//         <div className="flex w-full flex-col items-center gap-[30px] text-xl leading-6 text-title">
//           {btcWallets.map((item: any) => (
//             <div
//               key={item?.address}
//               className="flex w-full items-center gap-[10px]"
//               onClick={() => {
//                 if (routeType === 'send') {
//                   // secondaryPage.open(
//                   //   {
//                   //     showHead: false,
//                   //     title: 'Send',
//                   //     children: (
//                   //       <SendPageComponent
//                   //         chain={`${token.chain_id}`}
//                   //         address={
//                   //           token?.address ? token?.address : zeroAddress
//                   //         }
//                   //         wtype={item.type}
//                   //         onBack={() => {
//                   //           secondaryPage.close(true)
//                   //         }}
//                   //       />
//                   //     )
//                   //   },
//                   //   `/token/${token.chain_id}/${
//                   //     token?.address ? token?.address : zeroAddress
//                   //   }/${routeType}/${item.type}`
//                   // )
//                   navigate(
//                     `/token/${token.chain_id}/${
//                       token?.address ? token?.address : zeroAddress
//                     }/${routeType}/${item.type}`
//                   )
//                 } else {
//                   secondaryPage.open(
//                     {
//                       showHead: false,
//                       title: `Receive ${token.symbol.toLocaleUpperCase()}`,
//                       children: (
//                         <ReceivePageComponent
//                           chain={`${token.chain_id}`}
//                           address={
//                             token?.address ? token?.address : zeroAddress
//                           }
//                           wtype={item.type}
//                           onBack={() => {
//                             secondaryPage.close(true)
//                           }}
//                         />
//                       )
//                     },
//                     `/token/${token.chain_id}/${
//                       token?.address ? token?.address : zeroAddress
//                     }/${routeType}/${item.type}`
//                   )
//                 }
//               }}
//             >
//               <img src={item.image} alt="" className="size-[41px]" />

//               <div className="flex flex-1 flex-col">
//                 <div className="flex items-center justify-between gap-[10px] text-xl font-medium leading-[21px]">
//                   <span className="truncate">{item.typeName}</span>
//                   <span>{item.formatted || 0}</span>
//                 </div>
//                 <div className="flex items-center justify-between gap-[10px] text-df leading-[17px]">
//                   <span className="opacity-60">
//                     {shortenAddress(item.address)}
//                   </span>
//                   <span className="opacity-40">
//                     $
//                     <NumberFormatter
//                       value={
//                         item.formatted && item.price
//                           ? item.price * item.formatted
//                           : 0
//                       }
//                     />
//                   </span>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </>
//     )
//   })
// }

// export const useAccountAddressPopup = () => {
//   const [chainQrcode, setChainQrcode] = useState<
//     | {
//         web3Type: IWeb3Type
//         type: IBtcAddressType | undefined
//       }
//     | undefined
//   >()
//   const { evmAddress, solAddress, btcAddress, tonAddress, tronAddress } =
//     useLoginInfo()

//   const renderChians = [
//     ...btcAddressTypeMaps.map((type) => {
//       return {
//         chain: btc,
//         address: btcAddress[`${type}Address`],
//         type: type
//       }
//     }),
//     {
//       chain: ethereum,
//       address: evmAddress,
//       type: undefined
//     },
//     {
//       chain: solana,
//       address: solAddress,
//       type: undefined
//     },
//     {
//       chain: ton,
//       address: tonAddress,
//       type: undefined
//     },
//     {
//       chain: tron,
//       address: tronAddress,
//       type: undefined
//     }
//   ]

//   const showQrcodeChainInfo = renderChians.find(
//     (chain) =>
//       chain.chain.type === chainQrcode?.web3Type &&
//       chain.type === chainQrcode.type
//   )

//   const showQrAddress = showQrcodeChainInfo?.address

//   const getSymbol = () => {
//     const chainType = showQrcodeChainInfo?.chain.type
//     switch (chainType) {
//       case 'EVM':
//         return showQrcodeChainInfo?.chain.chain?.nativeCurrency.symbol
//       default:
//         return chainType
//     }
//   }

//   const { setOpen: setReceiveOpen, component: receiveComponent } =
//     usePopupAddressQRCode({
//       title: 'Account Address',
//       copyable: true,
//       address: showQrAddress,
//       qrcodeIcon: showQrcodeChainInfo?.chain.icon,
//       fullscreen: true,
//       symbol: getSymbol()
//     })

//   return {
//     renderChians,
//     ...usePopup({
//       fullscreen: true,
//       content: (
//         <>
//           {receiveComponent}
//           <div className="flex w-full flex-col gap-[12px]">
//             {renderChians.map((chain) => {
//               const address = chain.address
//               return (
//                 <ShowAccountItem
//                   key={address}
//                   chain={chain.chain}
//                   address={address}
//                   type={chain.type}
//                   openQrcode={() => {
//                     setChainQrcode({
//                       type: chain.type,
//                       web3Type: chain.chain.type
//                     })
//                     setReceiveOpen(true)
//                   }}
//                 />
//               )
//             })}
//           </div>
//         </>
//       )
//     })
//   }
// }

export const usePopup2FA = ({
  onItemClick,
  onClose
}: {
  onItemClick?: (item: string) => void
  onClose?: () => void
}) => {
  const ret = usePopup({
    title: 'TOMO Wallet',
    showCloseButton: true,
    fullscreen: true,
    onClose,
    className: 'email-popup',
    content: (
      <div className="leading-[1.3] text-title">
        <h3 className="text-[20px] font-bold">
          Two-Factor Authentication (2FA)
        </h3>

        <p className="mt-[10px] text-lg leading-normal">
          To enhance the security of your account, we strongly recommend
          enabling at least one 2FA.
        </p>

        <div className="mt-[28px] flex flex-col gap-[14px]">
          <SecurityItem
            containerClass="opacity-40"
            text={'Passkeys(Face ID)'}
            icon={securityKeySvg}
            checked={false}
          />

          <SecurityItem
            text={'Pay PIN'}
            icon={payPinSvg}
            checked={false}
            onClick={() => {
              onItemClick && onItemClick('pin')
            }}
          />
        </div>
      </div>
    )
  })
  return ret
}

export default usePopup
