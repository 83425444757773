import { Popup } from 'antd-mobile'
import { useAtom } from 'jotai'
import { verifyPinpayAtom } from 'state'
import PinPayVerify from './Verify'

const VerifyModal = () => {
  const [popupState, setPopupState] = useAtom(verifyPinpayAtom)

  const closePopup = (mfa: string) => {
    if (popupState.resolve) {
      popupState.resolve(mfa)
    }
    setPopupState({ visible: false, resolve: null })
  }

  return (
    <Popup
      position="right"
      closeIcon={null}
      visible={popupState.visible}
      style={{ '--min-width': '100vw' }}
    >
      <PinPayVerify onSuccess={closePopup} />
    </Popup>
  )
}

export default VerifyModal
