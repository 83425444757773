import VerifyModal from './components/pinpay/VerifyModal'
import { useLocation, useNavigate } from 'react-router-dom'
import { RawData } from './components/SignTransaction/DescriptionOrRawData'
import { BackButton } from '@vkruglikov/react-telegram-web-app'
import { pageSettingAtom } from '@/state'
import { useAtomValue } from 'jotai'

const RawDataDetail = () => {
  const pageSetting = useAtomValue(pageSettingAtom)
  const navigate = useNavigate()
  const location = useLocation()
  const rawData = location?.state || ''
  const onBack = () => {
    pageSetting.navBar?.onBack ? pageSetting.navBar?.onBack() : navigate(-1)
  }

  return (
    <>
      <BackButton onClick={onBack}></BackButton>
      <RawData rawData={rawData} />
      <VerifyModal />
    </>
  )
}

export default RawDataDetail
