import {
  forwardRef,
  useRef,
  useState,
  useEffect,
  useImperativeHandle
} from 'react'
import {
  NumberKeyboard,
  PasscodeInput as RpasscodeInput,
  PasscodeInputProps,
  PasscodeInputRef
} from 'antd-mobile'
import CustomNumberKeyboard from './NumberKeyboard/NumberKeyboard'

export type MyPasscodeInputRef = {
  focus: () => void
  reset: () => void
  onChange?: (value: string) => void
}
type MyPasscodeInputProps = {
  value: string
  setValue: React.Dispatch<React.SetStateAction<string>>
  onHide: () => void
  onShow: () => void
  onChange?: (value: string) => void
} & PasscodeInputProps

// Encapsulating components with forwardRef
const PasscodeInput = forwardRef<MyPasscodeInputProps, MyPasscodeInputRef>(
  (props: MyPasscodeInputProps, ref: MyPasscodeInputRef) => {
    const passcodeRef = useRef<PasscodeInputRef | null>(null)
    const [visible, setVisible] = useState(false)
    const cellLength = props?.length || 4

    useEffect(() => {
      setVisible(true)
    }, [])

    const keybordActions = {
      onClose: () => {
        // setVisible(false)
        // props.onHide?.()
        passcodeRef.current?.blur()
      },
      onInput: (v: string) => {
        const newValue = `${props.value}${v}`.slice(0, cellLength)
        // console.log('newValuenewValue', newValue, newValue.length)
        props.setValue(newValue)
        props.onChange?.(newValue)
        // }
      },
      onDelete: () => {
        // setValue(value.slice(0, -1))

        const newValue = props.value.slice(0, -1)
        props.setValue(newValue)
        props.onChange?.(newValue)
      }
    }
    const inputActions = {
      onFocus: () => {
        // setVisible(true)
        props.onFocus?.()
      },
      onBlur: () => {
        // setVisible(false)
        // props.onBlur?.()
        // keybordActions.onClose()
      }
    }

    useEffect(() => {
      setVisible(true)
      passcodeRef.current?.focus()
      props.onShow?.()
    }, [])

    useImperativeHandle(ref, () => ({
      focus: () => {
        props.onFocus?.()
      },
      reset: () => {
        props.setValue('')
        props.onChange?.('')
      }
    }))

    return (
      <>
        <RpasscodeInput
          {...props}
          length={cellLength}
          value={props.value}
          ref={passcodeRef}
          {...inputActions}
          keyboard={<></>}
        />

        <CustomNumberKeyboard
          visible={visible}
          value={props.value}
          setValue={props.setValue}
          setVisible={setVisible}
          onDone={keybordActions.onClose}
          onClose={keybordActions.onClose}
          onInput={keybordActions.onInput}
          onDelete={keybordActions.onDelete}
          isForcedValue={false}
          showCloseButton={false}
          title={''}
          safeArea={true}
        />
      </>
    )
  }
)
PasscodeInput.displayName = 'PasscodeInput'

export default PasscodeInput
