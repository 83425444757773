import useLoginInfo from 'hooks/useLoginInfo'
import { EmailStep2 } from './RecoveryEmail'
import { md5 } from 'utils/helper'
import Container from 'components/Container'
import { useMemo, useRef, useState } from 'react'
import PinPayBase, { PayPinBaseRefType } from './components/pinpay/Base'
import useTradePwd from 'hooks/useTradePwd'
import toast from 'components/Toast'
import useNavigateWithParams from 'hooks/useNavigateWithParams'
import { Toast } from 'antd-mobile'
import { BackButton } from '@vkruglikov/react-telegram-web-app'
import { errorContents } from 'config/const'

const ForgetStep1 = ({
  email,
  onSuccess
}: {
  email: string
  onSuccess: (code: string) => void
}) => {
  const countdownKey = `bf${md5(`${email || ''}`)}`
  return (
    <EmailStep2
      from="reset"
      email={email}
      countdownKey={countdownKey}
      onReset={(code: string) => {
        onSuccess && onSuccess(code)
      }}
    />
  )
}

const ForgetPage = () => {
  const { user } = useLoginInfo()
  const { resetTradePwd } = useTradePwd()
  const email = useMemo(() => {
    return user?.email || ''
  }, [user?.email])

  const payPinRef = useRef<PayPinBaseRefType>(null)
  const [mailCode, setMailCode] = useState('')
  const [step, setStep] = useState(1)
  const navigate = useNavigateWithParams()

  const [btnStatus, setBtnStatus] = useState('normal')
  const from = 'reset'
  const [isError, setIsError] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [old, setOld] = useState('')
  const [pinStep, setPinStep] = useState(1)
  const title = useMemo(() => {
    return pinStep == 1 ? 'Set' : 'Confirm'
  }, [pinStep])

  const verifyFirst = (pass: string) => {
    if (!pass) {
      setPinStep(1)
      setOld('')
      setIsError(true)
      setErrMsg(errorContents.paypinErrors.wrong1)
      payPinRef.current?.handleInit()
    } else {
      setPinStep(2)
      setOld(pass)
      setIsError(false)
      setErrMsg('')
      payPinRef.current?.handleInit()
    }
  }

  const verifyAndReset = async (pass: string) => {
    if (btnStatus != 'normal') return

    if (!pass || pass != old) {
      setPinStep(1)
      setOld('')
      setIsError(true)
      setErrMsg(errorContents.paypinErrors.NotMatch)
      payPinRef.current?.handleInit()
      return
    }

    Toast.show({
      icon: 'loading',
      duration: 0,
      maskClickable: false
    })

    try {
      setBtnStatus('loading')
      await resetTradePwd(mailCode, pass)
      setBtnStatus('success')
      setIsError(false)
      setErrMsg('')
      toast.success('Reset successfully.')
      navigate(-1)
    } catch (err) {
      toast.error(err as string)
      setBtnStatus('normal')
      setStep(1)
      setPinStep(1)
      setOld('')
      setIsError(false)
      setErrMsg('')
      payPinRef.current?.handleInit()
      return
    } finally {
      Toast.clear()
    }
  }

  const onPaypinConfirm = async (pass: string) => {
    if (pinStep == 1) {
      verifyFirst(pass)
    } else {
      await verifyAndReset(pass)
    }
  }

  return (
    <>
      <BackButton
        onClick={() => {
          if (step == 1) navigate(-1)
          else setStep(1)
        }}
      ></BackButton>
      <Container>
        {step == 1 && (
          <ForgetStep1
            email={email}
            onSuccess={(code: string) => {
              setMailCode(code)
              setStep(2)
            }}
          />
        )}

        {step == 2 && (
          <PinPayBase
            title={title}
            from={from}
            onConfirm={onPaypinConfirm}
            isError={isError}
            errMsg={errMsg}
            ref={payPinRef}
          />
        )}
      </Container>
    </>
  )
}

export default ForgetPage
