import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../type'
import * as wagmiChains from 'wagmi/chains'
import { B3 } from '../wagmiChainsConfig/b3'

const chain = B3
const icon = chainsSvgs.b3Svg
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: chain as any,
  type: 'EVM',
  name: chain.name,
  icon: icon,
  networkType
}

export default chainInfo
