import {
  bindEmailCodeVerify,
  bindEmailCodeSend,
  reLoginEmailSend as reLoginEmailSendApi,
  reLoginEmailVerify as reLoginEmailVerifyApi,
  reLoginIncreaseAuth as reLoginIncreaseAuthApi,
  reLoginPassIncrease as reLoginPassIncreaseApi,
  sendTradePwdEmail as sendTradePwdEmailApi,
  verifyTradeEmail as verifyTradeEmailApi,
  bindRecoverCodeCode,
  bindRecoverCodeSend,
  bindRecoverEmailCodeVerify
} from 'api'
import toast from '../components/Toast'

const successCode = 10000
const useEmail = () => {
  const verifyBindEmailCode = async (params: {
    email: string
    code: string
  }) => {
    try {
      const { code, result, message } = await bindEmailCodeVerify(params)
      // console.log({ code, result, message })
      if (code === successCode) {
        return true
      }
      toast.error(message)
    } catch (err: any) {
      toast.error(err?.response?.data?.msg || err?.message || '')
    }
    return false
  }
  const sendBindEmailCode = async (email: string) => {
    try {
      const { code, result, message } = await bindEmailCodeSend(email)
      if (code === successCode) {
        return true
      }
      // toast.error(message)
      return code
    } catch (err: any) {
      toast.error(err?.response?.data?.msg || err?.message || '')
    }
    return false
  }

  const verifyBindRecoverEmailCode = async (params: {
    email: string
    code: string
  }) => {
    try {
      const { code, result, message } = await bindRecoverEmailCodeVerify(params)
      // console.log({ code, result, message })
      if (code === successCode) {
        return true
      }
      toast.error(message)
    } catch (err: any) {
      toast.error(err?.response?.data?.msg || err?.message || '')
    }
    return false
  }

  const sendBindRrcoverEmailCode = async (email: string) => {
    try {
      const { code, result, message } = await bindRecoverCodeSend(email)
      if (code === successCode) {
        return true
      }
      // toast.error(message)
      return code
    } catch (err: any) {
      toast.error(err?.response?.data?.msg || err?.message || '')
    }
    return false
  }
  

  const reLoginEmailSend = async () => {
    try {
      const { code, message } = await reLoginEmailSendApi()
      if (code === 8000) {
        return true
      }
      toast.error(message)
      return code
    } catch (err: any) {
      toast.error(err?.response?.data?.msg || err?.message || '')
    }
    return false
  }

  const reLoginEmailVerify = async (mailCode: string, deviceId: string) => {
    try {
      // data: {"deadline": 0, "device_no": "string","encry_data": "string"}
      const { code, message, data } = await reLoginEmailVerifyApi({
        code: mailCode,
        device_no: deviceId
      })

      if (code !== 8000) {
        toast.error(message)
        return false
      }

      const {
        code: authCode,
        data: authData,
        message: authMsg
      } = await reLoginIncreaseAuthApi({ ...data })
      if (authCode !== 8000) {
        toast.error(authMsg)
        return false
      }
      const {
        code: incCode,
        data: incData,
        message: incMsg
      } = await reLoginPassIncreaseApi({ ...authData })
      if (incCode !== 8000) {
        toast.error(incMsg)
        return false
      }
      return true
    } catch (err: any) {
      // console.log('errrrrrr', err)
      toast.error(err?.response?.data?.message || err?.message || '')
    }
    return false
  }

  const sendTradePwdEmail = async () => {
    try {
      const { code, message } = await sendTradePwdEmailApi()
      if (code === 10000) {
        return true
      }
      toast.error(message)
      return code
    } catch (err: any) {
      toast.error(err?.response?.data?.msg || err?.message || '')
    }
    return false
  }

  const verifyTradeEmail = async (mailCode: string) => {
    try {
      const { code, message, data } = await verifyTradeEmailApi(mailCode)

      if (code !== 10000) {
        toast.error(message)
        return false
      }

      return true
    } catch (err: any) {
      // console.log('errrrrrr', err)
      toast.error(err?.response?.data?.message || err?.message || '')
    }
    return false
  }

  return {
    verifyBindEmailCode,
    sendBindEmailCode,
    reLoginEmailSend,
    reLoginEmailVerify,
    sendTradePwdEmail,
    verifyTradeEmail,
    verifyBindRecoverEmailCode,
    sendBindRrcoverEmailCode,
  }
}

export default useEmail
