import { defineChain } from 'viem'
import * as wagmiChains from 'wagmi/chains'
// import { mockTonChainId } from '../../config/ton'
// import { mockBtcEvmChainId } from '../../config/btc'
// import { mockSolEvmChainId } from '../../config/sol'
// import { mockTronChainId } from '../../config/tron'

export type IChainType = ReturnType<typeof defineChain>

export type IChainName<T extends keyof typeof wagmiChains> =
  (typeof wagmiChains)[T]['name']

const mockNubitChainId = 331239
export type IChainId = IChainType['id'] | typeof mockNubitChainId
// | typeof mockTonChainId
// | typeof mockBtcEvmChainId
// | typeof mockSolEvmChainId
// | typeof mockTronChainId

export type Address = `0x${string}`

export type IWeb3Type = 'EVM' | 'SOL' | 'BTC' | 'SUI' | 'ALL' | 'TON' | 'TRON' | 'TONTEST'

export enum Web3Type {
  'EVM' = 'EVM',
  'SOL' = 'SOL',
  'BTC' = 'BTC',
  'SUI' = 'SUI',
  'TON' = 'TON',
  'TRON' = 'TRON',
  'ALL' = 'ALL',
  'TONTEST' = 'TONTEST'
}

export type IWeb3NetworkType = 'main' | 'test' | 'custom'

export type IWeb3ChainType = {
  type: IWeb3Type
  icon: string
  name: string
  networkType: IWeb3NetworkType
  chain?: IChainType
  id: number;
}
