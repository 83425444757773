import { useAccount, useReadContract } from 'wagmi'
import { erc20Abi, zeroAddress } from 'viem'
import { multicall, readContracts } from '@wagmi/core'
import { signalChainConfig } from './useConfig'
import { sentryCaptureException } from '@/utils/sentry/sentryCaptureException'

export function useReadErc20Decimals({
  chainId,
  tokenAddress
}: {
  chainId: number | undefined
  tokenAddress: `0x${string}` | undefined
}) {
  const result = useReadContract({
    address: tokenAddress ?? zeroAddress,
    abi: erc20Abi,
    functionName: 'decimals',
    chainId
  })

  const { data: decimals, isError, isLoading } = result

  return { decimals, isLoading, isError, readRes: result }
}

export function useReadErc20Symbol({
  chainId,
  tokenAddress
}: {
  chainId: number | undefined
  tokenAddress: `0x${string}` | undefined
}) {
  const result = useReadContract({
    address: tokenAddress ?? zeroAddress,
    abi: erc20Abi,
    functionName: 'symbol',
    chainId
  })

  const { data: symbol, isError, isLoading } = result

  return { symbol, isLoading, isError, readRes: result }
}
export function useReadErc20Name({
  chainId,
  tokenAddress
}: {
  chainId: number | undefined
  tokenAddress: `0x${string}` | undefined
}) {
  const result = useReadContract({
    address: tokenAddress ?? zeroAddress,
    abi: erc20Abi,
    functionName: 'name',
    chainId
  })

  const { data: name, isError, isLoading } = result

  return { name, isLoading, isError, readRes: result }
}

export async function getMulticallTokenInfo({
  chainId,
  tokenAddress
}: {
  chainId: number
  tokenAddress: `0x${string}` | undefined
}) {
  const result = {
    decimals: 0,
    symbol: '',
    name: '',
    image: ''
  }
  try {
    const tokenInfos: any = [
      { address: tokenAddress, abi: erc20Abi, functionName: 'decimals' },
      { address: tokenAddress, abi: erc20Abi, functionName: 'symbol' },
      { address: tokenAddress, abi: erc20Abi, functionName: 'name' }
    ]
    const mergeList = (await multicall(signalChainConfig(chainId), {
      contracts: tokenInfos
    })) as any[]
    result.decimals =
      mergeList[0].status === 'success' ? mergeList[0].result : 0
    result.symbol = mergeList[1].status === 'success' ? mergeList[1].result : ''
    result.name = mergeList[2].status === 'success' ? mergeList[2].result : ''
  } catch (e) {
    console.log('multicall e', e)
    /**
     * if multicall failed, use readContracts
     */
    const address = tokenAddress as `0x${string}`
    return getErc20TokenInfos({ chainId, tokenAddress: address })
  }
  return result
}

export async function getErc20TokenInfos({
  chainId,
  tokenAddress
}: {
  chainId: number
  tokenAddress: `0x${string}`
}) {
  try {
    const config = signalChainConfig(chainId)
    const contracts = [
      {
        address: tokenAddress,
        abi: erc20Abi,
        functionName: 'decimals'
      },
      {
        address: tokenAddress,
        abi: erc20Abi,
        functionName: 'symbol'
      },
      {
        address: tokenAddress,
        abi: erc20Abi,
        functionName: 'name'
      }
    ]
    const res = await readContracts(config, { contracts })
    const [decimals, symbol, name] = res || []

    return {
      decimals: decimals.result || 0,
      symbol: symbol.result || '',
      name: name.result || '',
      image: ''
    }
  } catch (error) {
    sentryCaptureException(error as Error, {
      extra: {
        chainId,
        tokenAddress
      },
      tags: {
        business: 'signTransaction'
      }
    })
    return {
      decimals: 0,
      symbol: '',
      name: '',
      image: ''
    }
  }
}
