import { BackButton } from '@vkruglikov/react-telegram-web-app'
import BackupLogo from 'assets/imgs/backup_logo.png'
import useNavigateWithParams from 'hooks/useNavigateWithParams'
import { useAtomValue } from 'jotai'
import { useEffect } from 'react'
import { userStorageAtom } from 'state/storage'

const NoEmail = () => {
  const navigate = useNavigateWithParams()
  const userAtom = useAtomValue(userStorageAtom)

  useEffect(() => {
    if (userAtom?.email) {
      navigate('/')
    }
  }, [userAtom?.email])

  return (
    <>
      <BackButton onClick={() => navigate(-1)}></BackButton>
      <div className={'login-bg'}>
        <div className="relative flex size-full flex-col items-center ">
          <img src={BackupLogo} className={`mt-[60px] h-[140px] w-[121px]`} />

          <div className="z-10 mt-[40px] flex size-full flex-col">
            <div className="flex flex-col gap-[16px] px-10">
              <p className="text-center text-sm font-normal leading-[1.3] text-title/40">
                Verify identity through backup email
              </p>
              <div className="flex items-center justify-center rounded-xl bg-[#F21F7F1A] px-[12px] py-[16px]">
                <span className="block max-w-[245px] text-center text-lg font-medium leading-[20px] text-[#F21F7F]">
                  Oops! You have not linked a backup email
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NoEmail
