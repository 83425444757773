import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../type'
import * as wagmiChains from 'wagmi/chains'

const chain = wagmiChains.arbitrumSepolia
const icon = chainsSvgs.arbJpeg
const networkType: IWeb3NetworkType = 'test'

const chainInfo: IWeb3ChainType = {
  chain: chain,
  type: 'EVM',
  name: chain.name,
  icon: icon,
  networkType
}

export default chainInfo
