import { RouterProvider } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { routers } from 'router'
import { ConfigProvider } from 'antd-mobile'
import enUS from 'antd-mobile/es/locales/en-US'
import 'react-toastify/dist/ReactToastify.css'
import { WebAppProvider, useWebApp } from '@vkruglikov/react-telegram-web-app'
import { useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import Providers from './proviers'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { isTelegramReadyAtom } from 'state'
import packageJson from '../package.json'
import * as semver from 'semver'

//get env mode
function App() {
  const webapp = useWebApp()
  const queryClient = new QueryClient()
  const [isTelegramReady, setIsTelegramReady] = useAtom(isTelegramReadyAtom)

  useEffect(() => {
    const userAgent = navigator.userAgent
    const telegramVersionMatch = userAgent.match(/Telegram-Android\/([\d.]+)/)
    try {
      const versionString = telegramVersionMatch?.[1]
      if (versionString) {
        const vs = versionString?.split('.')
        // version can be 4 slots like '11.4.2.0', it's annoying
        const standardV = `${vs[0] || '0'}.${vs[1] || '0'}.${vs[2] || 0}`
        if (semver.gte(standardV, '11.4.2')) {
          console.log('expand tma on android tg 11.4.2')
          webapp.expand()
        }
      }
    } catch (e) {
      console.log('version compare error', e)
    }

    webapp.enableClosingConfirmation()
    webapp.disableVerticalSwipes()
    webapp.setHeaderColor('#ffffff')
    window.authBotVersion = packageJson.version

    const checkTelegramReady = () => {
      if (
        typeof window !== 'undefined' &&
        window?.Telegram &&
        window.Telegram.WebApp
      ) {
        window.Telegram.WebApp.ready()
        setIsTelegramReady(true)
      } else {
        setTimeout(checkTelegramReady, 200)
      }
    }

    checkTelegramReady()
  }, [])

  return (
    <Sentry.ErrorBoundary>
      <WebAppProvider
        options={{
          smoothButtonsTransition: true
        }}
      >
        {import.meta.env.VITE_CONSOLE == '1' && (
          <div
            style={{ position: 'fixed', top: 0, background: 'orange', left: 0 }}
            onClick={() => {
              window.localStorage.clear()
            }}
          >
            {packageJson.version}
          </div>
        )}

        <QueryClientProvider client={queryClient}>
          <Providers>
            <ConfigProvider locale={enUS}>
              <ToastContainer />
              <RouterProvider router={routers} />
            </ConfigProvider>
          </Providers>
        </QueryClientProvider>
      </WebAppProvider>
    </Sentry.ErrorBoundary>
  )
}

export default Sentry.withProfiler(App)
