// import { Config, getHttpEndpoint, Network } from '@orbs-network/ton-access'
import TonWeb, { AddressType } from 'tonweb'
import axios from 'axios'
import { TON_MIN_FEE_FOR_JETTON } from '@/constans'
import { TonTxParams, TonTxRequestStandard } from '@tomo-inc/tomo-telegram-sdk'
import { Cell, fromNano, toNano } from '@ton/core'

export type TonSigningTransactionType = {
  fromAddress: string
  publicKey: string
  amount: string
  toAddress: string
  memo?: string
  tokenContractAddress?: string
  tokenPrecision?: number
  forwardAmount?: string
}

export const mockTonChainId = 1100
export const mockTonTestnetChainId = 1101
export const mockTonOkxChainID = 607
export const tonDecimals = 9

const apiKey: string =
  '1b312c91c3b691255130350a49ac5a0742454725f910756aff94dfe44858388e'
const tonRpc: string = 'https://toncenter.com/api/v2/jsonRPC'
const hashHttp: string = 'https://toncenter.com/api/index/v1'
// export const tonScanUrl: string = 'https://tonviewer.com/transaction/'

export async function getTonWebProvider() {
  return new TonWeb(new TonWeb.HttpProvider(tonRpc, { apiKey: apiKey }))
}

export const getTonBalance = async (tonAddress: string) => {
  // get the decentralized RPC endpoint
  const tonWeb = await getTonWebProvider()
  const wallet = tonWeb.wallet.create({ address: tonAddress }) // if your know only address at this moment
  // const wallet = tonWeb.wallet.create({publicKey: TonWeb.utils.hexToBytes(publicKey)});

  const address = await wallet.getAddress()

  const balance = await tonWeb.getBalance(address)

  return { balance, formatted: TonWeb.utils.fromNano(balance) }
}

/**
 * build transfer signing message
 * @param transactionInfo  {publicKey: "be91c0566bed6a186780b5711529b0652e505bb3f9fc92866fb0c0f400c98dd6",amount: "0.3",toAddress: "EQC4d8D4ERsT6DH0Xzz_Ey8Yja2wXVnUtaffVOtm1htS6qG1",memo: "1111"}
 * @returns
 */
export async function createSigningTransaction(
  transactionInfo: TonSigningTransactionType
) {
  try {
    const tonWeb = await getTonWebProvider()
    const WalletClass = tonWeb.wallet.all['v4R2']
    //
    const wallet = new WalletClass(tonWeb.provider, {
      publicKey: tonWeb.utils.hexToBytes(transactionInfo.publicKey),
      wc: 0
    })
    // @ts-ignore ...
    // console.log('tonWallet ==>', window.tonWeb = tonWeb, window.tonWallet = wallet)
    //
    const seqno = (await wallet.methods.seqno().call()) || 0
    let stateInit = null
    if (seqno == 0) {
      const deploy = await wallet.createStateInit()
      stateInit = deploy.stateInit
    }

    let signingMessage
    let stateInitBoc
    let sendmode = 3
    const balance = await getTonBalance(transactionInfo.fromAddress)

    if (Number(balance.formatted) - Number(transactionInfo.amount) <= 0.01) {
      sendmode = 128
    }

    if (
      transactionInfo.tokenContractAddress &&
      transactionInfo.tokenContractAddress.trim().length > 0 &&
      transactionInfo.tokenPrecision
    ) {
      const jettonMinter = new TonWeb.token.jetton.JettonMinter(
        tonWeb.provider,
        // @ts-ignore
        { address: transactionInfo.tokenContractAddress }
      )
      //
      const walletAddress = await wallet.getAddress()
      const jettonWalletAddress =
        await jettonMinter.getJettonWalletAddress(walletAddress)
      //
      const jettonWallet = new TonWeb.token.jetton.JettonWallet(
        tonWeb.provider,
        {
          address: jettonWalletAddress.toString(true, true, false)
        }
      )
      const comment = new Uint8Array([
        ...new TextEncoder().encode(transactionInfo.memo || '0')
      ])

      //
      const tokenAmount =
        Number(transactionInfo.amount) * 10 ** transactionInfo.tokenPrecision
      const convertedAmount = TonWeb.utils.toNano(
        (tokenAmount / 10 ** 9).toString()
      )
      //
      const transferBody = await jettonWallet.createTransferBody({
        queryId: seqno,
        // @ts-ignore
        jettonAmount: convertedAmount,
        toAddress: new TonWeb.utils.Address(transactionInfo.toAddress),
        forwardPayload: comment,
        forwardAmount:
          transactionInfo.forwardAmount || TonWeb.utils.toNano('0.0001'),
        responseAddress: walletAddress
      })
      //
      const externalMessage = await wallet.createTransferMessage(
        new Uint8Array(), //
        jettonWalletAddress.toString(true, true, false),
        TonWeb.utils.toNano('' + TON_MIN_FEE_FOR_JETTON), //
        seqno,
        transferBody,
        sendmode, //3, // sendmode
        true, //
        stateInit as any
      )
      /**
       sendMode
        0: Default Send Mode
        1: Send as External Message
        2: Send as Internal Message
        3: Send as Bounceable Message
        */

      const cellBase64 = await externalMessage.signingMessage.toBoc(false)

      if (stateInit) {
        const cellBase641 = await stateInit.toBoc(false)
        stateInitBoc = tonWeb.utils.bytesToHex(cellBase641)
      }
      signingMessage = tonWeb.utils.bytesToHex(cellBase64)
      return {
        signingMessageBoc: signingMessage,
        stateInitBoc
      }
    } else {
      let toAddress: AddressType = new TonWeb.utils.Address(
        transactionInfo.toAddress
      ).toString(true, true, true)
      const info = await tonWeb.provider.getAddressInfo(
        transactionInfo.toAddress
      )
      if (info.state !== 'active') {
        toAddress = new TonWeb.utils.Address(
          transactionInfo.toAddress
        ).toString(true, true, false) // convert to non-bounce
      }
      const externalMessage = await wallet.createTransferMessage(
        new Uint8Array(),
        toAddress,
        TonWeb.utils.toNano(transactionInfo.amount),
        seqno,
        transactionInfo.memo, //
        sendmode, // 3,
        true,
        stateInit as any
      )
      const cellBase64 = await externalMessage.signingMessage.toBoc(false)
      signingMessage = tonWeb.utils.bytesToHex(cellBase64)
      const hash = await externalMessage.signingMessage.hash()
      if (stateInit) {
        const cellBase641 = await stateInit.toBoc(false)
        stateInitBoc = tonWeb.utils.bytesToHex(cellBase641)
      }
    }
    return {
      signingMessageBoc: signingMessage,
      stateInitBoc
    }
  } catch (e) {
    console.error(e)
  }
}

export async function createSigningTransactionPure(
  transactionInfo: TonTxRequestStandard
) {
  try {
    const tonWeb = await getTonWebProvider()
    const WalletClass = tonWeb.wallet.all['v4R2']
    const wallet = new WalletClass(tonWeb.provider, {
      publicKey: tonWeb.utils.hexToBytes(transactionInfo.publicKey as string),
      wc: 0
    })
    // console.log('tonWallet ==>', window.tonWeb = tonWeb, window.tonWallet = wallet)
    const seqno = (await wallet.methods.seqno().call()) || 0
    let stateInit = null
    if (seqno == 0) {
      const deploy = await wallet.createStateInit()
      stateInit = deploy.stateInit
    }

    // let signingMessage
    let stateInitBoc
    let sendmode = 3
    const balance = await getTonBalance(transactionInfo.body.from)

    const msgRaw = transactionInfo.body.messages[0]

    /** Nano Ton */
    const transferTonAmount = msgRaw.amount.toString() // no 0.05 TON fallback

    if (
      Number(balance.formatted) - Number(fromNano(transferTonAmount)) <=
      0.01
    ) {
      sendmode = 128
    }
    let toAddress: AddressType = new TonWeb.utils.Address(
      msgRaw.address
    ).toString(true, true, true)
    const info = await tonWeb.provider.getAddressInfo(toAddress)
    if (info.state !== 'active') {
      toAddress = new TonWeb.utils.Address(toAddress).toString(
        true,
        true,
        false
      ) // convert to non-bounce
    }
    const payload = msgRaw.payload
      ? tonWeb.boc.Cell.oneFromBoc(msgRaw.payload) // restore cell from hex code
      : ''
    const externalMessage = await wallet.createTransferMessage(
      new Uint8Array(),
      toAddress,
      transferTonAmount,
      seqno,
      payload, // this need to be a tonWeb.boc.Cell,
      sendmode, // 3,
      true,
      stateInit as any
    )
    const cellBase64 = await externalMessage.signingMessage.toBoc(false)
    const signingMessage = tonWeb.utils.bytesToHex(cellBase64)
    const hash = await externalMessage.signingMessage.hash()
    if (stateInit) {
      const cellBase641 = await stateInit.toBoc(false)
      stateInitBoc = tonWeb.utils.bytesToHex(cellBase641)
    }
    return {
      signingMessageBoc: signingMessage,
      stateInitBoc
    }
  } catch (e) {
    console.error(e)
  }
}

export async function sendTransaction(signedTransaction: string) {
  const tonWeb = await getTonWebProvider()

  const cell = tonWeb.boc.Cell.fromBoc(
    tonWeb.utils.base64ToBytes(signedTransaction)
  )[0]
  const msgHash = tonWeb.utils.bytesToBase64(await cell.hash())

  const result = await tonWeb.provider.sendBoc(signedTransaction)
  return { ...result, msgHash }
}

// { @extra: "1723608477.1462789:0:0.6971379973574183", @type: "query.fees", destination_fees: [], source_fees: {@type: "fees",fwd_fee: 0,gas_fee: 0,in_fwd_fee: 1006800,storage_fee:618} }
export async function sendMessageFee(
  address: string,
  signedTransaction: string
) {
  const tonWeb = await getTonWebProvider()
  return tonWeb.provider.getEstimateFee({
    address: address,
    body: signedTransaction
  })
}

export async function getTransactionsByInMessageHash(msg_hash: string) {
  const transRes = await axios.get(
    `${hashHttp}/getTransactionsByInMessageHash?msg_hash=${encodeURIComponent(
      msg_hash
    )}&include_msg_body=false&include_block=false`,
    {
      headers: {
        'X-API-Key': apiKey
      }
    }
  )
  return transRes?.data || []
}

// use the v2 api
export async function getTransactions(address: string, limit: number = 10) {
  const tonweb = await getTonWebProvider()

  return await tonweb.getTransactions(address, limit)
}

export function convertBase64ToHex(base64: string) {
  return TonWeb.utils.bytesToHex(TonWeb.utils.base64ToBytes(base64))
}
