import classNames from 'classnames'
import React, { ImgHTMLAttributes, useState } from 'react'
import Avatar from 'react-avatar'
import './BaseAvatar.css'
import { Avatar as AntdAvatar } from 'antd-mobile'

const BaseAvatar = (
  props: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > & {
    name?: string
    size?: string
    fontSize?: string
  }
) => {
  const [error, setError] = useState(false)
  const { name, onError, ...otherProps } = props

  const handleImageError = () => {
    setError(true)
  }

  if (!props.src && !name) {
    return (
      <AntdAvatar className={`size-[${props.size}px] rounded-full`} src="" />
    )
  }

  return props.src && !error ? (
    <img
      {...otherProps}
      height={props.size}
      width={props.size}
      src={props.src}
      onError={handleImageError}
      alt=""
    />
  ) : (
    <Avatar
      className={classNames(props.className, ' base-avatar')}
      size={props.size ? props.size : '28'}
      style={{
        fontSize: '16',
        ...props.style
      }}
      textSizeRatio={1}
      name={name}
    />
  )
}

export default BaseAvatar
