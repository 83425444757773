import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../type'
import * as wagmiChains from 'wagmi/chains'

const chain = undefined
const icon = chainsSvgs.btcSvg
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: chain,
  type: 'BTC',
  name: 'Bitcoin',
  icon: icon,
  networkType
}

export default chainInfo
