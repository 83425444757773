import { useAtom } from 'jotai/index'
import { deviceIdAtom } from '../state'
import { successCode, v2PkCheckApi, v2PkCreateApi, v2PkRegApi } from '../api'
import useBiometricManager from './useBiometricManager'
// import { isDevMode } from '../utils'
import { filterDeviceIDQuote, md5 } from 'utils/helper'
import { decrypt, encrypt } from 'utils'

const secretKey = 'be35a6622f254c288637b428209050c8'
export const useDeviceId = () => {
  const [deviceId, setDeviceId] = useAtom(deviceIdAtom)
  const { biometryManager } = useBiometricManager()
  const generate = () => {
    const secureRandom = () => {
      const array = new Uint8Array(1)
      window.crypto.getRandomValues(array)
      return array[0] / 256
    }

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (secureRandom() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      }
    )
  }

  const create = async (token: string = '') => {
    const id = token || generate()
    console.log('generate deviceId', id)

    try {
      //generate pubkey
      const v2PkRegRes = await v2PkRegApi(id)
      console.log('reg deviceId', id)
      if (v2PkRegRes.code != successCode) {
        throw new Error('Failed to generate pubkey')
      }
      const pubkey = v2PkRegRes.data.pubkey
      //create pubkey
      const v2PkCreateRes = await v2PkCreateApi({
        device_no: id,
        pubkey: pubkey
      })
      //check
      const checkRes = await v2PkCheckApi(id)
      if (checkRes.code != successCode) {
        throw new Error('Failed to check pubkey')
      }
      if (!checkRes.data.is_exists) {
        throw new Error('Pubkey is not existed')
      }
    } catch (err: any) {
      console.error('create passcode error', err)
      throw new Error(
        err?.response?.data?.code == 8800
          ? err?.response?.data?.code
          : err?.response?.data?.message || err.message
      )
    }

    // setDeviceId(id)
    try {
      biometryManager.updateBiometricToken('v1:' + id)
    } catch (err) {
      console.error('updateBiometricToken', err)
    }

    return id
  }

  const getDeviceIdFromToken = (token: string) => {
    if (!token) {
      return ''
    }
    if (!token.includes(':') || token.substring(0, 3) !== 'v1:') {
      return token
    }
    return token.split(':')[1]
  }

  const checkIsNewAccount = async (bioToken: string = '') => {
    const did = getDeviceIdFromToken(
      filterDeviceIDQuote(
        bioToken || localStorage.getItem('deviceId') || generate()
      )
    )
    setDeviceId(did)
    const {
      code,
      data: { count, is_exists }
    } = await v2PkCheckApi(did)
    if (code !== successCode) {
      throw new Error('Network error.')
    }

    if (count === 0) {
      return { isNew: true, isExists: is_exists, deviceId: did }
    } else {
      return { isNew: false, isExists: is_exists, deviceId: did }
    }
  }
  const generateBioKey = (uid: number | undefined | string) => {
    return `bio${md5(`${uid}`)}`
  }

  const checkIsNewByBioDisable = (uid: number | undefined) => {
    const key = generateBioKey(uid)
    const newBioMode = localStorage.getItem(key)
    const bioMode = localStorage.getItem('bioMode')

    if (newBioMode && uid && `${uid}` == decrypt(newBioMode, secretKey)) {
      // console.log('new....')
      return false
    } else if (
      bioMode &&
      uid &&
      `${uid}` == decrypt(bioMode.replace(/"/g, ''), secretKey)
    ) {
      // console.log('new2....')
      setBioByBioDisable(uid)
      localStorage.removeItem('bioMode')
      return false
    } else if (bioMode) {
      const olduid = decrypt(bioMode.replace(/"/g, ''), secretKey)
      if (olduid && /\d+/.test(olduid)) {
        // console.log('new3....')
        setBioByBioDisable(olduid)
        localStorage.removeItem('bioMode')
      }
      // console.log('new4....')
      setBioByBioDisable(uid)
      localStorage.removeItem('bioMode')
      return false
    }
    return true
  }
  const setBioByBioDisable = (uid: number | undefined | string) => {
    const key = generateBioKey(uid)
    localStorage.setItem(key, encrypt(`${uid}`, secretKey))
  }

  return {
    create,
    getDeviceIdFromToken,
    generate,
    checkIsNewAccount,
    deviceId,
    checkIsNewByBioDisable,
    setBioByBioDisable
  }
}
