import { useEffect } from 'react'
import LoadingIcon from '../components/LoadingIcon'
import { useLocation, useNavigate } from 'react-router-dom'
import useSdk from '@/hooks/useSdk'
import { getSdkHash } from '@/utils'
import { getSocialInfo } from '@/api'
import { useAtom } from 'jotai'
import { userStorageAtom } from '@/state/storage'
import useTradePwd from '@/hooks/useTradePwd'
import { UserType } from '@/hooks/useLoginInfo'

const PageLoading = () => {
  const navigator = useNavigate()
  const { getPayload } = useSdk()
  const [user, setUser] = useAtom(userStorageAtom)
  const location = useLocation()
  const { isNoPin } = location.state || { isNoPin: false }
  const [userAtom, setUserAtom] = useAtom(userStorageAtom)
  const { checkTradePwd } = useTradePwd()

  useEffect(() => {
    const init = async () => {
      if (isNoPin) {
        try {
          checkTradePwd()
            .then((result) => {
              if (!result) {
                navigator('/setPinpay')
              } else {
                setUserAtom({
                  ...(userAtom as UserType),
                  set_trade_password: true,
                  setTradePassword: true
                })
              }
            })
            .catch((e) => {
              navigator('/setPinpay')
            })
            .finally(() => {
              // setLoading(true)
            })
        } catch (e) {
          //
        }
      } else {
        const info = await getSocialInfo()
        const recoveryEmailBound = info.result?.recoveryEmailBound || false
        // judge user has recovery email
        if (recoveryEmailBound) {
          setUser({
            ...user!,
            recoveryEmailBound: true
          })
          localStorage.setItem(
            'user',
            JSON.stringify({ ...user, recoveryEmailBound: true })
          )
          navigator('/')
        } else {
          // if user has no recovery email
          const sdkHash = getSdkHash()
          const transData = sdkHash ? await getPayload() : {}
          let data = { email: '' }
          if ((transData?.data?.params || []).length) {
            data = transData?.data?.params[0] || {}
          }
          const emailFromSdk = data.email
          emailFromSdk
            ? navigator('/recovery-email', { state: { email: emailFromSdk } })
            : navigator('/recovery-email')
        }
      }
    }
    init()
  }, [isNoPin])

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        background: localStorage.theme === 'dark' ? '#12122a' : '#ffffff'
      }}
    >
      <LoadingIcon isLoading={true} size={80} textSize={20} marginTop={10} />
    </div>
  )
}

export default PageLoading
