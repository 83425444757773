import { Outlet } from 'react-router-dom'
import useLoginInfo from 'hooks/useLoginInfo'
import { useEffect, useState } from 'react'
import { SafeArea, Toast } from 'antd-mobile'
import { useInitData, BackButton } from '@vkruglikov/react-telegram-web-app'
import { classNames } from 'utils'
import { isTelegramReadyAtom } from 'state'
import { useAtom, useAtomValue } from 'jotai'

export default function RootLayout() {
  const { logout, user } = useLoginInfo()
  const [initDataUnsafe] = useInitData()
  const isTelegramReady = useAtomValue(isTelegramReadyAtom)

  useEffect(() => {
    if (
      initDataUnsafe?.user?.id &&
      user?.tgId &&
      initDataUnsafe?.user?.id != Number(user?.tgId)
    ) {
      logout()
    }
  }, [initDataUnsafe?.user?.id, user?.tgId])

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--viewport-height-intg',
      `${window.visualViewport?.height || 0}px`
    )
    function setMyViewportHeight() {
      document.documentElement.style.setProperty(
        '--viewport-height-intg',
        `${window.Telegram.WebApp.viewportHeight}px`
      )
    }
    if (isTelegramReady) {
      window.Telegram.WebApp.onEvent('viewportChanged', setMyViewportHeight)
      return window.Telegram.WebApp.offEvent(
        'viewportChanged',
        setMyViewportHeight
      )
    }
  }, [isTelegramReady])

  return (
    <>
      <main
        className={classNames(`w-full overflow-hidden`)}
        style={{ height: `var(--tg-viewport-height)` }}
      >
        <Outlet />
        {/* <SafeArea position="bottom" style={{ '--multiple': 0.5 }} /> */}
      </main>
    </>
  )
}
