import { PropsWithChildren, useEffect, useMemo } from 'react'
import { useAtom, useAtomValue } from 'jotai'
import { userStorageAtom, isInitializedAtom, loadUserAtom } from 'state/storage'
import useNavigateWithParams from 'hooks/useNavigateWithParams'
import { RouteConfig } from './routers'
import useTradePwd from 'hooks/useTradePwd'
import useSdk from '@/hooks/useSdk'
import { getSocialInfo } from '@/api'

const RequireAuth = ({
  children,
  route
}: PropsWithChildren<{ route: RouteConfig }>) => {
  const [userAtom, setUserAtom] = useAtom(userStorageAtom)
  const isInitialized = useAtomValue(isInitializedAtom)
  const [_, loadUser] = useAtom(loadUserAtom)
  const navigate = useNavigateWithParams()
  const { checkTradePwd } = useTradePwd()

  useEffect(() => {
    if (!isInitialized) {
      loadUser()
    }
  }, [isInitialized, loadUser])

  useEffect(() => {
    if (isInitialized && userAtom === null) {
      navigate('/login')
    } else if (isInitialized && userAtom && route.email && !userAtom?.email) {
      navigate('/noEmail', { replace: true })
    } else if (
      isInitialized &&
      userAtom &&
      route.pin &&
      !(userAtom?.setTradePassword || userAtom?.set_trade_password) // old use maybe need set_trade_password field to judge
    ) {
      navigate('/loading', { state: { isNoPin: true } })
    } else if (
      isInitialized &&
      userAtom &&
      route.recoverEmail &&
      !(userAtom?.email || userAtom?.recoveryEmailBound)
    ) {
      navigate('/loading')
    }
  }, [userAtom, isInitialized, navigate, route, setUserAtom])

  if (!isInitialized) {
    return null
  }

  return children
}

const GuardRoute = ({
  children,
  route
}: PropsWithChildren<{ route: RouteConfig }>) => {
  return <RequireAuth route={route}>{children}</RequireAuth>
}

export default GuardRoute
