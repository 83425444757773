import {
  Connection,
  PublicKey,
  Transaction,
  SystemProgram,
  TransactionMessage,
  TransactionInstruction,
  VersionedTransaction,
  clusterApiUrl
} from '@solana/web3.js'
import {
  createTransferInstruction,
  getAssociatedTokenAddress,
  createAssociatedTokenAccountInstruction,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  createTransferCheckedInstruction,
  Account,
  getAccount,
  TokenAccountNotFoundError,
  TokenInvalidAccountOwnerError
} from '@solana/spl-token'
import { Metaplex } from '@metaplex-foundation/js'
// import { Metadata } from '@metaplex-foundation/mpl-token-metadata'
// import base58 from 'bs58'

let connection: Connection
export const SolMainAddress = '11111111111111111111111111111111'
export const mockSolEvmChainId = 501
export const solDecimals = 9
export const solTokenName = 'SOL'
export const solEndpoint =
  'https://rpc.ankr.com/solana/ac79e83cf02a544dbb9b3f4c5d5478b2510b921e7d5739ded8791a932e8de0a6'
//   'https://go.getblock.io/4922ed815e5d4137ab3a7f87b34cbfe4'
export const solScanUrl = 'https://solscan.io/tx/'
export function getConnection() {
  if (connection) {
    return connection
  }
  // connection = new Connection(solEndpoint, 'recent')
  connection = new Connection(solEndpoint)
  return connection
}

export async function getSolFees() {
  try {
    const conn = getConnection()
    const { feeCalculator } = await conn.getRecentBlockhash()
    return { totalFee: feeCalculator.lamportsPerSignature.toString() as string }
  } catch (e) {
    return { totalFee: '0' }
  }
}

export async function sendSolTx(
  fromAddress: string,
  toAddress: string,
  amount: bigint, // bigint number
  mintAddress?: string
) {
  try {
    getConnection()
    const tx = new Transaction()
    const fromPublicKey = new PublicKey(fromAddress)
    const toPublicKey = new PublicKey(toAddress)
    if (!tx.feePayer) {
      tx.feePayer = fromPublicKey
    }
    if (mintAddress) {
      const tokenPublicKey = new PublicKey(mintAddress)
      tx.recentBlockhash = (
        await connection.getLatestBlockhash('max')
      ).blockhash
      tx.add(
        createTransferInstruction(
          tokenPublicKey,
          toPublicKey,
          fromPublicKey,
          amount
        )
      )
    } else {
      tx.recentBlockhash = (
        await connection.getLatestBlockhash('max')
      ).blockhash
      tx.add(
        SystemProgram.transfer({
          fromPubkey: fromPublicKey,
          toPubkey: toPublicKey,
          lamports: amount
        })
      )
    }
    const txHex = tx
      .serialize({ requireAllSignatures: false, verifySignatures: false })
      .toString('hex')
    return txHex
  } catch (e) {
    return null
  }
}

export const getSolTokenDetail = async (mintAddress: string) => {
  try {
    getConnection()
    const TOKEN_LIST_URL =
      'https://raw.githubusercontent.com/solana-labs/token-list/main/src/tokens/solana.tokenlist.json'
    const response = await fetch(TOKEN_LIST_URL)
    const tokenList = await response.json()
    const tokenDetail = tokenList.tokens.find(
      (token: any) => token.address === mintAddress
    )
    if (tokenDetail) {
      console.log('Token Symbol:', tokenDetail.symbol)
      console.log('Token Name:', tokenDetail.name)
      console.log('Token Decimals:', tokenDetail.decimals)
      return {
        symbol: tokenDetail.symbol,
        decimals: tokenDetail.decimals
      }
    } else {
      console.log('Token not found in Token List')
      return null
    }
  } catch (e) {
    console.log(e)
    return null
  }
}

async function getTokenAmount(client: any, mint: any, owner: any) {
  const tokenAccount = await getAssociatedTokenAddress(mint, owner)

  const info = await client.getAccountInfo(tokenAccount)
  if (info == null) {
    return null
  } else {
    return await client.getTokenAccountBalance(tokenAccount)
  }
}
export async function getSolTokenAccount(client: any, mint: any, owner: any) {
  const tokenAccount = await getAssociatedTokenAddress(mint, owner)

  return await client.getAccountInfo(tokenAccount)
}
export const getSolBalance = async ({
  address,
  token
}: {
  address: string | undefined
  token: string | undefined
}) => {
  if (address) {
    if (token) {
      const connection = getConnection()
      const owner = new PublicKey(address)
      const mint = new PublicKey(token)
      const metaplex = Metaplex.make(connection)
      const metadataPda = metaplex.nfts().pdas().metadata({ mint: mint })

      const tokenAccount = await getTokenAmount(connection, mint, owner)

      if (tokenAccount == null) {
        return {
          amount: 0n,
          token,
          format: '0',
          decimals: undefined
        }
      } else {
        return {
          amount: BigInt(tokenAccount.value.amount) || 0n,
          format: tokenAccount.value.uiAmountString,
          decimals: tokenAccount.value.decimals,
          token
        }
      }
    } else {
      const connection = getConnection()
      const publicKey = new PublicKey(address)
      const balance = await connection.getBalance(publicKey)
      if (balance) {
        return BigInt(balance)
      }
    }
  }
  return undefined
}

function getInstructions(data: any) {
  const instruction = new TransactionInstruction({
    programId: new PublicKey(data.programId),
    data: Buffer.from(data.data),
    keys: []
  })
  for (let j = 0; j < data.keys.length; j++) {
    instruction.keys.push({
      pubkey: new PublicKey(data.keys[j].pubkey),
      isSigner: data.keys[j].isSigner,
      isWritable: data.keys[j].isWritable
    })
  }
  return instruction
}

export const ToSerializeTransaction = async (data: any) => {
  const connection = getConnection()
  // const recentBlockhash = await connection.getLatestBlockhash()

  const txMsg = new TransactionMessage({
    recentBlockhash: data.tx.recentBlockhash,
    payerKey: new PublicKey(data.tx.from),
    instructions: []
  })

  for (let i = 0; i < data.tx.instructions.length; i++) {
    txMsg.instructions.push(getInstructions(data.tx.instructions[i]))
  }

  if (data.tx && data.tx.txType == 'LEGACY') {
    const tx = Transaction.populate(txMsg.compileToLegacyMessage())

    data.tx.signatures.forEach((signature: any) => {
      tx.addSignature(
        new PublicKey(signature.publicKey),
        Buffer.from(signature.signature)
      )
    })
    // tx.message.recentBlockhash = recentBlockhash
    return Buffer.from(tx.serialize({ requireAllSignatures: false })).toString(
      'hex'
    )
  } else if (data.tx && data.tx.txType == 'VERSIONED') {
    const tx = VersionedTransaction.deserialize(data.tx.serializedMessage)
    // const tx = new VersionedTransaction(data.tx.serializedMessage)
    // data.tx.signatures.forEach(signature => {
    //     tx.addSignature(new PublicKey(signature.publicKey), Buffer.from(signature.signature))
    // });
    // tx.feePayer = new PublicKey(data.tx.from)
    console.log(tx)

    return Buffer.from(tx.serialize()).toString('hex')
  }
}

export async function getSendSplToken(
  mint: string | undefined,
  from: string | undefined,
  to: string | undefined,
  amount: bigint | undefined
) {
  const connection = getConnection()

  const mintPublicKey = mint && new PublicKey(mint)
  const fromPublicKey = from && new PublicKey(from)
  const toPublicKey = to && new PublicKey(to)

  if (mintPublicKey && fromPublicKey && toPublicKey && amount) {
    const fromATA = await getAssociatedTokenAddress(
      mintPublicKey,
      fromPublicKey
    )

    const fromInfo = await connection.getAccountInfo(fromATA)

    if (fromInfo == null) {
      console.warn('from not token accmount')
      return null
    }
    const fromTokenAccount = await connection.getTokenAccountBalance(fromATA)
    if (fromTokenAccount.value.amount < amount) {
      return null
    }

    const transaction = new TransactionMessage({
      payerKey: fromPublicKey,
      recentBlockhash: (await connection.getLatestBlockhash()).blockhash,
      instructions: []
    })

    const toATA = await getAssociatedTokenAddress(mintPublicKey, toPublicKey)
    const toInfo = await connection.getAccountInfo(toATA)

    if (toInfo == null) {
      transaction.instructions.push(
        createAssociatedTokenAccountInstruction(
          fromPublicKey,
          toATA,
          toPublicKey,
          mintPublicKey,
          TOKEN_PROGRAM_ID,
          ASSOCIATED_TOKEN_PROGRAM_ID
        )
      )
    }

    transaction.instructions.push(
      createTransferCheckedInstruction(
        fromATA,
        mintPublicKey,
        toATA,
        fromPublicKey,
        amount,
        fromTokenAccount.value.decimals,
        [],
        TOKEN_PROGRAM_ID
      )
    )

    const versionedTransaction = new VersionedTransaction(
      transaction.compileToV0Message()
    )

    // return base58.encode(versionedTransaction.serialize())
    return Buffer.from(versionedTransaction.serialize()).toString('hex')
  }

  return undefined
}

export async function getSolTokenTx(
  fromAddress: string,
  toAddress: string,
  amount: number,
  mintAddress?: string
) {
  try {
    getConnection()
    const tx = new Transaction()
    const fromPublicKey = new PublicKey(fromAddress)
    const toPublicKey = new PublicKey(toAddress)
    if (!tx.feePayer) {
      tx.feePayer = fromPublicKey
    }
    if (mintAddress) {
      const tokenPublicKey = new PublicKey(mintAddress)
      tx.recentBlockhash = (
        await connection.getLatestBlockhash('max')
      ).blockhash

      const fromTokenPubKey = await getAssociatedTokenAddress(
        tokenPublicKey,
        new PublicKey(fromAddress)
      )
      const toTokenPubKey = await getAssociatedTokenAddress(
        tokenPublicKey,
        new PublicKey(toAddress)
      )

      let account: Account
      try {
        account = await getAccount(connection, toTokenPubKey)
      } catch (error: unknown) {
        if (
          error instanceof TokenAccountNotFoundError ||
          error instanceof TokenInvalidAccountOwnerError
        ) {
          try {
            tx.add(
              createAssociatedTokenAccountInstruction(
                fromPublicKey,
                toTokenPubKey,
                toPublicKey,
                tokenPublicKey
              )
            )
          } catch (error: unknown) {
            // Ignore all errors
          }
        } else {
          throw error
        }
      }

      tx.add(
        createTransferInstruction(
          fromTokenPubKey,
          toTokenPubKey,
          fromPublicKey,
          amount
        )
      )
    } else {
      tx.recentBlockhash = (
        await connection.getLatestBlockhash('max')
      ).blockhash
      tx.add(
        SystemProgram.transfer({
          fromPubkey: fromPublicKey,
          toPubkey: toPublicKey,
          lamports: amount
        })
      )
    }
    const txHex = tx
      .serialize({ requireAllSignatures: false, verifySignatures: false })
      .toString('hex')
    return txHex
  } catch (e) {
    return null
  }
}
