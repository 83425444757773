import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useState } from 'react'
import { copySvg, copyThemeSvg } from 'assets'

export default function Copy({
  height = '17.5px',
  width = '17.5px',
  text,
  icon = 'default',
  charactersCopy
}: {
  height?: string
  width?: string
  text: string | undefined
  icon?: 'default' | 'theme' | React.ReactNode
  charactersCopy?: string
}) {
  const [copied, setCopied] = useState(false)

  const onCopy = () => {
    setCopied(true)
    setTimeout(() => setCopied(false), 1500)
  }

  return (
    <CopyToClipboard text={text || ''} onCopy={onCopy}>
      <div>
        <>
          {icon === 'default' && (
            <img
              src={copySvg}
              className="cursor-pointer"
              style={{
                height,
                width,
                maxWidth: width
              }}
            />
          )}
          {icon === 'theme' && (
            <img
              src={copyThemeSvg}
              className="cursor-pointer"
              style={{
                height,
                width,
                maxWidth: width
              }}
            />
          )}
          {charactersCopy && <div>{charactersCopy}</div>}
          {icon !== 'default' && icon !== 'theme' && icon !== 'none' && icon}
        </>
      </div>
    </CopyToClipboard>
  )
}
