import { errorBoundarySvg } from 'assets'
import usePopup from 'hooks/usePopup'
import { useState } from 'react'
import * as Sentry from '@sentry/react'

type ErrorBoundaryProps = {
  error: unknown
  resetError: () => void
}

const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  const [resetFn, setresetFn] = useState<Function>()

  const { setOpen, component } = usePopup({
    title: ' ',
    fullscreen: true,
    showCloseButton: true,
    onClose: () => resetFn?.(),
    content: (
      <>
        <div className="flex flex-col items-center px-[20px]">
          <img src={errorBoundarySvg} alt="" className="mt-[122px]" />
          <div className="mt-[24px] text-xl font-[700] leading-[20.8px]">
            Error network
          </div>
          <div
            style={{
              color: '#666'
            }}
            className="mt-[10px] flex text-center"
          >
            Your network is experiencing some problems, please check and try
            refreshing
          </div>
          <button
            onClick={() => resetFn?.()}
            className="mt-[24px] h-[48px] w-[240px] rounded-[8px] bg-[#F21F7F] px-[16px] py-[12px] text-xl font-[500] text-white"
          >
            Refresh
          </button>
        </div>
      </>
    )
  })

  const ErrorFallback = ({ error, resetError }: ErrorBoundaryProps) => {
    if (error) {
      setOpen(true)
      setresetFn(() => resetError)
    }
    return component
  }

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default ErrorBoundary
