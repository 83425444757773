import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../type'

const icon = chainsSvgs.suiSvg
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: {
    id: 784,
    name: 'SUI',
    nativeCurrency: { decimals: 9, name: 'SUI', symbol: 'SUI' }
  },
  id: 784,
  type: 'SUI',
  name: 'SUI',
  icon: icon,
  networkType
}

export default chainInfo
