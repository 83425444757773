import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../type'
import * as wagmiChains from 'wagmi/chains'

const chain = undefined
const icon = chainsSvgs.solSvg
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: chain,
  type: 'SOL',
  name: 'Solana',
  icon: icon,
  networkType
}

export default chainInfo
