import { Space } from 'antd-mobile'
import { logoSvg } from 'assets'
import classNames from 'classnames'
// import { Button } from 'components/Button'
import Container from 'components/Container'
import PasscodeInput, { MyPasscodeInputRef } from 'components/PasscodeInput'
import useNavigateWithParams from 'hooks/useNavigateWithParams'
import { useAtomValue } from 'jotai'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
// import { userAttemptsAtomFamily } from 'state/family'
import { userStorageAtom } from 'state/storage'
import 'assets/styles/pass.css'

export type PayPinType = 'reset' | 'confirm' | 'set' | 'change'
export type PayPinBaseRefType = {
  handleInit: () => void
}
export type PayPinBaseType = {
  from: PayPinType
  isError?: boolean
  errMsg?: string
  onConfirm: (last: string, first?: string) => void
  // btnStatus?: ButtonStatusType
  failedCnt?: number
  // step?: number
  title: string
  // children?: React.ReactNode
  // popup?: boolean
}

const SET_TIP = 'Enter a 6 digit Pay PIN to securely create your Tomo Wallet'
const CONFIRM_TIP = 'Re-enter the Pay PIN to confirm.'

const PaypinBase = forwardRef<PayPinBaseRefType, PayPinBaseType>(
  (
    {
      from,
      isError = false,
      errMsg,
      onConfirm,
      // btnStatus,
      failedCnt = 0,
      // step = 1,
      title
      // children,
      // popup
    },
    ref
  ) => {
    const [pass, setPass] = useState('')
    // const [error, setError] = useState(false)
    const passcodeRef = useRef<MyPasscodeInputRef | null>(null)
    // const keyboardRef = useRef<HTMLParagraphElement | null>(null)
    // const [keyboardOpen, setKeyboardOpen] = useState(true)
    // const [isDisableButton, setIsDisableButton] = useState(true)
    const length = 6
    const user = useAtomValue(userStorageAtom)
    // const attempts = useAtomValue(userAttemptsAtomFamily(user?.id || 0))
    const navigate = useNavigateWithParams()

    // const title = useMemo(() => {
    //   switch (from) {
    //     case 'confirm':
    //       return 'Verify'
    //     case 'set':
    //       return step == 1 ? 'Set' : 'Confirm'
    //     case 'reset':
    //       return step == 1 ? 'Reset' : 'Confirm'
    //     case 'change':
    //       return step == 1 ? 'Original' : 'Confirm'
    //   }
    // }, [from, step])

    // const isAutoFill = useMemo(() => {
    //   return ['set', 'change', 'reset'].includes(from)
    // }, [from])

    useImperativeHandle(ref, () => ({
      handleInit: () => {
        handleInit()
      }
    }))

    const handleConfirm = async () => {
      onConfirm(pass.slice(0, length))
    }

    const handleInit = () => {
      // setOld('')
      setPass('')
      // setStep(1)
      // setError(false)
      passcodeRef.current?.reset()
      // setIsDisableButton(true)
    }

    // const handleFocus = () => {
    // setKeyboardOpen(true)
    // if (
    //   window.visualViewport?.height &&
    //   window.visualViewport?.height - 300 <= 250
    // ) {
    //   keyboardRef.current?.scrollIntoView()
    // }
    // }

    const handleFill = (value: string) => {
      if (value.length == length) {
        handleConfirm()
      }
    }

    const [small, setSmall] = useState(false)
    // useEffect(() => {
    // setSmall(window.innerHeight < 42 + 160 + 295 + 20)
    // }, [])

    // console.log('isErrorisError', isError)

    const desc = useMemo(() => {
      switch (title.toLowerCase()) {
        case 'set':
          return SET_TIP
        case 'confirm':
          return CONFIRM_TIP
        default:
          return ''
      }
    }, [title])

    return (
      <>
        <h3 className="mt-[4px] flex w-full items-center justify-between text-[20px] font-bold leading-[1.3] text-title">
          <p className="flex flex-1 items-center">
            {title}
            <img src={logoSvg} className="mx-[3px] size-[24px]" alt="" />
            Pay PIN
          </p>
          {from == 'confirm' && failedCnt >= 2 && (
            <span
              className="flex-none cursor-pointer text-lg font-normal leading-[20px] text-[#0D0E0E]"
              onClick={() => {
                navigate(user?.email ? '/forget' : '/noEmail')
              }}
            >
              Forgot PIN?
            </span>
          )}
        </h3>
        <span className='w-full text-left text-xl text-[#12121280]'>
          {desc}
        </span>
        <Space
          direction="vertical"
          block
          // className="mb-[90px] pt-[24px]"
          className={classNames(!small && 'pt-[14px]', 'pass')}
          style={{ '--gap': '20px' }}
        >
          <PasscodeInput
            plain={from == 'confirm' ? false : true}
            seperated={true}
            caret={false}
            value={pass}
            setValue={setPass}
            onFill={handleFill}
            // onShow={() => setKeyboardOpen(true)}
            // onHide={() => setKeyboardOpen(false)}
            // onFocus={handleFocus}
            // onChange={(value) => {
            //   setIsDisableButton(value.length < length)
            // }}
            error={isError}
            length={length}
            style={{ '--cell-gap': '14px', '--cell-size': '48px' }}
            ref={passcodeRef}
            className="flex w-full items-center justify-center"
          />
          {/* <p ref={keyboardRef}></p> */}
          {isError && (
            <div className="flex items-center justify-between text-df leading-[18px]">
              <p className="text-[#F21F7F]">{errMsg}</p>
            </div>
          )}
        </Space>
      </>
    )
  }
)

PaypinBase.displayName = 'PayPinBase'

export default PaypinBase
