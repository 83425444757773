import { hashWithWebCrypto } from 'utils'
import PinPayBase, { PayPinBaseRefType } from './Base'
import { mfaAuthVerificationApiV2 } from 'api'
import { useRef, useState } from 'react'
import Container from 'components/Container'
import { Toast } from 'antd-mobile'
import { buildErrMsg } from 'utils/paypin'

const PinPayVerify = ({ onSuccess }: { onSuccess: (mfa: string) => void }) => {
  const title = 'Verify'
  const [isError, setIsError] = useState(false)
  const [failedCnt, setFailedCnt] = useState(0)
  const [errMsg, setErrMsg] = useState('')
  const paypinRef = useRef<PayPinBaseRefType | null>(null)

  const onConfirm = async (password: string, first?: string) => {
    // verify logic
    if (!password) {
      setIsError(true)
      setFailedCnt(0)
      setErrMsg(buildErrMsg(0))
      paypinRef.current?.handleInit()
    }

    try {
      Toast.show({
        icon: 'loading',
        duration: 0,
        maskClickable: false
      })

      const tokenRes = await mfaAuthVerificationApiV2({
        mfaType: 2,
        tradePassword: await hashWithWebCrypto(password)
      })

      if (tokenRes && tokenRes.code == 10000 && tokenRes.result?.validateFlag) {
        setFailedCnt(0)
        setIsError(false)
        onSuccess && onSuccess(tokenRes.result?.mfaToken)
        paypinRef.current?.handleInit()
      } else if (tokenRes && tokenRes.code == 20000) {
        setFailedCnt(tokenRes.result?.count || 0)
        setIsError(true)
        setErrMsg(tokenRes?.result?.prompt || tokenRes?.message)
        paypinRef.current?.handleInit()
      } else {
        setFailedCnt(tokenRes.result?.count || 0)
        setIsError(true)
        setErrMsg(buildErrMsg(tokenRes.result?.count || 0))
        paypinRef.current?.handleInit()
      }
    } catch (err) {
      setIsError(true)
      setErrMsg(buildErrMsg(0))
      paypinRef.current?.handleInit()
    } finally {
      Toast.clear()
    }
  }

  return (
    <Container className="h-full !rounded-none">
      <PinPayBase
        from="confirm"
        onConfirm={onConfirm}
        isError={isError}
        errMsg={errMsg}
        title={title}
        failedCnt={failedCnt}
        ref={paypinRef}
      />
    </Container>
  )
}

export default PinPayVerify
