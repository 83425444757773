import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../type'
import * as wagmiChains from 'wagmi/chains'

const chain = wagmiChains.optimism
const icon = chainsSvgs.opSvg
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: {
    ...chain,
    rpcUrls: {
      default: {
        http: [
          'https://rpc.ankr.com/optimism/c626b2acc7d5145c9b392e8aff82409e2c61444b24a36be4cceed74f29eedaba'
        ]
      }
    }
  },
  //
  type: 'EVM',
  name: chain.name,
  icon: icon,
  networkType
}

export default chainInfo
