import { UserType } from 'hooks/useLoginInfo'
import { atom } from 'jotai'
import { atomWithStorage, createJSONStorage } from 'jotai/utils'

export const userStorageAtom = atomWithStorage<UserType | null>('user', null)

export const isInitializedAtom = atom(false)

export const loadUserAtom = atom(null, (get, set) => {
  const storedUser = localStorage.getItem('user')
  set(userStorageAtom, storedUser ? JSON.parse(storedUser) : null)
  set(isInitializedAtom, true)
})
