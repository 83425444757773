import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../type'

const icon = chainsSvgs.tronChainSvg
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: undefined,
  type: 'TRON',
  name: 'TRON',
  icon: icon,
  networkType
}

export default chainInfo
