import { createConfig, http } from 'wagmi'
import useChains, { evmAllChains } from './useChains'
// import { IWeb3ChainType } from '../proviers/web3Provider/type'
import { sepolia, mainnet } from 'viem/chains'

const changeEvmChains = () => {
  const evmChains = evmAllChains()
  const _evmChains = evmChains.map((m) => {
    if (m.id == mainnet.id) {
      return {
        ...m,
        rpcUrls: {
          default: {
            http: ['https://rpc.ankr.com/eth']
          }
        }
      }
    }

    if (m.id === sepolia.id) {
      return {
        ...m,
        rpcUrls: {
          default: {
            http: [
              // 'https://sepolia.infura.io/v3/ab1f7a246dc3464bb23bc7364b759215'
              'https://ethereum-sepolia-rpc.publicnode.com'
            ]
          }
        }
      }
    }

    return m
  })
  return _evmChains
}

export const signalChainConfig = (chainId: number) => {
  const _evmChains = changeEvmChains()
  const findChains = _evmChains.filter((i) => i.id === chainId)
  return createConfig({
    chains: findChains,
    transports: {
      [chainId]: http()
    }
  })
}

const useConfig = () => {
  const { evmChains } = useChains()
  const _evmChains = evmChains.map((m) => {
    if (m.id == mainnet.id) {
      return {
        ...m,
        rpcUrls: {
          default: {
            http: ['https://rpc.ankr.com/eth']
          }
        }
      }
    }

    if (m.id === sepolia.id) {
      return {
        ...m,
        rpcUrls: {
          default: {
            http: [
              // 'https://sepolia.infura.io/v3/ab1f7a246dc3464bb23bc7364b759215'
              'https://ethereum-sepolia-rpc.publicnode.com'
            ]
          }
        }
      }
    }

    return m
  })
  const wagmiConfig = createConfig({
    chains: _evmChains.map((item) => item) as any,
    transports: Object.fromEntries(
      _evmChains
        .map((chain) => {
          return chain?.id
        })
        .map((key) => [key, http()])
    )
  })
  // const wagmiConfig = createConfig({
  //   chains: [sepolia, mainnet],
  //   transports: {
  //     [mainnet.id]: http(),
  //     [sepolia.id]: http()
  //   }
  // })
  // const showBtc = true
  // const showSolana = true
  // const showSui = false
  // const showTon = true
  // const filterChains: (chainList: IWeb3ChainType[]) => IWeb3ChainType[] = (
  //   chainList: IWeb3ChainType[]
  // ) => {
  //   return chainList?.filter((chain) => {
  //     if (chain.type === 'SOL') {
  //       return showSolana
  //     }
  //     if (chain.type === 'BTC') {
  //       return showBtc
  //     }
  //     if (chain.type === 'SUI') {
  //       return showSui
  //     }
  //     if (chain.type === 'TON') {
  //       return showTon
  //     }
  //     return true
  //   })
  // }
  // const signalChainConfig = (chainId: number) => {
  //   const findChains = _evmChains.filter((i) => i.id === chainId)
  //   return createConfig({
  //     chains: findChains,
  //     transports: {
  //       [chainId]: http()
  //     }
  //   })
  // }
  return {
    // showBtc: showBtc,
    // showSolana: showSolana,
    // showSui: showSui,
    // showTon,
    config: wagmiConfig
    // filterChains,
    // signalChainConfig
  }
}

export default useConfig
