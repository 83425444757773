import arbitrum from 'proviers/web3Provider/chains/arbitrum'
import arbitrumSepolia from 'proviers/web3Provider/chains/arbitrumSepolia'
import avalanche from 'proviers/web3Provider/chains/avalanche'
import base from 'proviers/web3Provider/chains/base'
import baseSepolia from 'proviers/web3Provider/chains/baseSepolia'
import blast from 'proviers/web3Provider/chains/blast'
import blastSepolia from 'proviers/web3Provider/chains/blastSepolia'
import bsc from 'proviers/web3Provider/chains/bsc'
import bscTestnet from 'proviers/web3Provider/chains/bscTestnet'
import ethereum from 'proviers/web3Provider/chains/ethereum'
import linea from 'proviers/web3Provider/chains/linea'
import lineaSepolia from 'proviers/web3Provider/chains/lineaSepolia'
import mantle from 'proviers/web3Provider/chains/mantle'
import mantleSepoliaTestnet from 'proviers/web3Provider/chains/mantleSepoliaTestnet'
import optimism from 'proviers/web3Provider/chains/optimism'
import optimismSepolia from 'proviers/web3Provider/chains/optimismSepolia'
import polygon from 'proviers/web3Provider/chains/polygon'
import sepolia from 'proviers/web3Provider/chains/sepolia'
import solana from 'proviers/web3Provider/chains/solana'
import allChain from 'proviers/web3Provider/chains/allChain'
import btc from 'proviers/web3Provider/chains/btc'
import ton from 'proviers/web3Provider/chains/ton'
import tonTestnet from 'proviers/web3Provider/chains/tonTestnet'
import tron from 'proviers/web3Provider/chains/tron'
import scrollChain from './scrollChain'
import b3 from './b3'
import nubitSepolia from './nubitSepolia'
import zksync from 'proviers/web3Provider/chains/zksync'
import duckChainTestnet from 'proviers/web3Provider/chains/duckChainTestnet'
import duckChainMainnet from 'proviers/web3Provider/chains/duckChainMainnet'
import neoX from '@/proviers/web3Provider/chains/neox'
import merlinMainnet from '@/proviers/web3Provider/chains/merlinMainnet'
import merlinTestnet from '@/proviers/web3Provider/chains/merlinTestnet'
import aiLayerMainnet from '@/proviers/web3Provider/chains/aiLayerMainnet'
import aiLayerTestnet from '@/proviers/web3Provider/chains/aiLayerTestnet'
import bitLayerMainnet from '@/proviers/web3Provider/chains/bitLayerMainnet'
import bitLayerTestnet from '@/proviers/web3Provider/chains/bitLayerTestnet'
import storyNetworkTestnet from './storyNetworkTestnet'
import berachain_bArtioTestnet from './berachain_bArtioTestnet'
import botanixTestnet from './botanixTestnet'
import sui from '@/proviers/web3Provider/chains/sui'
import worldchain from '@/proviers/web3Provider/chains/worldChain'
import gravity from '@/proviers/web3Provider/chains/gravity'
import opbnb from 'proviers/web3Provider/chains/opbnb'

/**@deprecated  */
export const devChains = [
  sepolia,
  bscTestnet,
  arbitrumSepolia,
  optimismSepolia,
  baseSepolia,
  lineaSepolia,
  blastSepolia,
  // polygon
  // avalanche
  mantleSepoliaTestnet,
  merlinTestnet,
  aiLayerTestnet,
  tonTestnet,
]

export const prodChains = [
  allChain,
  btc,
  ethereum,
  bsc,
  solana,
  arbitrum,
  optimism,
  base,
  linea,
  blast,
  polygon,
  avalanche,
  mantleSepoliaTestnet,
  ton,
  tonTestnet,
  tron,
  sepolia,
  optimismSepolia,
  nubitSepolia,
  scrollChain,
  b3,
  zksync,
  duckChainTestnet,
  duckChainMainnet,
  neoX,
  merlinTestnet,
  merlinMainnet,
  aiLayerMainnet,
  aiLayerTestnet,
  bitLayerMainnet,
  bitLayerTestnet,
  storyNetworkTestnet,
  berachain_bArtioTestnet,
  botanixTestnet,
  sui,
  worldchain,
  gravity,
  opbnb
]

export {
  allChain,
  arbitrum,
  arbitrumSepolia,
  avalanche,
  base,
  baseSepolia,
  blast,
  blastSepolia,
  bsc,
  bscTestnet,
  ethereum,
  linea,
  lineaSepolia,
  mantle,
  mantleSepoliaTestnet,
  optimism,
  optimismSepolia,
  polygon,
  sepolia,
  solana,
  btc,
  scrollChain,
  b3,
  tron,
  ton,
  tonTestnet,
  nubitSepolia,
  zksync,
  duckChainTestnet,
  duckChainMainnet,
  neoX,
  merlinTestnet,
  merlinMainnet,
  aiLayerMainnet,
  aiLayerTestnet,
  bitLayerMainnet,
  bitLayerTestnet,
  sui,
  worldchain,
  gravity,
  opbnb
}
