import { Button } from 'components/Button'
import useLoginInfo, { TargetType, TonProofParams } from 'hooks/useLoginInfo'
import { connectSvg, logoSvg, oauthLogoSvg } from 'assets'
import { useWebApp } from '@vkruglikov/react-telegram-web-app'
import useApp from 'hooks/useApp'
import { SafeArea } from 'antd-mobile'
import useSdk from 'hooks/useSdk'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import BaseAvatar from 'components/BaseAvatar'

export default function RequestAccounts() {
  const { login } = useLoginInfo()
  const webApp = useWebApp()
  const { webAppReject } = useApp()
  const { getPayload } = useSdk()

  const { data: transData, isLoading } = useQuery({
    queryKey: ['request-account'],
    queryFn: async () => {
      return await getPayload()
    }
  })

  const transfer = useMemo(() => {
    const metaData = transData?.data?.options?.metaData || {}
    if ((transData?.data?.params || []).length) {
      const data = transData?.data?.params[0] || {}
      return { ...data, ...metaData }
    }
    return { ...metaData }
  }, [transData?.data?.options?.metaData, transData?.data?.params])
  const dappName = useMemo(() => {
    return transfer?.name || 'Dapp'
  }, [transfer?.name])

  console.log('transfer?.isNew', transfer?.isNew, transfer)

  const handleLogin = async () => {
    let params: TonProofParams | { isNew: boolean } = { isNew: !!transfer?.isNew }
    let target: TargetType = 'connect'
    if (transfer.tonProof && transfer.domain) {
      params = {
        payload: transfer.tonProof,
        domain: transfer.domain,
        workChain: transfer.workChain ?? 0
      }
      target = 'tonProof'
    }
    await login({
      target,
      success: () => {
        webApp.close()
      },
      params
    })
  }

  return (
    <>
      <div className="relative flex size-full flex-col items-center justify-between">
        <div className="mt-[90px] flex-1 p-[16px]">
          <div className="flex items-center justify-center p-[16px]">
            {transfer.icon ? (
              <img src={transfer.icon} className={`size-[56px]`} />
            ) : (
              <BaseAvatar
                name={dappName}
                size="56px"
                fontSize="56px"
                className="rounded-full"
              />
            )}

            <img src={connectSvg} className="ml-[24px] size-[22px]" />
            <img src={logoSvg} className={`ml-[24px] size-[56px]`} />
          </div>
          <p className="m-auto mt-[16px] w-full max-w-[268px] text-center text-xl font-medium leading-[21px] text-gray-1000">
            {dappName} is requesting to connect to your Tomo Wallet.
          </p>
          {/* <Link to={'/noEmail'}>forget</Link> */}
        </div>

        <div className="w-full flex-none px-[16px] py-[20px]">
          <div className={`grid grid-cols-2 gap-5 px-[20px] py-[16px]`}>
            <Button
              size="large"
              block
              onClick={() => webAppReject(false)}
              theme="ghost"
            >
              Reject
            </Button>
            <Button size="large" block onClick={handleLogin}>
              Approve
            </Button>
          </div>
          <SafeArea position="bottom" style={{ '--multiple': 0.5 }} />
        </div>
      </div>
    </>
  )
}
