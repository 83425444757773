// import { useMemo } from 'react'
// import { useInitData } from '@vkruglikov/react-telegram-web-app'
// import { decodeTelegramUrlParameters } from 'utils/sdk'
// import OauthComponent from './components/Oauth'
import RequestAccounts from './components/RequestAccounts'
import SwitchEthereumChain from './components/SwitchEthereumChain'
import AddEthereumChain from './components/AddEthereumChain'
import AddSeed from './components/AddSeed'
import PersonalSign from './components/PersonalSign'
import SignTypedData from './components/SignTypedData'
import SignTypedDataV3 from './components/SignTypedDataV3'
import SignTypedDataV4 from './components/SignTypedDataV4'
import SendTransaction from './components/SendTransaction'
import SignTransaction from './components/SignTransaction/index'
import useApp from 'hooks/useApp'
import { getMethodInfo } from '@/utils/message'
import ConnectUI from './ui/components/ConnectUI'
import SignTransactionUI from './ui/components/SignTransactionUI'
import SignTonTransaction from './ui/components/SignTonTransaction'
import SignSolanaTransaction from './ui/components/SignSolanaTransaction'
import SignMessageUI from './ui/components/SignMessageUI'
import SignSuiTransaction from './ui/components/SignSuiTransaction'
import VerifyModal from './components/pinpay/VerifyModal'
import NotFound from './components/NotFound'
import { BackButton } from '@vkruglikov/react-telegram-web-app'
import { useAtom, useAtomValue } from 'jotai'
import { verifyPinpayAtom } from 'state'
import SignTonTx from './ui/components/SignTonTx'

export default function Oauth() {
  const { actions, webAppReject } = useApp()
  const [popupState, setPopupState] = useAtom(verifyPinpayAtom)
  const [chainType, operation] = getMethodInfo(actions.method || '')

  const renderPageContent = () => {
    switch (actions.method) {
      case 'eth_requestAccounts':
        return <RequestAccounts />
      case 'wallet_switchEthereumChain':
        return <SwitchEthereumChain />
      case 'wallet_addEthereumChain':
        return <AddEthereumChain />
      case 'wallet_addSeed':
        return <AddSeed />
      case 'personal_sign':
        return <PersonalSign />
      case 'eth_signTypedData':
        return <SignTypedData />
      case 'eth_signTypedData_v3':
        return <SignTypedDataV3 />
      case 'eth_signTypedData_v4':
        return <SignTypedDataV4 />
      case 'eth_sendTransaction':
        return <SendTransaction />
      case 'eth_signTransaction':
      case 'eth_signETHTransaction':
      case 'eth_signErc20Transaction':
        return <SignTransaction />
      // case 'wallet_watchAsset':
      //   return <OauthComponent />
      // case 'metamask_watchAsset':
      //   return <OauthComponent />
      // case 'eth_accounts':
      //   return <EthAccounts />
      // case 'eth_chainId':
      //   return (
      //     <OauthComponent
      //       onDataChange={handleDataChange}
      //       onPasswordValidated={handlePasswordValidated}
      //     />
      //   )
      case 'ton_signTx':
        return <SignTonTx />
      case 'ton_signTransaction':
        return <SignTonTransaction />
      case 'sol_signTx': // new sign tx, accepting transaction as param, for sdk at and after 1.0.14
        return <SignSolanaTransaction />
      case 'sui_signTransaction':
        return <SignSuiTransaction />
      default: // currently used for sol and ton
        if (operation === 'connectWallet') {
          return <RequestAccounts />
        } else if (
          // sol_transaction & sol_transfer, sol_transaction is for sdk before 1.0.14
          operation === 'signTransaction' ||
          operation === 'transfer'
        ) {
          return <SignTransactionUI chainType={chainType as any} />
        } else if (operation === 'signMessage') {
          return <SignMessageUI chainType={chainType} />
        }
        return <NotFound />
    }
  }

  // console.log('actions ==>', actions)

  return (
    <>
      <BackButton
        onClick={() => {
          webAppReject(true)
        }}
      ></BackButton>
      {renderPageContent()}
      <VerifyModal />
    </>
  )
}
