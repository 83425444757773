import { logoBackupPng } from 'assets'
import { Button } from 'components/Button'
import Container from 'components/Container'
import useNavigateWithParams from 'hooks/useNavigateWithParams'

const BackupPage = () => {
  const navigate = useNavigateWithParams()

  return (
    <Container className="flex h-full flex-1 flex-col justify-between">
      <div className="mt-[48.66px] flex flex-col items-center gap-[24px] text-xl leading-6">
        <img className="w-[140px]" alt="" src={logoBackupPng} />

        <div className="mt-[58.6px] flex flex-col items-center justify-center">
          <span className="text-[20px] font-bold leading-[1.3]">
            Wallet successfully created!
          </span>
          <p className="mt-[20px] text-center text-lg font-normal leading-[21px] text-title/60">
            Earn on the go with Tomo Wallet
          </p>
        </div>
      </div>

      <div className="w-full pb-[8px] pt-[16px] text-xl leading-6">
        <Button block size="large" onClick={() => navigate('/recovery-email')}>
          Add Recovery Email
        </Button>

        <div className="mt-[14px] flex items-center justify-center invisible">
          <button
            className="font-medium text-title"
            onClick={() => navigate('/')}
          >
            Skip
          </button>
        </div>
      </div>
    </Container>
  )
}

export default BackupPage
