import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { NumberKeyboard, NumberKeyboardProps } from 'antd-mobile'
import useDevice from 'hooks/useDevice'
import './_style.scss'

interface CustomNumberKeyboardProps extends NumberKeyboardProps {
  value: string
  setValue: React.Dispatch<React.SetStateAction<string>>
  visible: boolean
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
  // Forced numeric type, for example, 001, 000, etc. are illegal values, pin code please set to false
  isForcedValue?: boolean
  onDone?: () => void
  isFeedback?: boolean
}

const CustomNumberKeyboard: React.FC<CustomNumberKeyboardProps> = ({
  value,
  setValue,
  visible,
  setVisible,
  isForcedValue = true,
  title = 'Done',
  isFeedback = false,
  customKey = '.',
  onDone,
  ...other
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const { vibrate } = useDevice()

  const isPC = useMemo(() => {
    return (
      typeof window !== 'undefined' &&
      window.navigator &&
      !/Mobi|Android/i.test(window.navigator.userAgent)
    )
  }, [])

  const handleMouseDown = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement
    if (target.classList.contains('adm-number-keyboard-key')) {
      const key = target.textContent || ''
      if (!key) {
        handleDelete()
      } else {
        handleKeyboardInput(key.trim())
      }
    }
  }, [])

  useEffect(() => {
    if (visible) {
      const titleElement = document.querySelector('.adm-number-keyboard-title')

      if (titleElement) {
        titleElement.addEventListener('click', handleTitleClick)

        return () => {
          titleElement.removeEventListener('click', handleTitleClick)
        }
      }
    }
  }, [visible])

  useEffect(() => {
    if (visible && isPC) {
      const keyElements = document.querySelectorAll('.adm-number-keyboard-key')
      keyElements.forEach((keyElement) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        keyElement.addEventListener('mousedown', handleMouseDown)
      })
      return () => {
        keyElements.forEach((keyElement) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          keyElement.removeEventListener('mousedown', handleMouseDown)
        })
      }
    }
  }, [visible, isPC, handleMouseDown])

  const handleTitleClick = useCallback(() => {
    console.log('handleTitleClick')
    setVisible(false)
    onDone && onDone()
  }, [onDone, setVisible])

  const mapKeyboardInput = useCallback((event: KeyboardEvent) => {
    const key = event.key
    if (key === 'Backspace') {
      handleKeyboardInput('delete')
    } else if (/^[0-9]$/.test(key)) {
      handleKeyboardInput(key)
    } else if (key === '.') {
      handleKeyboardInput('.')
    } else if (key === 'Enter') {
      handleKeyboardInput('close')
    }
  }, [])

  useEffect(() => {
    if (visible) {
      window.addEventListener('keydown', mapKeyboardInput)
      return () => {
        window.removeEventListener('keydown', mapKeyboardInput)
      }
    }
  }, [visible, mapKeyboardInput])

  /**
   * Keyboard input
   * @param key
   */
  const handleKeyboardInput = useCallback(
    (key: string) => {
      if (isFeedback) vibrate()

      if (key === 'close') {
        setVisible(false)
      } else if (key === 'delete') {
        setValue((prev) => {
          other?.onInput && other?.onInput(prev.slice(0, -1))
          return prev.slice(0, -1)
        })
      } else {
        setValue((prev) => {
          if (prev === '0' && key !== '.' && isForcedValue) {
            other?.onInput && other?.onInput(prev)
            return prev
          }
          if (key === '.') {
            if (prev === '' || prev.includes('.')) {
              other?.onInput && other?.onInput(prev)
              return prev
            }
          }
          other?.onInput && other?.onInput(prev + key)
          return prev + key
        })
      }
    },
    [isFeedback, isForcedValue, setValue, setVisible, vibrate]
  )

  const handleDelete = useCallback(() => {
    setValue((prev) => {
      other?.onInput && other?.onInput(prev.slice(0, -1))
      return prev.slice(0, -1)
    })
  }, [setValue])

  return (
    <div ref={containerRef} className={'keyboard_wrap'}>
      {/* {visible && (
        <div className={'mask'} onClick={() => setVisible(false)}></div>
      )} */}
      {visible && containerRef.current && (
        <NumberKeyboard
          title={title}
          customKey={customKey}
          forceRender={false}
          visible={visible}
          onClose={() => setVisible(false)}
          onDelete={handleDelete}
          onInput={handleKeyboardInput}
          getContainer={containerRef.current}
          {...other}
        />
      )}
    </div>
  )
}

export default CustomNumberKeyboard
